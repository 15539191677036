import React from 'react';
import { Route } from 'react-router-dom';
import Configuration from './configuration';
import MatchPage from './matches/pages/MatchPage'
import AppNoAccess from './error/AppNoAccess'
import SportOrderPage from './configuration/SportOrderPage';
import RedactionResetPage from './redaction';

export default [
    <Route exact path="/configuration" component={Configuration} />,
    <Route exact path="/match/sportType/:id" component={MatchPage} />,
    <Route exact path="/app-no-access" component={AppNoAccess} />,
    <Route exact path="/sport-order" component={SportOrderPage} />,
    <Route exact path="/redaction-reset" component={RedactionResetPage} />,
];