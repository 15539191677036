import React, { FC, useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import compose from 'recompose/compose';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';
import { getData, options } from './chart';
import { buildRequest, checkResponse } from '../../helpers/request';
import { usePrevious } from '../../helpers/utils';
import { isEmpty, isEqual } from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const SportRadarRequests = () => {
  const [metrics, setMetrics] = useState(0);
  const classes = useStyles();

  const fetchMetrics = () => {
    const request = buildRequest('metrics/prometheus', 'GET');

    fetch(request)
        .then(checkResponse)
        .then( (json) => {
            setMetrics(json)
        })
        .catch(error => console.error('Error:', error));
  }

  const prevMetrics = usePrevious(metrics);
  useEffect(() => {
    if(!isEqual(prevMetrics, metrics)) {
      fetchMetrics();
    }  
  }, [metrics, prevMetrics]);

  return (
    <Card
      className={classes.root}
    >
      <CardHeader
        title="SportRadar Requests"
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          {!isEmpty(metrics) ?
            <Bar
                data={getData(metrics)}
                options={options}
            />: null
          }
        </div>
      </CardContent>
    </Card>
  )
}

export default SportRadarRequests;
