import React from 'react';
import { Filter, List, Edit, Datagrid, BooleanField, TextField, EditButton, SimpleForm, TextInput, ReferenceInput, SelectInput, DateInput } from 'react-admin';
import { DefaultToolbar } from '../helpers/DefaultToolbar';
import Icon from '@material-ui/icons/Event';
import MatchGridList from './GridList';
import moment from 'moment';

export const MatchIcon = Icon;


const MatchFilter = (props: any) => (
    <Filter {...props}>
        <ReferenceInput label="Sports" source="sportType" reference="sport/all" alwaysOn >
            <SelectInput optionText="name" />
        </ReferenceInput>
        <DateInput source="gameDate" alwaysOn />
    </Filter>
);

const MatchTitle = (props: any) => {
    const { record } = props;
    return <span>Match {record ? `"${record.id}"` : ''}</span>;
};

export const MatchEdit = (props) => (
    <Edit title={<MatchTitle />} {...props}>
        <SimpleForm toolbar={<DefaultToolbar />}>
            <TextInput disabled source="id" />
            <TextInput disabled source="gameDate" />
            <TextInput disabled source="group" />
            <TextInput disabled source="roundNumber" />
            <TextInput disabled source="roundType" />
            <TextInput disabled source="status" />
            <TextInput disabled source="homeScore" />
            <TextInput disabled source="awayScore" />
            <TextInput disabled source="matchMinute" />
            <TextInput disabled source="matchStatus" />
            <TextInput disabled source="phase" />
        </SimpleForm>
    </Edit>
);
