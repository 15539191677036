import React, { Fragment } from 'react';
import compose from 'recompose/compose';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, colors } from '@material-ui/core';

import { useTranslate } from 'react-admin';

const useStyles = makeStyles((theme: Theme) => ({
    listItem: {
    },
    listItemText: {
        color: colors.blueGrey[800],
        fontFamily: 'Roboto, sans-serif',
        fontSize: '15px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightMedium
    },
    sidebarIsOpen: {
        paddingLeft: 25,
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
    sidebarIsClosed: {
        paddingLeft: 0,
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
    listItemIcon: {
        marginRight: -16,
    },
    icon: {
        color: colors.blueGrey[600],
    },
}))

const SubMenu = ({
    handleToggle,
    sidebarIsOpen,
    isOpen,
    name,
    icon,
    children,
}) => {
    const classes = useStyles();
    const translate = useTranslate();
    return (
        <Fragment>
            <ListItem
                dense
                button
                onClick={handleToggle}
                className={classes.listItem}
                disableGutters
            >
                <ListItemIcon className={classes.listItemIcon}>{isOpen ? <ExpandMore className={classes.icon} /> : icon}</ListItemIcon>
                <ListItemText
                    primary={isOpen ? <Typography variant="h6">{ translate(name) }</Typography> : ''}
                    secondary={isOpen ? '' : <Typography variant="h6">{ translate(name) }</Typography>}
                />
            </ListItem>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List
                    dense
                    component="div"
                    disablePadding
                    className={
                        sidebarIsOpen
                            ? classes.sidebarIsOpen
                            : classes.sidebarIsClosed
                    }
                >
                    {children}
                </List>
                <Divider />
            </Collapse>
        </Fragment>
    )   
}

export default SubMenu;
