import React from 'react';
import { BooleanField, TextField, EditButton, TextInput, BooleanInput, DateField, Responsive, Filter } from 'react-admin';
import { List, Datagrid } from '../components/customs';
import MobileGrid from './MobileGrid';
import { isAdmin, isEditor } from '../permissions';
import { LogoField } from '../helpers/utils';
import { SearchInput } from '../components/customs/Input';
import { PurgeButton } from '../components/buttons';

const CompetitionFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <TextInput label="Name DE" source="nameDE"  />
        <BooleanInput label="hasStandings" source="hasStandings"  />
    </Filter>
);

const CompetitionList = ({ permissions, ...props }) => (
    <List {...props} filters={<CompetitionFilter />} bulkActionButtons={false}>
        <Responsive
            medium={
                <Datagrid>
                    <LogoField />
                    <TextField source="id" />
                    <TextField source="nameDE" label="Name DE"/>
                    <BooleanField source="isActive" />
                    <BooleanField source="hasTicker" />
                    <BooleanField source="hasStandings" />
                    <BooleanField source="isTop" />
                    <TextField source="countryCode" />
                    <TextField source="topSort" />
                    <TextField source="isActive" />
                    <DateField source="inactiveAt" showTime />
                    <BooleanField source="isImageAvailable" />
                    {isAdmin(permissions) && <PurgeButton />}
                    {(isAdmin(permissions) || isEditor(permissions)) && <EditButton />}
                </Datagrid>
            }
        />
    </List>
);

export default CompetitionList;