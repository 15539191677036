import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { buildRequest, checkResponse, checkResponseRaw } from '../helpers/request';
import { Title, useTranslate } from 'react-admin';
import RedactionResetDropdown from './RedactionResetDropdown';
import ResourceSelect from './ResourceSelect';
import isEmpty from 'lodash/isEmpty';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Divider from '@material-ui/core/Divider';
import { isAdmin, isEditor } from '../permissions';
import { SportAdminPage } from '../components/customs';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { usePermissions } from 'react-admin';

const useStyles = makeStyles(theme =>({
    root: {
        marginRight: theme.spacing(2.5),
    },
    wrapper: {
        marginTop: 25,
        marginLeft: 25,
        position: 'relative',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
          backgroundColor: green[700],
        },
    },
    resetSuccessMessage: {
        color: 'green',
        marginLeft: '10px',
        fontSize: '12px',
    },
    resetIcon: {
        marginRight: 5,
    },
    checkIcon: {
        marginRight: 5,
        fontSize: 20,
    }
}))

export const RedactionResetPage = () => {
    const classes = useStyles();
    const buttonClassname = clsx({
        [classes.buttonSuccess]: successReset,
    });
    const translate = useTranslate();
    const { permissions } = usePermissions();

    const [resourceGroups, setResourceGroups] = useState([]);
    const [resources, setResources] = useState([]);
    const [selectedResourceGroup, setSelectedResourceGroup] = useState('');
    const [selectedResource, setSelectedResource] = useState('');
    const [resetLoading, setResetLoading] = useState(false);
    const [successReset, setSuccessReset] = useState(false);

    const [pollingServices, setPolingServices] = useState([]);
    const [selectedPollingService, setSelectedPollingService] = useState('');

    useEffect(() => {
        fetchPollingServices()
            .then(json => setPolingServices(json))
            .catch(error => console.error('Failed to fetch resourceGroups from server: ', error))
    }, [])

    const handlePollingServiceChange = (event) => {
        const pollingService = event.target.value;
        setSelectedPollingService(pollingService);
        setSuccessReset(false);
        fetchResourceGroup(pollingService)
            .then(json => {
                setResourceGroups(json)
                setSelectedResourceGroup('')
                setResources([])
                setSelectedResource('')
            })
            .catch(error => console.error('Failed to fetch resourceGroups from server: ', error))
    }

    const handleResourceGroupChange = (event) => {
        const resourceGroup = event.target.value;
        setSelectedResourceGroup(resourceGroup);
        setSuccessReset(false);
        fetchResources(resourceGroup, selectedPollingService)
            .then(json => {
                setResources(json);
                setSelectedResource(Array.isArray(json) ? json[0] : '');
            })
            .catch(error => console.error('Failed to fetch resources from server: ', error))
    }

    const handleResourceChange = (event) => {
        const resource = event.target.value;
        setSelectedResource(resource);
        setSuccessReset(false);
    }

    const fetchPollingServices = () => new Promise((resolve, reject) => {
        const request = buildRequest('redaction/polling-service', 'GET');
        fetch(request)
            .then(checkResponse)
            .then(json => resolve(json))
            .catch(error => reject(error));
    });

    const fetchResourceGroup = (pollingService) => new Promise((resolve, reject) => {
        const request = buildRequest(`redaction/${pollingService}/resourceGroup`, 'GET');
        fetch(request)
                .then(checkResponse)
                .then(json => resolve(json))
                .catch(error => reject(error));
    });

    const fetchResources = (resourceGroupName, pollingService) => new Promise((resolve, reject) => {
        const request = buildRequest(`redaction/${pollingService}/resourceGroup/${resourceGroupName}`, 'GET');
        fetch(request)
                .then(checkResponse)
                .then(json => resolve(json))
                .catch(error => reject(error));
    });

    const resetResource = (resourceGroupName, resourceName, pollingService) => new Promise((resolve, reject) => {
        const request = buildRequest(`redaction/${pollingService}/resourceGroup/${resourceGroupName}/${resourceName}`, 'POST');
        fetch(request)
                .then(checkResponseRaw)
                .then(response => resolve(response))
                .catch(error => reject(error));
    });

    const handleResetClick = () => {
        setResetLoading(true);
        resetResource(selectedResourceGroup, selectedResource, selectedPollingService)
            .then(() => {
                setSuccessReset(true);
                setResetLoading(false);
            })
            .catch(error => console.error('Reset request to poller service failed: ', error))
    }

    return (
        isAdmin(permissions) || isEditor(permissions)) ? (
            <SportAdminPage>
                <Card>
                    <Title title="Redaction Resource Reset" />
                    <CardContent>
                        <RedactionResetDropdown handleChange={handlePollingServiceChange} selectedItem={selectedPollingService}
                                                items={pollingServices} title={'Polling Service'} />
                        <RedactionResetDropdown handleChange={handleResourceGroupChange} selectedItem={selectedResourceGroup}
                                                items={resourceGroups} title={'Resource Group'} />
                        <ResourceSelect handleChange={handleResourceChange} selectedResource={selectedResource} resources={resources} />
                        <Divider variant="middle" />
                        <div className={classes.wrapper}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={isEmpty(selectedResource)}
                                className={buttonClassname}
                                onClick={handleResetClick}
                            >
                                {successReset ? <CheckIcon className={classes.checkIcon} /> : <RotateLeftIcon className={classes.resetIcon} /> }
                                Reset
                                {resetLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </Button>

                            {successReset ? <span className={classes.resetSuccessMessage}>{translate('resources.redaction.reset.successMessage', {resourceGroup: selectedResourceGroup, resource: selectedResource})}</span> : null }
                        </div>
                    </CardContent >
                </Card>
            </SportAdminPage>
    ): null
}