import React, { FC } from 'react';
import compose from 'recompose/compose';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import { translate, Responsive } from 'react-admin';
import { TaskAssign } from './Dashboard/TaskAssign'
import { SportRadarRequests } from './Dashboard/SportRadarRequests';
import { TaskProgress } from './Dashboard/TaskProgress';
import { ImageAvailableCount } from './Dashboard/ImageAvailable';
import { TickerSports } from './Dashboard/TickerSports';
import { TotalSports } from './Dashboard/TotalSports';
import { LastChange } from './Dashboard/LastChange';

const useStyles = makeStyles(theme => ({
    root: {
        marginRight: theme.spacing(-1),
    },
    grid: {
    },
    topMargin: {
        marginTop:'16px',
    },
    bottomContent: {
        marginTop:'16px',
        marginBottom: '20px'
    }
}));

const Dashboard: FC = () => {
    const classes = useStyles();
    return (
        <Responsive
            small={
                <div className={classes.root}>
                    <Grid
                        container
                        spacing={0}
                        className={classes.grid}
                    >
                        <Grid container item xs={12} spacing={4}>
                            <Grid
                                item
                                xs={6}
                                >
                                <TickerSports />
                                </Grid>
                                <Grid
                                item
                                xs={6}
                                >
                                <LastChange />
                                </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={4} className={classes.topMargin}>
                            <Grid
                                item
                                xs={6}
                            >
                                <TaskProgress />
                            </Grid>
                            <Grid
                                item
                                xs={6}
                            >
                                <TotalSports />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={4} className={classes.topMargin}>
                            <Grid
                                item
                                xs={12}
                            >
                                <SportRadarRequests />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={4} className={classes.topMargin}>
                            <Grid
                                item
                                xs={12}
                            >
                                <ImageAvailableCount />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={4} className={classes.bottomContent}>
                            <Grid
                                item
                                xs={12}
                            >
                                <TaskAssign />
                            </Grid>
                        </Grid>

                    </Grid>
                </div>
            }
            medium={
                <div className={classes.root}>
                    <Grid
                        container
                        spacing={0}
                        className={classes.grid}
                    >
                        <Grid container item xs={12} spacing={4}>
                                <Grid
                                item
                                xs={3}
                                >
                                <TickerSports />
                                </Grid>
                                <Grid
                                item
                                xs={3}
                                >
                                <LastChange />
                                </Grid>
                                <Grid
                                item
                                xs={3}
                                >
                                <TaskProgress />
                                </Grid>
                                <Grid
                                item
                                xs={3}
                                >
                                <TotalSports />
                                </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={4} className={classes.bottomContent}>
                            <Grid container item xs={9} >
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <SportRadarRequests />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <TaskAssign />
                                </Grid>
                            </Grid>
                            <Grid container item xs={3}  >
                                <Grid container item xs={12} >
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <ImageAvailableCount />
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>

                    </Grid>
                </div>
            }
        />
    )
}

export default Dashboard;