import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import lodashSet from 'lodash/set';
import lodashGet from 'lodash/get';
import { Form, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import FilterFormInput from './FilterFormInput';

const useStyles = makeStyles(theme => ({
    form: {
        marginTop: '-10px',
        paddingTop: 0,
        display: 'flex',
        alignItems: 'flex-end',
        flexWrap: 'wrap',
    },
    body: { display: 'flex', alignItems: 'flex-end' },
    spacer: { width: '1em' },
    icon: {
        color: theme.palette.primary1Color || '#00bcd4',
        paddingBottom: 0,
    },
    clearFix: { clear: 'right' },
}));

const sanitizeRestProps = ({
    anyTouched,
    asyncValidate,
    asyncValidating,
    autofill,
    blur,
    change,
    clearAsyncError,
    clearFields,
    clearSubmit,
    clearSubmitErrors,
    destroy,
    dirty,
    dispatch,
    displayedFilters,
    filterValues,
    handleSubmit,
    hideFilter,
    initialize,
    initialized,
    initialValues,
    invalid,
    pristine,
    pure,
    reset,
    resetSection,
    save,
    setFilter,
    setFilters,
    submit,
    submitFailed,
    submitSucceeded,
    submitting,
    touch,
    triggerSubmit,
    untouch,
    valid,
    validate,
    ...props
}) => props;

const handleSubmit = event => {
    event.preventDefault();
    return false;
};

export const mergeInitialValuesWithDefaultValues = ({
    initialValues,
    filters,
}) => ({
    initialValues: {
        ...filters
            .filter(
                filterElement =>
                    filterElement.props.alwaysOn &&
                    filterElement.props.defaultValue
            )
            .reduce(
                (acc, filterElement) =>
                    lodashSet(
                        { ...acc },
                        filterElement.props.source,
                        filterElement.props.defaultValue
                    ),
                {}
            ),
        ...initialValues,
    },
});

export const FilterForm = ({
    classes = {},
    className,
    resource,
    margin,
    variant,
    filters,
    displayedFilters = {},
    hideFilter,
    initialValues,
    ...rest
}) => {

    useEffect(() => {
        filters.forEach(filter => {
            if (filter.props.alwaysOn && filter.props.defaultValue) {
                throw new Error(
                    'Cannot use alwaysOn and defaultValue on a filter input. Please set the filterDefaultValues props on the <List> element instead.'
                );
            }
        });
    }, [filters]);

    const getShownFilters = () =>
        filters.filter(
            filterElement =>
                filterElement.props.alwaysOn ||
                displayedFilters[filterElement.props.source] ||
                typeof lodashGet(initialValues, filterElement.props.source) !==
                    'undefined'
        );

    const handleHide = useCallback(
        event => hideFilter(event.currentTarget.dataset.key),
        [hideFilter]
    );

    return (
        <form
            className={classnames(className, classes.form)}
            {...sanitizeRestProps(rest)}
            onSubmit={handleSubmit}
        >
            {getShownFilters().map(filterElement => (
                <FilterFormInput
                    key={filterElement.props.source}
                    filterElement={filterElement}
                    handleHide={handleHide}
                    resource={resource}
                    margin={margin}
                    variant={variant}
                    classes={classes}
                />
            ))}
            <div className={classes.clearFix} />
        </form>
    );
}

FilterForm.propTypes = {
    resource: PropTypes.string.isRequired,
    filters: PropTypes.arrayOf(PropTypes.node).isRequired,
    displayedFilters: PropTypes.object,
    hideFilter: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    classes: PropTypes.object,
    className: PropTypes.string,
};

const EnhancedFilterForm = props => {
    const { classes: classesOverride, ...rest } = props;
    const classes = useStyles(props);

    const mergedInitialValuesWithDefaultValues = mergeInitialValuesWithDefaultValues(
        props
    );

    const { initialValues, ...rest2 } = rest;

    return (
        <Form
            onSubmit={handleFinalFormSubmit}
            initialValues={mergedInitialValuesWithDefaultValues}
            mutators={{ ...arrayMutators }}
            render={formProps => (
                <>
                    <FormSpy
                        subscription={FormSpySubscription}
                        onChange={({ pristine, values }) => {
                            if (pristine) {
                                return;
                            }
                            rest && rest.setFilters(values);
                        }}
                    />
                    <FilterForm classes={classes} {...formProps} {...rest2} />
                </>
            )}
        />
    );
}

/*const enhance = compose(
    withStyles(styles),
    withProps(mergeInitialValuesWithDefaultValues),
    reduxForm({
        form: 'filterForm',
        enableReinitialize: true,
        destroyOnUnmount: false, // do not destroy to preserve state across navigation
        onChange: (values, dispatch, props) =>
            props && props.setFilters(values),
    })
);

export default enhance(FilterForm);*/

const handleFinalFormSubmit = () => {};

// Options to instruct the FormSpy that it should only listen to the values and pristine changes
const FormSpySubscription = { values: true, pristine: true };

export default EnhancedFilterForm;