import React from 'react';
import { Edit, TextInput, SimpleForm, ImageField } from 'react-admin';
import { ResourceActions } from '../buttons/ResourceAction';
import { DefaultToolbar } from '../helpers/DefaultToolbar';
import CustomImageInput from '../fileInput/CustomImageInput';
import { SportAdminPage } from '../components/customs';

const GroupTitle = (props: any) => {
    const { record } = props;
    return <span>Group {record ? `"${record.name}"` : ''}</span>;
};

const SportGroupEdit = (props) => (
    <SportAdminPage>
        <Edit title={<GroupTitle />} {...props} actions={<ResourceActions />}>
            <SimpleForm toolbar={<DefaultToolbar />}>
                <TextInput disabled source="id" />
                <TextInput multiple source="name" />
                <TextInput multiple source="nameDE" label="Name DE"/>
                <TextInput source="sort" />
                <TextInput source="iconName" label={"Icon Name"} />
                <CustomImageInput source="logo" label="Icon" accept="image/png" multiple={false}>
                    <ImageField source="src" />
                </CustomImageInput>
            </SimpleForm>
        </Edit>
    </SportAdminPage>
);

export default SportGroupEdit;