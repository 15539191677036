import React from "react";
import { Datagrid, List } from '../../components/customs';
import { EditButton, Filter, Responsive, TextField } from "react-admin";
import { SearchInput } from "../../components/customs/Input";
import {isAdmin, isEditor} from "../../permissions";

const SoccerTeamStandingFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

const SoccerTeamStandingList = ({ permissions, ...props }) =>  (
  <List {...props} filters={<SoccerTeamStandingFilter />} sort={{ field: 'competitionId', order: 'ASC' }} bulkActionButtons={false}>
    <Responsive
      medium={
        <Datagrid>
          <TextField source="competitionId" />
          <TextField source="teamId" />
          <TextField source="seasonId" />
          <TextField source="groupName" />
          <TextField source="groupNameDe" label="groupName DE" />
          {(isAdmin(permissions) || isEditor(permissions)) && <EditButton />}
        </Datagrid>
      }
    />
  </List>
)

export default SoccerTeamStandingList