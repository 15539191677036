import React, { Component, Fragment, forwardRef, ReactElement, Ref } from 'react';
import { connect } from 'react-redux';
import { change, submit, isSubmitting } from 'redux-form';
import { EditBox, GridListBox } from '../getty';
import compose from 'recompose/compose';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import { translate } from 'react-admin';
import {
    fetchEnd,
    fetchStart,
    required,
    showNotification,
    Button,
    SimpleForm,
    TextInput,
    Responsive
} from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/More';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { 
    Tooltip,
    TextField
 }
from '@material-ui/core';

import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';


import { buildRequest, checkResponse } from '../helpers/request'
import { getLogoutUrl } from '../helpers/consts';

import 'react-image-crop/dist/ReactCrop.css';

import { css } from 'react-emotion';
import { BeatLoader, BarLoader } from 'react-spinners';

import Grid from '@material-ui/core/Grid';
import classnames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import { saveTempFile, removeTempFile } from '../helpers/request';
import { API_URL } from '../helpers/consts';
import { get, isEmpty } from 'lodash';
import { Form, Field } from 'react-final-form';


const getColsForWidth = (width, imageSelected) => {
    if (width === 'xs') return 1;
    if (width === 'sm') return imageSelected ? 2 : 3;
    if (width === 'md') return imageSelected ? 3 : 4;
    if (width === 'lg') return imageSelected ? 4 : 6;
    return 6;
};

const getGridItemSize = (element, width, imageSelected) => {
    switch(element) {
        case 'editBox':
            if(width === 'sm') return 6;
            if(width === 'md') return 5;
            if(width === 'lg') return 4;
            break;
        case 'listBox':
            if(width === 'sm') return imageSelected ? 6 : 12;
            if(width === 'md') return imageSelected ? 7 : 12;
            if(width === 'lg') return imageSelected ? 8 : 12;
            break;
        default:
            return 4;
    }

}

const Transition = forwardRef(function Transition(
        props : TransitionProps & { children?: ReactElement<any, any> },
        ref: Ref<unknown>,
    ) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const getComputedImageWidth = (totalWidth, percentWidth) => {
    return totalWidth * (percentWidth / 100);
}

export const getComputedImageHeight = (totalHeight, percentHeight) => {
    return totalHeight * (percentHeight / 100);
}

export const getComputedNum = (num, other) => {
    return (num/100) * other;
}

const getOneSize = (config, resource, offset) => {
    const operation = get(config, `resourceData[${resource}].operation`) || null;
    if(isEmpty(operation)) {
        return 400;
    }
    return operation.split('x')[offset];
}

export const getFinalImgUrl = (imageServiceURL, imgComputedWidth, imgComputedHeight, imgOriginalWidth, imgOriginalHeight, box, src, config, resource) => {
    const aspectRatio = (imgComputedWidth && imgComputedHeight) ? imgComputedWidth / imgComputedHeight: imgOriginalWidth / imgOriginalHeight;
    const width = getOneSize(config, resource, 0);
    const height = Math.round(width / aspectRatio);
    return `${imageServiceURL}${width}x${height}/filters:focal(${box.left}x${box.top}:${box.right}x${box.bottom})/${src}`;
}

export const getCropFinalImgUrl = (imageServiceURL, box, src) => {
       return `${imageServiceURL}${box.left}x${box.top}:${box.right}x${box.bottom}/${src}`;
}

export const fetchConfig = () => new Promise((resolve, reject) => {
    const request = buildRequest('config/thumbor', 'GET');

    fetch(request)
        .then(checkResponse)
        .then(json => resolve(json))
        .catch(error => reject(error));
});

export const downloadImage = (imageId) => new Promise((resolve, reject) => {
    const downloadRequest = buildRequest(`images/download/${imageId}`, 'POST');

    fetch(downloadRequest)
        .then(checkResponse)
        .then(json => resolve(json))
        .catch(error => reject(error));
});

const getImageExtension = resource => {
    switch(resource) {
        case 'player':
        case 'officials':
        case 'referee':
            return 'jpg';
        default:
            return 'png';
    }
}

const convertNumToPercent = (num, total) => {
    return (num / total) * 100;
}

const adjustNum = (num, other) => {
    return num - (other/2);
}

export const fetchCropFocalPoints = (operation, fileName) => new Promise((resolve, reject) => {
    const finalUrl = `${API_URL}/api/thumbor/meta/${operation}?fileName=${fileName}`;
    fetch(finalUrl)
        .then(response => response.json())
        .then(json => {
         resolve(json);
        })
        .catch(error => reject(error));
});

export const fetchFocalPoints = (imageServiceURL, fileName) => new Promise((resolve, reject) => {
    const url = `${API_URL}/api/thumbor/meta?fileName=${fileName}`;
    fetch(url)
        .then(response => response.json())
        .then(json => {
            const x = adjustNum(json.thumbor.focal_points[0].x, json.thumbor.focal_points[0].width);
            const y = adjustNum(json.thumbor.focal_points[0].y, json.thumbor.focal_points[0].height);

            const imgOriginalWidth = json.thumbor.source.width;
            const imgOriginalHeight = json.thumbor.source.height;

            //if the width and height equal 1 we take 10 percent of the original width as default
            const width = json.thumbor.focal_points[0].width > 1 ? json.thumbor.focal_points[0].width : imgOriginalWidth * 0.1;
            const height = json.thumbor.focal_points[0].height > 1 ? json.thumbor.focal_points[0].height : imgOriginalWidth * 0.1;

            const crop = {x: convertNumToPercent(x, imgOriginalWidth), y: convertNumToPercent(y, imgOriginalHeight), width: convertNumToPercent(width, imgOriginalWidth),
                height: convertNumToPercent(height, imgOriginalHeight)};

            const data = {imgOriginalWidth, imgOriginalHeight, crop}

            resolve(data);
        })
        .catch(error => reject(error));

});

export const fetchCropImagePreview = (operation, fileName, imageType) => new Promise((resolve, reject) => {
    const url = `${API_URL}/api/thumbor/image/crop/preview/${imageType}?operation=${operation}&fileName=${fileName}`;
    fetch(url)
        .then(response => response.blob())
        .then(blob => {
            const reader = new FileReader();
            const urlPreview = URL.createObjectURL(blob);
            resolve(urlPreview);
        })
        .catch(error => reject(error));
});

const styles = ((theme: Theme) => createStyles({
    tracks: {
    },
    tracksEdit: {

    },
    gridList: {
        width: '100%',
        overflow: 'visible',
    },
    gridEdit: {
        width: '100%'
    },
    item: {
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        '&:hover': {
            transform: 'scale(1.1)'
        },

    },
    itemListGrid: {
        transition: theme.transitions.create('all', {
            easing: theme.transitions.easing.sharp,
            duration: '1s',
        })
    },
    hide: { display: 'none' },
    show: {},
    loaderContainer: {textAlign: 'center'},
    loadMore: { margin: 'auto' },
    icon: { color: '#999', cursor: 'pointer' },
    imgEditDialog: { display: 'flex' },
    editImageContainer: {height: '200', width: '150'},
    editImage: {maxWidth: '100%', maxHeight: '100%'},
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: 2,
        flex: 1,
        color: '#fff'
    },
    searchItems: {
        display: 'inline-block',
    },
    items: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },
    editBox: {
        display: 'flex',
        flexDirection: 'column',
        width: 'auto',
        top: 0,
        border: '1px solid #ccc!important',
        position: 'sticky',
        justifyContent: 'center',

    },
    preview: {
        margin: 10,
        marginLeft: 30,
        height: '50%',
    },
    imgCropper: {
        minHeight: '350px',
        marginTop: 10,
        marginLeft: 25,
        textAlign: 'center',
    },
    editToolbar: {
        display: 'inline-block',
        marginTop: 0,
        marginRight: 0,
        marginLeft: 20,
        marginBottom: 20,
        float: 'right',
    },
    imageSelectingLoading:  {
        position: 'absolute',
        zIndex: 100,
        top: '50%',
        margin: 'auto',
        width: '5%'
    },
    searchFormElContainer: {
        margin: '20px 25px'
    },
    searchFormEl: {
        display: 'inline-block'
    },
    searchFormTextField: {
        width: '250px'
    },
    searchSubmitButton: {
        margin: '13px 0 0 15px'
    }
}));

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

export const DefaultLoader = props => {
    return (
        <div style={{ textAlign: 'center' }}>
            <BeatLoader
            className={override}
            sizeUnit={"px"}
            size={15}
            color={'#123abc'}
            loading={props.mainLoading}
            />
        </div>
    )
};

var page = 1;
var phrase = null;

type Track = {
    id: number,
}
type Box = {
    left: number,
    top: number,
    right: number,
    bottom: number
}

type GettyImageButtonProps = {
    submit: any,
    resource: string,
    onSelectImage: any,
    classes: any,
    translate: any,
    width: any,
    searchPhrase: any,
}

type GettyImageButtonState = {
    config: object,
    error: boolean,
    showDialog: boolean,
    tracks: Track[],
    phrase: string,
    page: number,
    mode: string,
    mainLoading,
    loading: boolean,
    imageSrc: any,
    imageServiceURL: string,
    reshapeUrl: string,
    croppedImageUrl: any,
    imageId: any,
    src: any,
    showEditImageDialog: boolean,
    imageSelected: boolean,
    isImageEdit: boolean,
    isImageSelecting: boolean,
    imgOriginalWidth: any,
    imgOriginalHeight: any,
    imgComputedWidth: any,
    imgComputedHeight: any,
    crop: object,
    box: Box,
    action: string,
    percentCrop: any,
    tempFile: any,
    urlPreview: any,
    previewImageUrl: any,
    targetAspectRatio: any,
    focusPointPreview1: any,
    focusPointPreview2: any,

}

class GettyImageButton extends Component<GettyImageButtonProps, GettyImageButtonState> {

    constructor(props) {
		super(props)
		this.state = {
            config: {},
		    error: false,
            showDialog: false,
            tracks: [],
            phrase: "",
            page: 1,
            mode: 'search',
            mainLoading: false,
            loading: false,
            imageSrc: null,
            imageServiceURL: '',
            reshapeUrl: '',
            src: null,
            croppedImageUrl: null,
            imageId: null,
            showEditImageDialog: false,
            imageSelected: false,
            isImageEdit: false,
            isImageSelecting: false,
            imgOriginalWidth: null,
            imgOriginalHeight: null,
            imgComputedWidth: null,
            imgComputedHeight: null,
            crop: {
              x: 0,
              y: 0,
              width: 20,
              height: 20
            },
            box: {
              left:0,
              top:0,
              right:0,
              bottom:0
            },
            action: 'crop',
            percentCrop: null,
            tempFile: null,
            urlPreview: null,
            previewImageUrl: null,
            targetAspectRatio: null,
            focusPointPreview1: null,
            focusPointPreview2: null,

        };
    }

	componentDidMount() {
	    fetchConfig()
	        .then((json: any) => this.setState({ imageServiceURL: json.thumborInternalUrl, reshapeUrl: json.thumborUrl, config: json }))
	        .catch(error => console.error('Failed to fetch config from server: ', error));
	}

	handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        const { tempFile } = this.state;
        if(tempFile) {
            removeTempFile(tempFile);
        }
        this.setState({ showDialog: false });
    };

    handleSearchClick = () => {
        const { submit } = this.props;

        // Trigger a form submit
        // This is needed because our modal action buttons are outside the form
        submit('post-search');
    };

    imageClick = (index, e) => {
        this.setState({ isImageSelecting: true });
        //Set default blob in case no crop is made
        const { imageServiceURL } = this.state;
        const { resource } = this.props;
        const id =  this.state.tracks[index].id;
        downloadImage(id)
            .then((json: any) => {
                fetch(json.uri)
                    .then(response => response.blob())
                    .then(blob => {
                        const file = new File([blob], `${id}.${getImageExtension(resource)}`);
                        this.setState({croppedImageUrl: blob, tempFile: file})
                        saveTempFile(file)
                            .then(location => {
                               const src = location;
                               this.setState({ imageId: id, src })
                               fetchFocalPoints(imageServiceURL, file.name)
                                   .then((data: any) => this.setState({ imgOriginalWidth: data.imgOriginalWidth, imgOriginalHeight: data.imgOriginalHeight, crop: data.crop }))
                                   .catch(error => console.error('Failed to fetch focal points: ', error));

                               this.setState({ mainLoading: true });
                               //this.loadImg(src);
                               this.setState({ imageSrc: src, src});
                            })
                    })
                    .catch(error => console.error(error))
            })
            .catch(error => console.log('Failed to download image: ', error));

    }

    loadImg = src => {
        var img = new Image();
        img.crossOrigin = "Anonymous";
        const parent = this;
        img.addEventListener('error', function () {
            parent.setState({ mainLoading: false });
        });
        img.addEventListener('load', function () {
            parent.setState({ isImageSelecting: false, imageSelected: true, isImageEdit: true, mainLoading: false });
        }, false);

        img.src = src;
    }

    handleSubmit = values => {
        this.searchItems(values.searchPhrase);
    };

    getProcessedImages = tracks => {
        return tracks.map(track => {
            const aspectRatio = track.download_sizes[0].width / track.download_sizes[0].height;
            const width = 500;
            const height = Math.round(width/aspectRatio)
            return {
               id: track.id,
               src: track.display_sizes[0].uri,
               thumbnail: track.display_sizes[0].uri,
               thumbnailWidth: width,
               thumbnailHeight: height
            };
})
    }

    searchItems = (phrase) => {
        this.setState({ loading: true, phrase: phrase });
        const request = buildRequest(`images/search?phrase=${phrase}&page=1`, 'GET')

        fetch(request)
            .then(this.checkResponse)
            .then( (json) => {
                this.setState({
                    tracks: this.getProcessedImages(json.images),
                    mode: 'load'
                })
                this.setState({ loading: false });
            })
            .catch(error => {
                console.error('Error:', error);
                this.setState({ loading: false });
            });
    }

    loadItems = () => {
        this.setState({ loading: true });
        const { phrase } = this.state;
        const request = buildRequest(`images/search?phrase=${phrase}&page=${page}`, 'GET')

        fetch(request)
            .then(this.checkResponse)
            .then( (json) => {

                this.setState({
                    tracks: [...this.state.tracks, ...this.getProcessedImages(json.images)],
                    mode: 'load'
                })
                page++;
                this.setState({ loading: false });
            })
            .catch(error => {
                console.error('Error:', error);
                this.setState({ loading: false });
            });
    }

    checkResponse = response => {
        if(response.status === 401 || response.status === 403){
            localStorage.removeItem('token')
             window.location.href = getLogoutUrl() || '/';
        }
        if(response.status !== 200) {
            return Promise.reject(new Error('Failed to fetch'));
        }
        return response.json();
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
           e.preventDefault();
           this.searchItems(e.target.value);
        }
    }

    onImageLoaded = image => {
        this.setState({ isImageSelecting: false, imageSelected: true, isImageEdit: true, mainLoading: false, imgOriginalWidth: image.naturalWidth, imgOriginalHeight: image.naturalHeight});
        this.onCropComplete(this.state.crop, null);
    };

    onCropChange = crop => {
        this.setState({ crop });
    };

    onCropComplete = (crop, percentCrop) => {
        const {imgOriginalWidth, imgOriginalHeight, imageServiceURL, action, tempFile} = this.state;
        const imgComputedWidth = getComputedImageWidth(imgOriginalWidth, crop.width);
        const imgComputedHeight = getComputedImageHeight(imgOriginalHeight, crop.height);
        this.setState({ percentCrop, imgComputedWidth, imgComputedHeight });
        this.computeImgBox(action, tempFile.name, crop, percentCrop, imgOriginalWidth, imgOriginalHeight);
    };

    computeImgBox = (action, fileName, crop, percentCrop, totalWidth, totalHeight) => {
        const { config } = this.state;
        const { resource } = this.props;
        const left = Math.round(getComputedNum(percentCrop && percentCrop.x || crop.x, totalWidth));
        const right = Math.round(left + getComputedNum(percentCrop && percentCrop.width || crop.width, totalWidth));
        const top = Math.round(getComputedNum(percentCrop && percentCrop.y || crop.y, totalHeight));
        const bottom = Math.round(top + getComputedNum(percentCrop && percentCrop.height || crop.height, totalHeight));


        if(action === 'crop') {
            fetchCropFocalPoints(`${left}x${top}:${right}x${bottom}`, fileName)
               .then((json: any) => {
                    const targetAspectRatio = json.thumbor.target.width / json.thumbor.target.height;
                    const previewHeight = Math.round(200/targetAspectRatio);
                    const imageType = get(config, `resourceData[${resource}].imageType`) || 'jpg';
                    fetchCropImagePreview(`${left}x${top}:${right}x${bottom}/200x${previewHeight}`, fileName, imageType)
                        .then(imgPreview => {
                            this.setState({ urlPreview: imgPreview })
                        })
                        .catch(error => console.log(error));
                    this.setState({ targetAspectRatio:targetAspectRatio })
                })
               .catch(error => console.error('Failed to fetch focal points for crop: ', error));
        }

        if(action == 'focusPoint') {
            const { reshapeUrl, src } = this.state;
            const focusPointPreview1 = `${reshapeUrl}50x50/filters:focal(${left}x${top}:${right}x${bottom})/${src}`;
            const focusPointPreview2 = `${reshapeUrl}50x50/filters:focal(${left}x${top}:${right}x${bottom}):round_corner(255,255,255,255)/${src}`;
            this.setState({ focusPointPreview1, focusPointPreview2 })
        }

        this.setState({ box:{left, top, right, bottom}});
    }

    saveEditImage = () => {
        const {imageId, imageServiceURL, imgComputedWidth, imgComputedHeight, imgOriginalWidth, imgOriginalHeight, box, src, action, config, tempFile} = this.state;
        const { resource } = this.props;
        let finalImageUrl;
        if(action === 'crop') {
            const operation = `${box.left}x${box.top}:${box.right}x${box.bottom}`
            const urlPostfix = get(config, `resourceData[${resource}].imageType`) || 'jpg';
            finalImageUrl = `${API_URL}/api/thumbor/image/crop/preview/${urlPostfix}?operation=${operation}&fileName=${tempFile.name}`;
        }
        else{
            //default is focus point
            finalImageUrl = getFinalImgUrl(imageServiceURL, imgComputedWidth, imgComputedHeight, imgOriginalWidth, imgOriginalHeight, box, src, config, resource);
        }
        fetch(finalImageUrl)
            .then(response => response.blob())
            .then(blob => this.props.onSelectImage(blob, imageId))
            .catch(error => console.error(error));

        this.setState({ imageSelected: false, isImageEdit: false, showDialog: false, showEditImageDialog: false });
    }

    handleEditClose = () => {
        const { tempFile } = this.state;
        if(tempFile) {
            removeTempFile(tempFile);
        }
        this.setState({ imageSelected: false, isImageEdit: false });
    }

    fetchImageAsBlob = (src) => {
          fetch(src)
          .then(response => response.blob())
          .then(blob => {
               this.setState({croppedImageUrl: blob})
          });
    }

    setAction = action => {
        this.setState({ action: action })
    }

    handleActionChange = (event) => {
        const {imgOriginalWidth, imgOriginalHeight, crop, percentCrop, tempFile} = this.state;
        this.computeImgBox(event.target.value, tempFile.name, crop, percentCrop, imgOriginalWidth, imgOriginalHeight);
        this.setAction(event.target.value);
    }

    render() {
        const { classes, translate, width, resource } = this.props;
        const { showDialog, imageSelected, isImageEdit, tracks, src, tempFile, previewImageUrl, crop, action, box, imageServiceURL, mainLoading, isImageSelecting,
         targetAspectRatio, urlPreview, focusPointPreview1, focusPointPreview2 } = this.state;

        return (
            <div style={{ display: 'none'}}>
                <Fragment>
                    <Responsive
                        small={
                            <Tooltip title={translate('pos.getty.dialog.title')}>
                                <AspectRatioIcon className={classes.icon} onClick={this.handleClick} />
                            </Tooltip>
                        }
                        medium={
                            <Button variant="contained" onClick={this.handleClick} label={translate('pos.getty.dialog.title')} />
                        }
                    />

                    <DefaultLoader mainLoading={mainLoading} />
                    <Dialog fullScreen open={showDialog} onClose={this.handleCloseClick} TransitionComponent={Transition}>
                        <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.handleCloseClick} aria-label="close">
                            <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                            {translate('pos.getty.dialog.title')}
                            </Typography>
                        </Toolbar>
                        </AppBar>
                        <div>
                            <span className={classes.searchItems}>
                                <Form
                                    onSubmit={this.handleSubmit}
                                    render={({ handleSubmit, form}) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className={classes.searchFormElContainer}>                             
                                                <div className={classes.searchFormEl}>
                                                    <Field
                                                        name="searchPhrase"
                                                    >
                                                    {props => (
                                                        <div className={classes.searchFormTextField}>
                                                            <TextField
                                                                fullWidth
                                                                placeholder="Phrase *"
                                                                name={props.input.name}
                                                                value={props.input.value}
                                                                onChange={props.input.onChange}
                                                                onKeyPress={this.handleKeyPress}
                                                            />
                                                        </div>
                                                    )}
                                                    </Field>
                                                </div>
                                                <div className={classes.searchFormEl}>     
                                                    <Button variant="contained" label={translate('pos.getty.dialog.search')} onClick={form.submit} className={classes.searchSubmitButton}>
                                                        <SearchIcon />
                                                    </Button>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                />
                            </span>
                        </div>
                        <Divider  variant="fullWidth"/>
                        <DialogContent>
                            <div style={{flexGrow: 1}}>
                                <Grid container spacing={1}>
                                    <Responsive
                                        medium={
                                            <Grid container item xs={12} spacing={2}>
                                                <Grid item xs={getGridItemSize('editBox', width, imageSelected)} className={classes.gridEdit}>
                                                    <div className={classnames({
                                                        [classes.hide]: !isImageEdit,
                                                        [classes.editBox]: isImageEdit
                                                    })}>
                                                        <EditBox
                                                            translate={translate}
                                                            src={src}
                                                            crop={crop}
                                                            onImageLoaded={this.onImageLoaded}
                                                            onCropComplete={this.onCropComplete}
                                                            onCropChange={this.onCropChange}
                                                            saveEditImage={this.saveEditImage}
                                                            handleEditClose={this.handleEditClose}
                                                            urlPreview1={focusPointPreview1}
                                                            urlPreview2={focusPointPreview2}
                                                            urlPreview={urlPreview}
                                                            classes={classes}
                                                            action={action}
                                                            handleActionChange={this.handleActionChange}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={getGridItemSize('listBox', width, imageSelected)} className={classes.gridList}>
                                                    <div className={classnames({
                                                        [classes.tracks]: !imageSelected,
                                                        [classes.tracksEdit]: imageSelected
                                                    })}>
                                                        <div className={classes.items}>
                                                            <div className={classes.imageSelectingLoading}>
                                                                {isImageSelecting && <CircularProgress className={classes.progress}  size={70} value={50} />}
                                                            </div>
                                                            {tracks &&
                                                                <GridListBox
                                                                    translate={translate}
                                                                    classes={classes}
                                                                    tracks={tracks}
                                                                    cols={getColsForWidth(width, imageSelected)}
                                                                    imageClick={this.imageClick}
                                                                    resource={resource}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>

                                        }
                                    />
                                </Grid>
                            </div>
                        </DialogContent>
                        <DialogContent>
                            <div>
                                <BarLoader
                                className={override}
                                width={300}
                                color={'#123abc'}
                                loading={this.state.loading}
                                />
                        </div>

                    </DialogContent>
                    <DialogActions>
                            <div className={classes.loadMore}>
                            <Button variant="contained" color="primary" className={this.state.mode === 'load' ? classes.show : classes.hide} label={translate('pos.getty.dialog.loadMore')} onClick={this.loadItems} >
                                <MoreIcon />
                            </Button>
                        </div>
                    </DialogActions>

                        <DialogActions>

                            <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
                                <IconCancel />
                            </Button>
                        </DialogActions>

                    </Dialog>


                </Fragment>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    isSubmitting: isSubmitting('post-search')(state)
});

const mapDispatchToProps = {
    change,
    fetchEnd,
    fetchStart,
    showNotification,
    submit
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    translate,
    withStyles(styles),
    withWidth(),
)(GettyImageButton);

const ImgEditComponentBase = props => {
    const { translate, classes, reshapeUrl, handleEditImageCloseClick, showEditImageDialog, imageSrc, src, crop, box, onImageLoaded, onCropComplete, onCropChange, saveEditImage,
            action, handleActionChange, targetAspectRatio, urlPreview, focusPointPreview1, focusPointPreview2 } = props;

    return (
        <Dialog
            className={classes.imgEditDialog}
            open={showEditImageDialog}
            onClose={handleEditImageCloseClick}
            PaperProps={{
                  style: {
                    minHeight: "65vh",
                    minWidth: "65vw"
                  },
            }}
        >
            <DialogContent>
                <div style={{textAlign: 'center'}}>
                    {imageSrc &&

                    <EditBox
                        translate={translate}
                        src={imageSrc}
                        crop={crop}
                        onImageLoaded={onImageLoaded}
                        onCropComplete={onCropComplete}
                        onCropChange={onCropChange}
                        saveEditImage={saveEditImage}
                        handleEditClose={handleEditImageCloseClick}
                        urlPreview1={focusPointPreview1}
                        urlPreview2={focusPointPreview2}
                        urlPreview={urlPreview}
                        classes={classes}
                        action={action}
                        handleActionChange={handleActionChange}
                    />
                    }
                </div>
            </DialogContent>
        </Dialog>
    );
};

const ImgEditComponent = compose(translate, withStyles(styles))(ImgEditComponentBase);

export { ImgEditComponent };

