import React from 'react';
import { Create, TextInput, SelectInput, TabbedForm, FormTab, AutocompleteInput } from 'react-admin';
import { ResourceActions } from '../../buttons/ResourceAction';
import { typeChoices } from './CommentaryList';

interface Error {
    matchId?: string[],
    langId?: string[]
}

export const validateCommentary = (values) => {
    const errors: Error = {};
    if(!values.matchId) {
        errors.matchId = ['match id is required'];
    }
    if(!values.langId) {
        errors.langId = ['lang id is required'];
    }
    return errors;
}

export const CommentaryCreate = (props) => (

        <Create {...props} actions={<ResourceActions />} >
            <TabbedForm redirect="list">
                <FormTab label="Main Content">
                    <TextInput disabled source="matchId" label="Match Id"/>
                    <TextInput source="langId" label="Lang Id" />
                    <TextInput multiline source="message" />
                    <AutocompleteInput source="eventType" choices={typeChoices} />
                    <TextInput source="second" />
                    <TextInput source="matchMinute" />
                    <TextInput source="homeScore" />
                    <TextInput source="awayScore" />
                    <SelectInput source="period" choices={[
                        { id: '0', name: 'PRE_MATCH' },
                        { id: '1', name: 'FIRST_HALF' },
                        { id: '2', name: 'FIRST_HALF_STOP' },
                        { id: '3', name: 'SECOND_HALF' },
                        { id: '4', name: 'SECOND_HALF_STOP' },
                        { id: '5', name: 'FIRST_EXTRA_PERIOD' },
                        { id: '6', name: 'FIRST_EXTRA_PERIOD_STOP' },
                        { id: '7', name: 'SECOND_EXTRA_PERIOD' },
                        { id: '8', name: 'SECOND_EXTRA_PERIOD_STOP' },
                        { id: '9', name: 'PENALTY_SHOOTOUT' },
                        { id: '10', name: 'END_OF_MATCH' }
                    ]} />
                </FormTab>
                <FormTab label="Additional Content (Json)">
                    <TextInput source="additionalContent.title" label="title"/>
                    <TextInput source="additionalContent.description" label="description" />
                    <TextInput source="additionalContent.link" label="link" />
                    <TextInput source="additionalContent.imageUrl" label="image url" />
                    <TextInput source="additionalContent.ozID" label="ozID" />
                </FormTab>
            </TabbedForm>
        </Create>
);