import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput } from 'react-admin';
import { DefaultToolbar } from '../DefaultToolbar';
import { ResourceActions } from '../../buttons/ResourceAction';

type ValueType = {
    langId: string,
    eventType: string
}

type ErrorType = {
    langId: string[],
    eventType: string[]
}
export const validateCommentary = (values: ValueType) => {
    const errors = {} as ErrorType;
    if(!values.langId) {
        errors.langId = ['lang id is required'];
    }
    if(!values.eventType) {
        errors.eventType = ['eventType is required'];
    }
    return errors;
}

export const eventTypeChoices =
    [
       { id: 'comment', name: 'comment' },
       { id: 'start', name: 'start' },
       { id: 'end', name: 'end' }
    ]


export const CommentaryCreate = (props) => (

        <Create {...props} actions={<ResourceActions />} >
            <SimpleForm toolbar={<DefaultToolbar />} validate={validateCommentary} >
                <TextInput source="langId" label="Lang Id" />
                <TextInput disabled source="matchId" />
                <TextInput source="minute" />
                <TextInput source="sort" />
                <TextInput multiline source="message" />
                <SelectInput source="eventType" choices={eventTypeChoices} />
            </SimpleForm>
        </Create>
);