import SoccerTeamStandingList from './SoccerTeamStandingList';
import SoccerTeamStandingEdit from './SoccerTeamStandingEdit';
import Icon from '@material-ui/icons/Flag';

export const CountryIcon = Icon;

export default {
  list: SoccerTeamStandingList,
  edit: SoccerTeamStandingEdit
}

