import CountryList from './CountryList';
import CountryEdit from './CountryEdit';
import Icon from '@material-ui/icons/Flag';

export const CountryIcon = Icon;

export default {
    list: CountryList,
    edit: CountryEdit
}

