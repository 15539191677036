import TeamList from './TeamList';
import TeamEdit from './TeamEdit';
import Icon from '@material-ui/icons/Group';

export const TeamIcon = Icon;

export default {
    list: TeamList,
    edit: TeamEdit
}
