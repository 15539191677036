import { API_URL, getLogoutUrl } from './helpers/consts';

const providerUploadFeature = requestHandler => (type, resource, params) => {
    if (type === 'UPDATE') {

        var serverPrefix = process.env.NODE_ENV === 'development' ? API_URL : '';
        var headers = new Headers({ Accept: 'application/json' });
        headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);

        let requestPart;
        if(resource === 'event') {
            const eventControllerPostFix = localStorage.getItem('eventControllerPostFix');
            requestPart = `${resource}/${eventControllerPostFix}`;
        }
        else {
            requestPart = resource;
        }

        if (params.data.logo) {
            if (params.data.logo.rawFile) {
                 const rawFile = params.data.logo.rawFile;
                 let form = new FormData();
                 form.set('file', rawFile);

                 const request = new Request(`${serverPrefix}/api/${requestPart}/upload/${params.data.id}`, {
                        method: 'POST',
                        headers: headers,
                        body: form,
                     });

                 return fetch(request)
                 .then((response) => {
                    if (response.status === 200) {
                        if(params && params.data) {
                            if(params.data.hasOwnProperty('isImageAvailable')) {
                                params.data.isImageAvailable = true;
                            }
                        }
                        return requestHandler(type, resource, params);
                    }
                    if (response.status === 401 || response.status === 403) {
                        localStorage.removeItem('token')
                        window.location = getLogoutUrl();
                    }
                    return Promise.reject(new Error('An Error occurred. Could not upload image'));
                 });
            }
        } else if (params.data.isImageAvailable != null) {
            const request = new Request(`${serverPrefix}/api/${requestPart}/delete/${params.data.id}`, {
                method: 'DELETE',
                headers: headers,
            });

            return fetch(request)
                .then((response) => {
                    if (response.status === 200) {
                        if(params && params.data) {
                            if(params.data.hasOwnProperty('isImageAvailable')) {
                                params.data.isImageAvailable = false;
                            }
                        }
                        return requestHandler(type, resource, params);
                    }
                    if (response.status === 401 || response.status === 403) {
                        localStorage.removeItem('token')
                        window.location = getLogoutUrl();
                    }
                    return Promise.reject(new Error('An Error occurred. Could not delete image'));
                });
        }
    }
    return requestHandler(type, resource, params);
};

export default providerUploadFeature;