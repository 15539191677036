import React from 'react';
import { Paper, Input, FormControl, InputLabel } from '@material-ui/core';
import { fade, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import { DateInput, FieldTitle } from 'react-admin';
import { InputBase } from './InputBase';
import DateRangeIcon from '@material-ui/icons/DateRange';

const useStyles = makeStyles(theme => ({
  root: {
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },

}));

const dateFormatter = v => {
    if (!(v instanceof Date) || isNaN(v.getDate())) return;
    const pad = '00';
    const yyyy = v.getFullYear().toString();
    const MM = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();
    return `${yyyy}-${(pad + MM).slice(-2)}-${(pad + dd).slice(-2)}`;
};

const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

const sanitizeValue = value => {
    // null, undefined and empty string values should not go through dateFormatter
    // otherwise, it returns undefined and will make the input an uncontrolled one.
    if (value == null || value === '') {
        return '';
    }

    if (value instanceof Date) {
        return dateFormatter(value);
    }

    // valid dates should not be converted
    if (dateRegex.test(value)) {
        return value;
    }

    return dateFormatter(new Date(value));
};

const CustomDateInput = props => {
  const { className, style, label, resource, source, isRequired, options, input, meta, ...rest } = props;

  if (typeof meta === 'undefined') {
      throw new Error(
          "The DateInput component wasn't called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details."
      );
  }
  const { touched, error } = meta;

  const value = sanitizeValue(input.value);

  const classes = useStyles();
  console.log(props)

  return (
        <FormControl>
           <InputLabel>
            <FieldTitle
                label={label}
                source={source}
                resource={resource}
                isRequired={isRequired}
            />
           </InputLabel>
          <InputBase
            type='date'
            {...rest}
            className={classes.input}
            disableUnderline
            onChange={props.input.onChange}
            value={value}
            placeholder={label}
           />
        </FormControl>

  );
};

CustomDateInput.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
};

export default CustomDateInput;
