import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import SportOrder from './SportOrder';
import { translate, WithPermissions } from 'react-admin';
import compose from 'recompose/compose';
import {isAdmin, isEditor} from '../permissions';
import { SportAdminPage } from '../components/customs';


class SportOrderPageBase extends Component{

    componentDidMount(){
        if(this.props.match.params.id) {
            localStorage.setItem('sportId', this.props.match.params.id)
        }
    }

    render() {
        const { permissions } = this.props;
        return (
            <SportAdminPage>
                <Card>
                    {(isAdmin(permissions) || isEditor(permissions)) ?
                        <SportOrder authParams={{ key: this.props.match.path, params: this.props.match.params }} /> : null
                    }
                </Card>
            </SportAdminPage>
        )
    }

}

const SportOrderPage = (props) => (
    <WithPermissions
        authParams={{ key: props.match.path, params: props.match.params }}
        location={props.location}
        render={({ permissions }) => <SportOrderPageBase permissions={permissions} {...props} /> }
    />
);

export default compose(
    translate
)(SportOrderPage);