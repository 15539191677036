import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput } from 'react-admin';
import { DefaultToolbar } from '../DefaultToolbar';
import { ResourceActions } from '../../buttons/ResourceAction';
import { validateCommentary, eventTypeChoices } from '../../commentaries/basketball/CommentaryCreate';
import { AnyARecord } from 'dns';

export const CommentaryCreate = (props: any) => (

        <Create {...props} actions={<ResourceActions />} >
            <SimpleForm toolbar={<DefaultToolbar />} validate={validateCommentary} >
                <TextInput source="langId" label="Lang Id" />
                <TextInput disabled source="matchId" />
                <TextInput source="set" />
                <TextInput source="sort" />
                <TextInput multiline source="message" />
                <SelectInput source="eventType" choices={eventTypeChoices} />
            </SimpleForm>
        </Create>
);