import React, { FC } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useTranslate, Title } from 'react-admin';
import compose from 'recompose/compose';

const AppNoAccess: FC = () => {
    const translate = useTranslate();
    return (
        <Card>
            <Title title={translate('resources.noAccess.app.title')} />
            <CardContent>
                {translate('resources.noAccess.app.message')}
            </CardContent>
        </Card>
    )
}

export default AppNoAccess;
