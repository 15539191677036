import React from 'react';
import { Edit, SimpleForm, TextInput, ImageField, Responsive } from 'react-admin';
import { ResourceActions } from '../buttons/ResourceAction';
import { DefaultToolbar } from '../helpers/DefaultToolbar';
import CustomImageInput from '../fileInput/CustomImageInput';

const OfficialTitle = (props: any) => {
    const { record } = props;
    return <span>Official {record ? `"${record.lastName} ${record.firstName}"` : ''}</span>;
};

export const OfficialEdit = (props) => (
    <Edit title={<OfficialTitle />} {...props} actions={<ResourceActions />}>
        <SimpleForm toolbar={<DefaultToolbar />}>
            <TextInput disabled source="officialsId" label="Id" />
            <TextInput disabled source="standardPosition" />
            <TextInput disabled source="firstName" />
            <TextInput disabled source="lastName" />
            <TextInput disabled source="birthDate" />
            <TextInput disabled source="weight" />
            <TextInput disabled source="height" />
            <TextInput disabled source="nickName" />
            <TextInput disabled source="birthPlace" />
            <TextInput disabled source="nationality" />
            <TextInput disabled source="preferredFoot" />
            <TextInput disabled source="realPosition" />
            <TextInput disabled source="realPositionSide" />
            <TextInput disabled source="country" />

            <CustomImageInput source="logo" label="Logo" accept="image/jpeg" multiple={false}>
                <ImageField source="src" />
            </CustomImageInput>
        </SimpleForm>
    </Edit>
);