import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { useTranslate, usePermissions, useLocale, useSetLocale, Title } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import TabView from '../TabView'
import moment from 'moment'
import { buildRequest, checkResponse } from '../helpers/request'
import SportOrder from './SportOrder';
import {isAdmin, isEditor} from '../permissions';
import { SportAdminPage } from '../components/customs';

const useStyles = makeStyles(theme => ({
    label: { width: '10em', display: 'inline-block' },
    button: { margin: '1em' },
}))

var sorts = []

const Configuration = props => {

    const [date, setDateState] = useState(moment().format('YYYY-MM-DD'));
    const [cards, setCards] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [sortOverridePrefix, setSortOverridePrefix] = useState('group');
    const [overrideConfirmDialogOpen, setOverrideConfirmDialogOpen] = useState(false);

    useEffect(() => {
        const day = moment().format('YYYY-MM-DD');
        setCurrentDate(day)
        getSportGroups(day)
    }, [])

    const setDate = (day = moment().format('YYYY-MM-DD')) => {
        setDateState(day);
        setSelectedOption(null);
        setSortOverridePrefix('group');
        setCurrentDate(day)
        getSportGroups(day)
    }

    const setCurrentDate = date => {
        if (typeof props.setDate === 'function') {
            props.setDate(date)
        }
    }

    const handleChange = (selectedOption) => {

        setSelectedOption(selectedOption);
        setSortOverridePrefix('sport');
        getSports(date, selectedOption.value);
    }

    const getSportGroups = date => {
        const request = buildRequest(`group/sort?when=${date}`, 'GET')

        fetch(request)
            .then(checkResponse)
            .then( (json) => {
                setCards(json);
                json.forEach(group => {
                    setOptions([...options, {value: group.id, label: group.name}])
                    sorts.push(group.sort)
                })
            })
            .catch(error => console.error('Error:', error));

    }

    const getSports = (date, groupId) => {
        const request = buildRequest(`sport/group?when=${date}&groupId=${groupId}`, 'GET')

        fetch(request)
            .then(checkResponse)
            .then( (json) => {
                setCards(json);
                json.forEach(sport => {
                    sorts.push(sport.sort)
                })
            })
            .catch(error => console.error('Error:', error));

    }

    const setSports = date => {
        const url = selectedOption ? `sport/rank?when=${date}&groupId=${selectedOption.value}` : `group/rank?when=${date}`;
        const request = buildRequest(url, 'PUT', JSON.stringify(cards))

        fetch(request)
        .then(checkResponse);

    }

    const handleGroupOverride = () => {
        const request = buildRequest(`group/override?when=${date}`, 'DELETE');

        fetch(request)
            .then(checkResponse)
            .then(getSportGroups(date))
            .catch(error => console.error('Error:', error));
    }

    const handleSportOverride = () => {
        const request = buildRequest(`sport/override?when=${date}&groupId=${selectedOption.value}`, 'DELETE');

        fetch(request)
            .then(checkResponse)
            .then(getSports(date, selectedOption.value))
            .catch(error => console.error('Error:', error));
    }

    const showOverrideConfirmDialog = () => {
        setOverrideConfirmDialogOpen(true);
    }

    const closeOverrideConfirmDialog = () => {
        setOverrideConfirmDialogOpen(false);
    }

    const confirmOverride = () => {
        sortOverridePrefix === 'group' ? handleGroupOverride() : handleSportOverride();
        setOverrideConfirmDialogOpen(false);
    }

    const classes = useStyles();
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const locale = useLocale();
    const setLocale = useSetLocale();

    const labels = (isAdmin(permissions) || isEditor(permissions)) ? [translate('pos.configuration.tabs.priorities'), translate('pos.configuration.tabs.settings')] : [translate('pos.configuration.tabs.settings')];

    return (
        <SportAdminPage>
            <Card>
                <Title title={translate('pos.configuration.title')} />
                <CardContent>
                <TabView labels={labels} >
                    {(isAdmin(permissions) || isEditor(permissions)) ?
                        <SportOrder /> : null
                    }
                    <CardContent>
                        <CardContent>
                            <div className={classes.label}>{translate('pos.language')}</div>
                            <Button
                                variant="raised"
                                className={classes.button}
                                color={locale === 'de' ? 'primary' : 'default'}
                                onClick={() => setLocale('de')}
                            >
                                de
                            </Button>
                            <Button
                                variant="raised"
                                className={classes.button}
                                color={locale === 'en' ? 'primary' : 'default'}
                                onClick={() => setLocale('en')}
                            >
                                en
                            </Button>
                        </CardContent>
                    </CardContent>
                </TabView>
                </CardContent>
            </Card>
        </SportAdminPage>

    )

}

export default Configuration;