import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Text } from '@sport1/universal-components'

export default class SessionMatch extends Component {
    static propTypes = {
        competitionName: PropTypes.string.isRequired,
        sessionName: PropTypes.string.isRequired
    }

    render() {
        const { competitionName, sessionName } = this.props
        return (
            <Row center mt={2} mb={2}>
                <Text>{competitionName} - {sessionName}</Text>
            </Row>
        )
    }
}