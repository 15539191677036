import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Hcalendar from '../calendar/Hcalendar'
import { observer,inject } from 'mobx-react'
import MatchScores from '../matches/MatchScores'
import moment from 'moment'
import { buildRequest, checkResponse } from '../helpers/request';

/*@inject('matchStore')
@observer*/
class MatchGridList extends Component{

    constructor(props) {
        super(props)
        this.state = {
            date: moment().format('YYYY-MM-DD'),
            sportId: this.props.sportId? this.props.sportId : this.fetchDefaultSport(),
        }
    }

    componentDidMount(day = moment().format('YYYY-MM-DD')) {
        this.setCurrentDate(day)
        //this.props.matchStore.fetchMatches(day, this.state.sportId)
    }

    setDate = (day = moment().format('YYYY-MM-DD')) => {
            this.setState({ date: day })
            this.setCurrentDate(day)
            //this.props.matchStore.fetchMatches(day, this.state.sportId)
    }

    setCurrentDate = date => {
        if (typeof this.props.setDate === 'function') {
            this.props.setDate(date)
        }
    }

    fetchDefaultSport = () => {
        const request = buildRequest('config/sport/default', 'GET')

        fetch(request)
            .then(checkResponse)
            .then( (json) => {
                this.setState({
                    sportId: json.sport.id
                });
            })
            .catch(error => console.error('Error:', error));

    }

    render() {
        /*const ids = this.props.matchStore.ids === null?this.props.ids:this.props.matchStore.ids;
        const matches = this.props.matchStore.matches;*/
        const ids = [];
        const matches = [];

        const { date } = this.state

        return (
                <div style={{ width: '60%', margin: 'auto' }}>
                    <Hcalendar setDate={this.setDate} selectedDate={date} />
                    <MatchScores
                        isEmpty={false}
                        isLoading={false}
                        error={false}
                        date={date}
                        ids={ids}
                        matches={matches}
                        sportId={this.state.sportId}
                    />
                </div>

        )
    }
}

MatchGridList.propTypes = {
    push: PropTypes.func
};

export default MatchGridList;
