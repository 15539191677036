import React, { useState, useEffect, useRef, Component, createElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import clsx from 'clsx';
import {
    createMuiTheme,
    withStyles,
    createStyles,
} from '@material-ui/core/styles';
import compose from 'recompose/compose';
import withWidth from '@material-ui/core/withWidth';
import { Notification, Error } from 'react-admin';
import Sidebar from './Sidebar';
import Menu from './Menu';
import AppBar from './Appbar';
import { ThemeProvider } from '@material-ui/styles';
import defaultTheme from '../theme';

const styles = theme =>
    createStyles({
        root: {
            height: '100%',
        },
        appFrame: {
            display: 'flex',
            flexDirection: 'column',
        },
        contentWithSidebar: {
            height: '100%',
        },
        content: {
            paddingTop: 92,
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
            flexGrow: 1,
            flexBasis: 0,
            [theme.breakpoints.up('xs')]: {
                paddingLeft: theme.spacing(3),
            },
        },
        shiftContent: {
            paddingLeft: 240
        },
    });

const sanitizeRestProps = ({
    staticContext,
    history,
    location,
    match,
    ...props
}) => props;


class Layout extends Component {
    state = { hasError: false, errorMessage: null, errorInfo: null };

    constructor(props) {
        super(props);
        /**
         * Reset the error state upon navigation
         *
         * @see https://stackoverflow.com/questions/48121750/browser-navigation-broken-by-use-of-react-error-boundaries
         */
        props.history.listen(() => {
            if (this.state.hasError) {
                this.setState({ hasError: false });
            }
        });
        this.state = {
            openSidebar: false,
        }
    }

    componentDidCatch(errorMessage, errorInfo) {
        this.setState({ hasError: true, errorMessage, errorInfo });
    }

    handleSidebarOpen = () => {
        this.setState({ openSidebar: true });
    };

    handleSidebarClose = () => {
        this.setState({ openSidebar: false });
    };

    render() {
        const {
            appBar,
            children,
            classes,
            className,
            customRoutes,
            error,
            dashboard,
            logout,
            menu,
            notification,
            open,
            sidebar,
            title,
            width,
            ...props
        } = this.props;

        const { hasError, errorMessage, errorInfo, openSidebar } = this.state;

        const isDesktop = (width === 'lg' || width === 'xl') ? true : false;

        const shouldOpenSidebar = isDesktop ? true : openSidebar;

        return (
            <div
              className={clsx({
                [classes.root]: true,
                [classes.shiftContent]: isDesktop
              })}
                {...sanitizeRestProps(props)}
            >
                <div className={classes.appFrame}>
                    <AppBar title open logout={logout} onSidebarOpen={this.handleSidebarOpen} />
                    <main className={classes.contentWithSidebar}>
                        {createElement(sidebar, {
                            children: createElement(menu, {
                                logout,
                                hasDashboard: !!dashboard,
                            }),
                            variant: isDesktop ? 'persistent' : 'temporary',
                            onClose: this.handleSidebarClose,
                            open: shouldOpenSidebar,
                        })}
                        <div className={classes.content}>
                            {hasError
                                ? createElement(error, {
                                      error: errorMessage,
                                      errorInfo,
                                      title,
                                  })
                                : children}
                        </div>
                    </main>
                    {createElement(notification)}
                </div>
            </div>
        );
    }
}

const componentPropType = PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
]);

Layout.propTypes = {
    appBar: componentPropType,
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    classes: PropTypes.object,
    className: PropTypes.string,
    customRoutes: PropTypes.array,
    dashboard: componentPropType,
    error: componentPropType,
    history: PropTypes.object.isRequired,
    logout: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func,
        PropTypes.string,
    ]),
    menu: componentPropType,
    notification: componentPropType,
    open: PropTypes.bool,
    sidebar: componentPropType,
    title: PropTypes.node.isRequired,
};

Layout.defaultProps = {
    appBar: AppBar,
    error: Error,
    menu: Menu,
    notification: Notification,
    sidebar: Sidebar,
};

const mapStateToProps = state => ({
    open: state.admin.ui.sidebarOpen,
});

const EnhancedLayout = compose(
    connect(
        mapStateToProps,
        {} // Avoid connect passing dispatch in props
    ),
    withRouter,
    withStyles(styles),
    withWidth(),
)(Layout);

const LayoutWithTheme = ({ ...props }) => {
    return (
        <ThemeProvider theme={defaultTheme}>
            <EnhancedLayout {...props} />
        </ThemeProvider>
    );
};

LayoutWithTheme.propTypes = {
    theme: PropTypes.object,
};

LayoutWithTheme.defaultProps = {
    theme: defaultTheme,
};

export default LayoutWithTheme;