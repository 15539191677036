import englishMessages from 'ra-language-english';

export default {
    ...englishMessages,
    pos: {
        dashboard: {
            changes: {
                title: 'CHANGES',
                subtitle: 'Change since last month |||| Changes since last month'
            },
            imageAvailableCount: {
                title: 'Images available'
            },
            tickerSports: {
                title: 'ACTIVE TICKER SPORTS',
            },
            totalSports: {
                title: 'TOTAL SPORTS',
            },
        },
        configuration: {
            title: 'Configuration',
            tabs: {
                priorities: 'Priorities',
                settings: 'Settings',
            },
            select: {
                placeholder: 'Select a group for in-group ordering'
            },
            sort: {
                override:{
                    button: {
                        title: 'Reset Sorting'
                    },
                    dialog: {
                        title: 'Reset Sorting',
                        message: 'Are you sure?',
                        yes: 'Yes',
                        no: 'No'
                    }
                }
            }
        },
        language: 'Language',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        getty: {
            main: {
                title: 'Import Getty Image'
            },
            dialog: {
                title: 'Import Getty Image',
                image: {
                   edit: {
                       title: 'Focal point not correct? move box to focal and resize to fit, if required.',
                       accept: 'Validate'
                   }
                },
                loadMore: 'Load More',
                search: 'Search'
            }
         },
         sportOrder: {
             name: 'Sport Order |||| Sports Order'
         },
         menu: {
             ticker: 'Ticker',
             catalog: 'Catalog',
             setup: 'Setup',
         },
        auth: {
            title: 'Sign in',
            or: 'or',
            ms: {
                sign_in: 'Log in with Microsoft'
            },
        }
    },
     resources: {
            competition: {
                name: 'Competition |||| Competitions',
                fields: {
                     id: 'id',
                     nameDE: 'nameDE',
                     isActive: 'isActive',
                     hasTicker: 'hasTicker',
                     hasStandings: 'hasStandings',
                     isTop: 'isTop',
                     countryCode: 'countryCode',
                     topSort: 'topSort',
                }
            },
            event: {
                name: 'Event |||| Events',
                fields: {
                      id: 'id',
                      name: 'Name',
                      startDate: 'startDate',
                      endDate: 'endDate',
                      stageType: 'stageType',
                      status: 'status',
                      nameDE: 'nameDE',
                      shortName: 'shortName',
                      shortNameDE: 'shortNameDE',
                      season: 'season',
                },
            },
            session: {
                name: 'Session |||| Sessions',
            },
            team: {
                name: 'Team |||| Teams',
                fields: {
                    code: 'code',
                    country: 'country',
                    city: 'city',
                    founded: 'founded',
                    postalCode: 'Postal code',
                    regionName: 'Region name',
                    shortName: 'Short name',
                    street: 'street',
                    webAddress: 'Web address',
                    nameDE: 'Name DE',
                    shortNameDE: 'Short name DE',
                }
            },
            player: {
                name: 'Player |||| Players',
                fields: {
                    standardPosition: 'standardPosition',
                    firstName: 'firstName',
                    lastName: 'lastName',
                    birthDate: 'birthDate',
                    weight: 'weight',
                    height: 'height',
                    nickName: 'nickName',
                    birthPlace: 'birthPlace',
                    nationality: 'nationality',
                    preferredFoot: 'preferredFoot',
                    realPosition: 'realPosition',
                    realPositionSide: 'realPositionSide',
                    country: 'country'
                }
            },
            country: {
                name: 'Country |||| Countries',
                fields: {
                    code: 'ioc',
                    ioc: 'ioc',
                    en: 'en',
                    de: 'de',
                    es: 'es',
                    fr: 'fr',
                    it: 'it',
                    ru: 'ru',
                    optaCode: 'optaCode',
                }
            },
            season: {
                name: 'Season |||| Seasons',
                fields: {
                    id: 'id',
                    name: 'name',
                    nameDE: 'nameDE',
                    startDate: 'startDate',
                    endDate: 'endDate',
                    year: 'year',
                    competitionId: 'competitionId',
                    isCurrent: 'isCurrent',
                    sportId: 'sportId'
                }
            },
            sport: {
                name: 'Sport |||| Sports',
                matches: 'Matches',
                fields: {
                    id: 'id',
                    name: 'name',
                    nameDE: 'nameDE',
                    isActive: 'isActive',
                    sort: 'sort',
                    sportGroupId: 'sportGroupId',
                }
            },
            group: {
                name: 'Sport Category |||| Sport Categories',
                fields: {
                    id: 'id',
                    name: 'name',
                    nameDE: 'nameDE',
                    sort: 'sort',
                }
            },
            ticker: {
                name: 'Ticker |||| Tickers'
            },
            match: {
                name: 'Match |||| Matches',
                del: {
                   dialog: {
                       title: 'Delete matches',
                       message: {
                           matchSingular: 'match',
                           matchPlural: 'matches',
                           prefix: 'Are you sure you want to delete',
                           suffix: '?'
                       }
                   }
                }
            },
            task: {
                create: {
                  title: 'Create new task',
                  button: {
                    text: 'New task'
                  }
                },
                edit: {
                    title: 'Modify task',
                },
                viewAll: 'View all',
            },
            officials: {
                name: 'Official |||| Officials',
                fields: {
                    team: 'team',
                    firstName: 'firstName',
                    lastName: 'lastName',
                    birthDate: 'birthDate',
                    nickName: 'nickName',
                    birthPlace: 'birthPlace',
                    nationality: 'nationality',
                    country: 'country'
                }
            },
            referee: {
                name: 'Referee |||| Referees',
                fields: {
                    firstName: 'firstName',
                    lastName: 'lastName',
                    birthDate: 'birthDate',
                    nickName: 'nickName',
                    birthPlace: 'birthPlace',
                    country: 'country'
                }
            },
            redaction: {
                name: 'Redaction Reset',
                reset: {
                    successMessage: 'A reset request for successfully sent to the PollerService for %{resourceGroup} - %{resource}!'
                }
            },
            'standings-group': {
                name: 'Standings Group'
            },
            'team-standings': {
                name: 'Team Standings'
            },
            noAccess: {
                app: {
                    title: 'Access not allowed',
                    message: "You don't have enough right to use this App"
                }
            },
            purge: {
                title: 'Purge image from CDN.'
            },
            'soccer-team-standing': {
              name: 'Soccer Team Standing'
            }
     }
}