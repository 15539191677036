import React from 'react';
import { TextField, Responsive, DateField, Filter } from 'react-admin';
import { List, Datagrid } from '../components/customs';
import { SearchInput, DateInput } from '../components/customs/Input';

const ChangesFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <DateInput source="changedOnUtc" label="Changed On" />
    </Filter>
);

const ChangesList = ({ permissions, ...props }) => (
    <List {...props} sort={{ field: 'changedOnUtc', order: 'DESC' }} filters={<ChangesFilter />} bulkActionButtons={false}>
        <Responsive
            medium={
                <Datagrid>
                    <TextField source="description" />
                    <TextField source="user" />
                    <DateField source="changedOnUtc" label="Changed On" showTime />
                </Datagrid>
            }
        />
    </List>
);

export default ChangesList;