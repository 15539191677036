import React from 'react';
import {
    BooleanField,
    TextField,
    CloneButton,
    CardActions,
    ExportButton,
    Filter,
    TextInput
} from 'react-admin';
import { List, Datagrid } from '../../components/customs';
import CommentaryEditButton from '../../commentaries/EditButton'
import CommentaryCreateButton from '../../commentaries/CreateButton'
import isEmpty from 'lodash/isEmpty'
import { BackButton } from '../../buttons/ResourceAction';
import { getUrlVars } from '../../commentaries/motorsport/CommentaryList';
import { isAdmin, isMatchEditor } from '../../permissions';
import { SearchInput } from '../../components/customs/Input';
import { BulkActionButtons } from '../../components/buttons';

import Icon from '@material-ui/icons/Comment';
export const CommentaryIcon = Icon;

export const getDefaultRecord = (search) => {
    const data = getUrlVars(decodeURIComponent(search));

    const filter = !isEmpty(data) ? JSON.parse(data.filter) : {};

    if(!isEmpty(filter)) {
        if(!isEmpty(filter.matchId)) {
            localStorage.setItem("matchId", filter.matchId)
        }
        if(!isEmpty(filter.langId)) {
            localStorage.setItem("langId", filter.langId)
        }
        return {matchId: filter.matchId, langId: filter.langId, eventType: 'comment'}
    }else {
        return {matchId: localStorage.getItem("matchId"), langId: localStorage.getItem("langId"), eventType: 'comment'}
    }
}

export const CommentaryActions = (props) => {
    const {
        basePath,
        currentSort,
        exporter,
        filterValues,
        resource,
        permissions,
        record
    } = props;
    return (
        <CardActions>
            <BackButton />
            {(isAdmin(permissions) || isMatchEditor(permissions)) && <CommentaryCreateButton record={record} basePath={basePath} />}
            <ExportButton
                resource={resource}
                sort={currentSort}
                filter={filterValues}
                exporter={exporter}
            />
        </CardActions>
    )
}

export const CommentaryFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <TextInput source="langId" alwaysOn />
    </Filter>
);

export const typeChoices = [
    { id: 'attempt blocked', name: 'attempt blocked' },
    { id: 'attempt saved', name: 'attempt saved' },
    { id: 'corner', name: 'corner' },
    { id: 'end', name: 'end' },
    { id: 'free kick lost', name: 'free kick lost' },
    { id: 'free kick won', name: 'free kick won' },
    { id: 'goal', name: 'goal' },
    { id: 'line up', name: 'line up' },
    { id: 'miss', name: 'miss' },
    { id: 'offside', name: 'offside' },
    { id: 'own goal', name: 'own goal' },
    { id: 'penalty lost', name: 'penalty lost' },
    { id: 'penalty miss', name: 'penalty miss' },
    { id: 'penalty saved', name: 'penalty saved' },
    { id: 'penalty won', name: 'penalty won' },
    { id: 'postponed', name: 'postponed' },
    { id: 'red card', name: 'red card' },
    { id: 'start', name: 'start' },
    { id: 'start delay', name: 'start delay' },
    { id: 'end delay', name: 'end delay' },
    { id: 'substitution', name: 'substitution' },
    { id: 'yellow card', name: 'yellow card' },
    { id: 'comment', name: 'comment' },
    { id: 'team news', name: 'team news' },
    { id: 'post_match summary', name: 'post_match summary' },
    { id: 'kick off', name: 'kick off' },
    { id: 'highlight', name: 'highlight' },
    { id: 'half time', name: 'half time' },
    { id: 'full time', name: 'full time' },
    { id: 'assist', name: 'assist' },
    { id: 'half_time summary', name: 'half_time summary' },
    { id: 'penalty goal', name: 'penalty goal' },
    { id: 'second_yellow red card', name: 'second_yellow red card' },
    { id: 'second half', name: 'second half' },
    { id: 'post', name: 'post' },
    { id: 'secondyellow card', name: 'secondyellow card' },
    { id: 'player retired', name: 'player retired' },
    { id: 'stats', name: 'stats' },
    { id: 'video referee', name: 'video referee' },
    { id: 'pre kick off', name: 'pre kick off' },
    { id: 'comment important', name: 'comment important' },
    { id: 'penalty_so miss', name: 'penalty_so miss' },
    { id: 'penalty_so goal', name: 'penalty_so goal' },
    { id: 'contentious referee decisions', name: 'contentious referee decisions' }

]

const SoccerIdTextField = props => {
    const recordId = props.record && props.record.id;
    console.log(recordId);
    if(!isEmpty(recordId)) {
        const index = recordId.lastIndexOf(',');
        if(index > 0) {
            props.record.id = recordId.slice(0, index);
        }
    }
    return <TextField {...props} />
}

export const CommentaryList = ({permissions, ...props}) => {
    const record = getDefaultRecord(props.location.search);

    return (
        <List {...props} sort={{ field: 'lastModified', order: 'DESC' }} filters={<CommentaryFilter />} actions={<CommentaryActions record={record} permissions={permissions} />}
            bulkActionButtons={(isAdmin(permissions) || isMatchEditor(permissions)) ? <BulkActionButtons /> : false} >
            <Datagrid>
                <SoccerIdTextField source="id" />
                <TextField source="langId" label="Lang Id" />
                <TextField source="message" />
                <BooleanField source="away" />
                <TextField source="eventType" label="Event Type" />
                <TextField source="second" />
                <TextField source="matchMinute" label="Match Minute" />
                <TextField source="homeScore" label="Score Home" />
                <TextField source="awayScore" label="Score Away" />
                <TextField source="period" />
                {(isAdmin(permissions) || isMatchEditor(permissions)) && <CloneButton />}
                {(isAdmin(permissions) || isMatchEditor(permissions)) && <CommentaryEditButton />}
            </Datagrid>
        </List>
)};