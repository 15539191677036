import React from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import SeasonReferenceField from './seasons/SeasonReferenceField';

export const GenericValueField = ({className, label, record, source, ...props}) => {
    const val = get(record, source);

    if(source !== 'venue' && source !== 'sport') {
        let finalVal;
        if(typeof val === 'boolean') {
            finalVal = JSON.stringify(val);
        }
        else if(source === 'season') {
            finalVal = <SeasonReferenceField record={record} label={source} {...props} />
        }
        else {
            finalVal = val && typeof val !== 'string' ? JSON.stringify(val) : val
        }

        if(!isEmpty(finalVal)) {
          return (
              <span className={className} >
                  <span style={{fontWeight: 'bold'}} >{label}</span>:&nbsp; {finalVal}
              </span>
          );
        }
    }
    return null;
}

const TrueValueField = ({className, label, record, source}) => {
    const val = get(record, source);

    let finalVal;
    if(typeof val === 'boolean') {
        finalVal = JSON.stringify(val);
    }
    else {
        finalVal = val && typeof val !== 'string' ? JSON.stringify(val) : val
    }

    if(!isEmpty(finalVal)) {
      return (
          <span className={className} >
              <span style={{fontWeight: 'bold'}} >{label}</span>:&nbsp; {finalVal}
          </span>
      );
    }
    return null;
}

export default TrueValueField;