import React from 'react';
import AvatarField from './AvatarField';

const AvatarNameField = (props: any) => {
    const { name, record, size } = props;
    return (
        <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', fontSize: 15 }}>
            <AvatarField record={record} size={size} />
            &nbsp;
            { name }
        </div>
    )
};

export default AvatarNameField;