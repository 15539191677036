import React from 'react';
import {
    TextField,
    CreateButton,
    CardActions,
    DateField,
    EditButton
} from 'react-admin';
import {  List, Datagrid } from '../components/customs';
import StatusTextField from '../Dashboard/TaskAssign/StatusTextField';


const TaskAssignActions = (
    props: any,
) => (
    <CardActions>
        <CreateButton basePath={props.basePath} variant='contained' />
    </CardActions>
);

const TaskAssignList = (props: any) => (
    <List {...props} sort={{ field: 'created_at', order: 'DESC' }} actions={<TaskAssignActions />} bulkActionButtons={false}>
        <Datagrid>
            <TextField source="description"/>
            <TextField source="createdBy"/>
            <DateField source="createdAt" showTime />
            <TextField source="assignedTo"/>
            <StatusTextField source="status" />
            <EditButton />
        </Datagrid>
    </List>
);

export default TaskAssignList;
