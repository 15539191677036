import React from 'react';
import { ReferenceField, TextField } from 'react-admin';

const EventReferenceField = props => {
    const sportType =localStorage.getItem('eventControllerPostFix');
    return (
    <ReferenceField label="Event" source="event.id" reference="event" {...props} linkType="show">
        <TextField source="name" />
    </ReferenceField>
)};

EventReferenceField.defaultProps = {
    source: 'event.id',
    addLabel: true,
    label: 'Event'
};

export default EventReferenceField;