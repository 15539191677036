import React, { useRef, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';

export const ProfileField = ({ record, size }) => (
    <Avatar
        src={record && record.logo ? `${record.logo.imageServiceUrl}${record.logo.mSize}${record.logo.option}${record.logo.src}` : null}
        size={size}
        style={{ width: size, height: size }}
    />
);

export const LogoField = (props) => {
    const { record, size } = props;
    return (
    <img
        src={record && record.logo ? `${record.logo.imageServiceUrl}${record.logo.mSize}${record.logo.option}${record.logo.src}` : null}
        alt=""
        srcSet={record && record.logo ? `${record.logo.imageServiceUrl}${record.logo.sSize}${record.logo.option}${record.logo.src} 1x, ${record.logo.imageServiceUrl}${record.logo.mSize}${record.logo.option}${record.logo.src} 2x` : null}
        style={{width: size, height: size}}
    />
)
}

export const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}