import React from 'react';
import { Edit, TextField, BooleanInput, EditButton, TextInput, SimpleForm, ImageField } from 'react-admin';
import { DefaultToolbar } from '../helpers/DefaultToolbar';
import { ResourceActions } from '../buttons/ResourceAction';
import isEmpty from 'lodash/isEmpty';
import { SportAdminPage } from '../components/customs';

const SessionTitle = (props: any) => {
    const { record } = props;
    return <span>Event {record ? record.name ? `"${record.name}"` : record.nameDE ? `"${record.nameDE}"` : `"${record.id}"` : ''}</span>;
};

const SessionEdit = (props) => (
    <SportAdminPage>
        <Edit title={<SessionTitle />} {...props} actions={<ResourceActions />}>
            <SimpleForm toolbar={<DefaultToolbar />}>
                <TextInput disabled source="id" />
                <TextInput disabled source="name" />
                <TextInput disabled source="startDate" />
                <TextInput disabled source="endDate" />
                <TextInput disabled source="stageType" />
                <TextInput disabled source="status" />
                <BooleanInput source="isHidden" />
                <TextInput multiple parse={v => isEmpty(v) ? null : v} source="nameDe" label="nameDE" />
                <TextInput multiple parse={v => isEmpty(v) ? null : v} source="shortName" label="shortName" />
                <TextInput multiple parse={v => isEmpty(v) ? null : v} source="shortNameDe" label="shortNameDE" />
            </SimpleForm>
        </Edit>
    </SportAdminPage>
);

export default SessionEdit;