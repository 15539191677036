import SessionList from './SessionList';
import SessionEdit from './SessionEdit';
import Icon from '@material-ui/icons/DateRange';

export const SessionIcon = Icon;

export default {
    list: SessionList,
    edit: SessionEdit
}




