import React from 'react';
import {Edit, SimpleForm, TextInput} from 'react-admin';
import {DefaultToolbar} from '../../helpers/DefaultToolbar';
import {SportAdminPage} from '../../components/customs';

const MatchGroupTitle = (props: any) => {
    return <span>Standing Group {props.record ? `"${props.record.name}"` : `"${props.record.id}"`}</span>;
};

const MatchGroupEdit = (props) => (
    <SportAdminPage>
        <Edit title={<MatchGroupTitle/>} {...props}>
            <SimpleForm toolbar={<DefaultToolbar/>}>
                <TextInput disabled source="id"/>
                <TextInput disabled source="groupName"/>
                <TextInput multiple source="groupNameDE" label='Group name DE'/>
                <TextInput disabled source="name"/>
                <TextInput multiple source="nameDE" label='Name DE'/>
                <TextInput disabled source="sportId"/>
            </SimpleForm>
        </Edit>
    </SportAdminPage>
);

export default MatchGroupEdit;