import React from 'react';
import { Edit, Datagrid, BooleanInput, SimpleForm, TextInput, ImageField } from 'react-admin';
import CustomImageInput from '../fileInput/CustomImageInput'
import { ResourceActions } from '../buttons/ResourceAction';
import { DefaultToolbar } from '../helpers/DefaultToolbar';
import { SportAdminPage } from '../components/customs';

const CompetitionTitle = (props: any) => {
    return <span>Competition {props.record ? props.record.name ? `"${props.record.name}"` : props.record.nameDE ? `"${props.record.nameDE}"` : `"${props.record.id}"` : ''}</span>;
};

const CompetitionEdit = (props) => (
    <SportAdminPage>
        <Edit title={<CompetitionTitle />} {...props} actions={<ResourceActions />}>
            <SimpleForm toolbar={<DefaultToolbar />}>
                <TextInput disabled source="id" />
                <TextInput multiple source="nameDE" label="Name DE"/>
                <BooleanInput source="isActive" />
                <BooleanInput source="hasTicker" />
                <BooleanInput source="hasStandings" />
                <BooleanInput source="isTop" />
                <TextInput source="countryCode" />
                <TextInput source="topSort" />
                <CustomImageInput source="logo" label="Logo" accept="image/png" multiple={false}>
                    <ImageField source="src" />
                </CustomImageInput>
            </SimpleForm>
        </Edit>
    </SportAdminPage>
);

export default CompetitionEdit;