import React, { Fragment } from 'react';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import JustifiedGrid from 'react-justified-grid';
import Gallery from 'react-grid-gallery';


const GridListBox = (props) => {
    const { classes, tracks, cols, imageClick, resource } = props;

    return (
            <div style={{
                display: "block",
                minHeight: "1px",
                width: "100%",
                overflow: "auto"}}
            >
                <Gallery
                    images={tracks}
                    onClickThumbnail={imageClick}
                    lightboxWidth={1536}
                />
            </div>
    );
}

export default GridListBox;