import React from 'react';
import { TextField, TextInput, CardActions, ExportButton, EditButton, Responsive, BooleanField, Filter } from 'react-admin';
import { List, Datagrid } from '../components/customs';
import LinkToRelatedMatches from '../matches/LinkToRelatedMatches';
import ConfigurationButton from '../configuration/button';
import MobileGrid from './MobileGrid';
import {isAdmin, isEditor} from '../permissions';
import { SearchInput } from '../components/customs/Input';
import { PurgeButton } from '../components/buttons';

const SportActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter
}) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }

        <ConfigurationButton />
        <ExportButton
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
        />
    </CardActions>
);

const SportFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <TextInput label="Sport group Id" source="sportGroup.id" />
    </Filter>
);


const SportList = ({permissions, ...props}) => (
    <List {...props} filters={<SportFilter />} actions={<SportActions />} bulkActionButtons={false}>
        <Responsive
            medium={
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="name" label="Name" />
                    <TextField source="nameDE" label="Name DE" />
                    <TextField source="isActive" label="Is Active" />
                    <TextField source="sort" />
                    <TextField source="sportGroup.id" />
                    <LinkToRelatedMatches />
                    {(isAdmin(permissions) || isEditor(permissions)) && <EditButton />}
                </Datagrid>
            }
        />
    </List>
);

export default SportList;