import React, { useState } from 'react';
import {
  Button,
  Typography,
  Paper,
  Avatar,
  TextField,
  CircularProgress,
  Link,
  Box
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { useLocation } from 'react-router-dom';
import { useTranslate, useLogin, useNotify } from 'ra-core';
import { Notification } from 'react-admin';
import theme from '../theme';
import { Field, withTypes } from 'react-final-form';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { css } from 'styled-components';

const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: 'url(https://source.unsplash.com/random/1600x900)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    paper: {
        minWidth: 300,
        marginTop: '6em',
    },
    mainContainer: {
        padding: theme.spacing(4)
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.error.main,
    },
    top: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
    },
    msButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(4)
    },
    msButton: {
        borderRadius: '0px',
        border: '1px solid #A9A9A9'
    },
    msLogo: {
        
    },
    msLoginText: {
        paddingLeft: theme.spacing(3),
        textTransform:'none',
        fontSize: '14px'
    },
    formDividerContainer: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        display: "flex",
        alignItems: "center",
    },
    formDividerWord: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    formDivider: {
        flexGrow: 1,
        height: 1,
        backgroundColor: "#B9B9B940",   
    },
    form: {
        width: 320, // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    actions: {
        padding: '1em 1em 1em 1em',
    },
}))

interface FormValues {
    username?: string;
    password?: string;
}

const { Form } = withTypes<FormValues>();

const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        variant="outlined"
        margin="normal"
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const Login = () => {
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const login = useLogin();
    const location = useLocation<{ nextPathname: string } | null>();

    const handleSubmit = (auth: FormValues) => {
        setLoading(true);
        login(auth, location.state ? location.state.nextPathname : '/').catch(
            (error: Error) => {
                setLoading(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        : error.message,
                    'warning'
                );
            }
        );
    };

    const validate = (values: FormValues) => {
        const errors: FormValues = {};
        if (!values.username) {
            errors.username = translate('ra.validation.required');
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }
        return errors;
    };

    const Copyright = () => {
        return (
          <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.sport1.de/">
              Sport1 GmbH
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        );
    }

    return (
        <div className={classes.main}>
            <Paper className={classes.paper} elevation={3} square>
                <div className={classes.mainContainer}>
                    <div className={classes.top}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                        {translate('pos.auth.title')}
                        </Typography>
                    </div>
                    <div className={classes.msButtonContainer}>
                        <Button
                            className={classes.msButton}
                            variant="outlined"
                            href="/api/users/sso"
                            startIcon={<img height="25px" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4yLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA2NCA2NCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNjQgNjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiNGMDUxMjU7fQ0KCS5zdDF7ZmlsbDojN0ZCQjQyO30NCgkuc3Qye2ZpbGw6I0ZEQjgxMzt9DQoJLnN0M3tmaWxsOiMzMkEwREE7fQ0KPC9zdHlsZT4NCjxyZWN0IGNsYXNzPSJzdDAiIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCIvPg0KPHJlY3QgeD0iMzQiIGNsYXNzPSJzdDEiIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCIvPg0KPHJlY3QgeD0iMzQiIHk9IjM0IiBjbGFzcz0ic3QyIiB3aWR0aD0iMzAiIGhlaWdodD0iMzAiLz4NCjxyZWN0IHk9IjM0IiBjbGFzcz0ic3QzIiB3aWR0aD0iMzAiIGhlaWdodD0iMzAiLz4NCjwvc3ZnPg0K"  />}
                        >
                            <Typography variant="body2" color="textSecondary" className={classes.msLoginText} noWrap>
                                {translate('pos.auth.ms.sign_in')}
                            </Typography>
                        </Button>
                    </div>
                    <div className={classes.formDividerContainer}>
                        <div className={classes.formDivider} />
                            <Typography className={classes.formDividerWord}>{translate('pos.auth.or')}</Typography>
                        <div className={classes.formDivider} />
                    </div>
                    <div>
                        <Form
                            onSubmit={handleSubmit}
                            validate={validate}
                            render={({ handleSubmit }) => (
                                <form onSubmit={handleSubmit} noValidate>
                                    <div >
                                        <div className={classes.form}>
                                            <Field
                                                autoFocus
                                                name="username"
                                                // @ts-ignore
                                                component={renderInput}
                                                label={translate('ra.auth.username')}
                                                autoComplete="email"
                                                disabled={loading}
                                            />
                                            <Field
                                                name="password"
                                                // @ts-ignore
                                                component={renderInput}
                                                label={translate('ra.auth.password')}
                                                type="password"
                                                disabled={loading}
                                                autoComplete="current-password"
                                            />
                                        </div>
                                        <div className={classes.actions}>
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                color="primary"
                                                disabled={loading}
                                                fullWidth
                                            >
                                                {loading && (
                                                    <CircularProgress
                                                        size={25}
                                                        thickness={2}
                                                    />
                                                )}
                                                {translate('ra.auth.sign_in')}
                                            </Button>  
                                        </div>
                                        <Box mt={5}>
                                            <Copyright />
                                        </Box>
                                        <Notification />
                                    </div>
                                </form>
                            )}
                        />
                    </div>
                </div>
            </Paper>
        </div>
    )

}

const LoginWithTheme = (props: any) => (
    <ThemeProvider theme={theme}>
        <Login {...props} />
    </ThemeProvider>
);

export default LoginWithTheme;