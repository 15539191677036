import React from 'react';
import { ReferenceField, TextField } from 'react-admin';

const CompetitionReferenceField = props => (
    <ReferenceField label="competition" source="competition.id" reference="competition" {...props} linkType="show">
        <TextField source="name" />
    </ReferenceField>
);

CompetitionReferenceField.defaultProps = {
    source: 'competition.id',
    addLabel: true,
    label: 'Competition'
};

export default CompetitionReferenceField;