import React, { Component } from 'react'
import { TextField, ReferenceInput, DateInput, Filter } from 'react-admin';
import { List } from '../components/customs';
import MatchGridList from './GridList';
import moment from 'moment';
import { buildRequest, checkResponse } from '../helpers/request';
import isEmpty from 'lodash/isEmpty';
import { SearchInput, SelectInput } from '../components/customs/Input';

export const fetchDefaultSport = () => new Promise((resolve, reject) => {
    const request = buildRequest('config/sport/default', 'GET');

    fetch(request)
        .then(checkResponse)
        .then(json => resolve(json))
        .catch(error => reject(error));
});

export const fetchMatchConfig = (sportType) => new Promise((resolve, reject) => {
    const request = buildRequest(`config/match?sportId=${sportType}`, 'GET');

    fetch(request)
        .then(checkResponse)
        .then(json => resolve(json))
        .catch(error => reject(error));
});

class MatchList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sport: {},
            path: '',
            sort: 'id',
            sortDir: 'DESC'
        }
    }

    componentDidMount() {
        const defaultSportId = localStorage.getItem('defaultSportId');
        if(!defaultSportId) {
            fetchDefaultSport()
            .then(json => {
                localStorage.setItem('defaultSportId', json.sport.id);
                this.setState({
                    sport: json.sport,
                    path: json.commentaryRoute,
                    sort: json.sort,
                    sortDir: json.sortDir
                });
            })
            .catch(error => console.error('Failed to fetch default sport:', error));
        }
        else {
            this.setMatchConfig(defaultSportId);
        }
    }

    MatchFilter = (props) => (
        <Filter {...props}>
            <ReferenceInput
                label="Sports"
                source="sportType"
                reference="sport/active"
                inputProps={{
                    onChange: (e) => {
                        this.setMatchConfig(e.target.value);
                    }
                }}
                alwaysOn
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <DateInput source="gameDate" alwaysOn />
        </Filter>
    );

    setMatchConfig = (sportType) => {
        fetchMatchConfig(sportType)
            .then(json => {
                localStorage.setItem('defaultSportId', json.sport.id);
                this.setState({
                    sport: json.sport,
                    path: json.commentaryRoute,
                    sort: json.sort,
                    sortDir: json.sortDir
                });
            })
            .catch(error => console.error('Failed to fetch match config: ', error));

    }

    render() {
        const props = this.props;
        const { sport, path, sort, sortDir } = this.state;
        return !isEmpty(sport) ? (
            <List {...props} filters={<this.MatchFilter />} sort={{ field: 'competition.isTop', order: 'DESC' }} filterDefaultValues={{ sportType: sport.id, gameDate: moment().format('YYYY-MM-DD') }} bulkActionButtons={false}>
                <MatchGridList sport={sport} path={path} sort={sort} sortDir={sortDir} />
            </List>
        ): null;
    }
}

export default MatchList;