import React from 'react';
import {
    TextField,
    CloneButton
} from 'react-admin';
import { List, Datagrid } from '../../components/customs';
import CommentaryEditButton from '../../commentaries/EditButton'
import { getDefaultRecord, CommentaryFilter, CommentaryMotorSportActions } from '../motorsport/CommentaryList';
import { isAdmin, isMatchEditor } from '../../permissions';
import { BulkActionButtons } from '../../components/buttons';

export const CommentaryList = (props: any) => {
    const { permissions } = props;
    const record = getDefaultRecord(props.location.search);
    return (
        <List {...props} sort={{ field: 'sort', order: 'DESC' }} filters={<CommentaryFilter />} actions={<CommentaryMotorSportActions permissions={permissions} record={record} />}
            bulkActionButtons={(isAdmin(permissions) || isMatchEditor(permissions)) ? <BulkActionButtons /> : false} >
            <Datagrid>
                <TextField source="id" />
                <TextField source="langId" label="Lang ID" />
                <TextField source="sessionId" label="Session ID" />
                <TextField source="sort" />
                <TextField source="minute" />
                <TextField source="message" />
                <TextField source="eventType" label="Event Type" />
                {(isAdmin(permissions) || isMatchEditor(permissions)) && <CloneButton />}
                {(isAdmin(permissions) || isMatchEditor(permissions)) && <CommentaryEditButton />}
            </Datagrid>
        </List>
)}