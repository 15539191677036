import React from 'react';
import { Edit, BooleanInput, TextInput, SimpleForm } from 'react-admin';
import { DefaultToolbar } from '../../../helpers/DefaultToolbar';
import { ResourceActions } from '../../../buttons/ResourceAction';
import { SportAdminPage } from '../../../components/customs';

const MatchResourcePageTitle = (props: any) => {
    const { record } = props;
    return <span>Event {record ? record.name ? `"${record.name}"` : record.nameDE ? `"${record.nameDE}"` : `"${record.id}"` : ''}</span>;
};

const MatchResourcePageEdit = (props) => (
    <SportAdminPage>
        <Edit title={<MatchResourcePageTitle />} {...props} actions={<ResourceActions />}>
            <SimpleForm toolbar={<DefaultToolbar />}>
                <TextInput disabled source="id" />
                <TextInput disabled source="gameDate" />
                <BooleanInput source="isHidden" />
            </SimpleForm>
        </Edit>
    </SportAdminPage>
);

export default MatchResourcePageEdit;