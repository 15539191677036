import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
import MatchGrid from '../matchgrid';

class MatchPage extends Component{

    componentDidMount(){
        if(this.props.match.params.id) {
            localStorage.setItem('sportId', this.props.match.params.id)
        }
    }

    render() {
        return (
            <Card>
                <Title title="Matches" />
                <CardContent>
                    <MatchGrid sportId={this.props.match.params.id}/>
                </CardContent >
            </Card>
        )
    }

}
export default MatchPage;