import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import {
    EditButton,
    translate,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import AvatarNameField from '../AvatarNameField';
import TrueValueField from '../TrueValueField';
import { isAdmin, isEditor } from '../permissions';

const listStyles = theme => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: theme.typography.body1,
    cardContentRow: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        margin: '0.5rem 0',
    },
});


export const MobileGrid = ({permissions, classes, ids, data, basePath, translate}) => (
    <div style={{ margin: '1em' }}>
        {ids.map(id => (
            <Card key={id} className={classes.card}>
                <CardHeader
                    title={
                        <div className={classes.cardTitleContent}>
                            <span>
                                <AvatarNameField record={data[id]} name={data[id].name} />
                            </span>
                            {
                                (isAdmin(permissions) || isEditor(permissions)) &&
                                <EditButton
                                    resource="team"
                                    basePath={basePath}
                                    record={data[id]}
                                />
                            }
                        </div>
                    }
                />
                <CardContent className={classes.cardContent}>
                    <TrueValueField className={classes.cardContentRow} label={translate('resources.team.fields.code')} record={data[id]}  source="code" />

                    <TrueValueField className={classes.cardContentRow} label={translate('resources.team.fields.country')} record={data[id]}  source="country" />

                    <TrueValueField className={classes.cardContentRow} label={translate('resources.team.fields.city')} record={data[id]}  source="city" />


                    <TrueValueField className={classes.cardContentRow} label={translate('resources.team.fields.founded')} record={data[id]}  source="founded" />


                    <TrueValueField className={classes.cardContentRow} label={translate('resources.team.fields.postalCode')} record={data[id]}  source="postalCode" />


                    <TrueValueField className={classes.cardContentRow} label={translate('resources.team.fields.regionName')} record={data[id]}  source="regionName" />


                    <TrueValueField className={classes.cardContentRow} label={translate('resources.team.fields.shortName')} record={data[id]}  source="shortName" />


                    <TrueValueField className={classes.cardContentRow} label={translate('resources.team.fields.street')} record={data[id]}  source="street" />

                    <TrueValueField className={classes.cardContentRow} label={translate('resources.team.fields.webAddress')} record={data[id]}  source="webAddress" />

                    <TrueValueField className={classes.cardContentRow} label={translate('resources.team.fields.nameDE')} record={data[id]}  source="nameDE" />

                    <TrueValueField className={classes.cardContentRow} label={translate('resources.team.fields.shortNameDE')} record={data[id]}  source="shortNameDE" />

                </CardContent>

            </Card>
        ))}
    </div>
);

const enhance = compose(
    withStyles(listStyles),
    translate
);

export default enhance(MobileGrid);