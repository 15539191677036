import React from 'react'
import PropTypes from 'prop-types'
import { View } from '@sport1/universal-components'
import { Link } from 'react-router-dom'
import { stringify } from 'query-string';

class Match extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
    }

    render() {
        const {
            match,
            sportId,
            path,
            sort,
            sortDir
        } = this.props

        if (match == null || !Object.keys(match).length) return null

        localStorage.setItem('sessionId', match.id);

        return (
            <View mb={2} borderX={1} borderY={1} borderColor="border.dark" position="relative" style={{ backgroundColor: '#f5f5f5' }}>
            <Link
              to={{
                pathname: path,
                search: stringify({
                    page: 1,
                    perPage: 10,
                    sort: sort,
                    order: sortDir,
                    filter: JSON.stringify({ matchId: match.id, langId: 'de', sportId: sportId, sessionId: match.id }),
                    sessionId: match.id,
                }),
              }}
              style={{ textDecoration: 'none', backgroundColor: '#ccc' }}
            >
                {this.props.children}
            </Link>
            </View>
        )
    }
}

export default Match

