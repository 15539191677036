import React, { Component } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Select from 'react-select';
import Hcalendar from '../calendar/Hcalendar';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import moment from 'moment';
import { buildRequest, checkResponse } from '../helpers/request';
import Container from './draggable/Container';
import update from 'immutability-helper';
import { translate, changeLocale, Title } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import compose from 'recompose/compose';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend'

const styles = {
    label: { width: '10em', display: 'inline-block' },
};

var sorts = []

class SportOrder extends Component {

    constructor(props) {
        super(props);
        this.moveCard = this.moveCard.bind(this)
        this.state = {
            date: moment().format('YYYY-MM-DD'),
            cards: [],
            options: [],
            selectedOption: null,
            sortOverridePrefix: 'group',
            overrideConfirmDialogOpen: false,
        };
    }

    componentDidMount(day = moment().format('YYYY-MM-DD')) {
        this.setCurrentDate(day)
        this.getSportGroups(day)
    }

    setDate = (day = moment().format('YYYY-MM-DD')) => {
            this.setState({ date: day, selectedOption: null, sortOverridePrefix: 'group' })
            this.setCurrentDate(day)
            this.getSportGroups(day)
    }

    setCurrentDate = date => {
        if (typeof this.props.setDate === 'function') {
            this.props.setDate(date)
        }
    }

    handleChange = (selectedOption) => {
        this.setState({ selectedOption, sortOverridePrefix: 'sport' });
        this.getSports(this.state.date, selectedOption.value);
    }

    getSportGroups = date => {
        const request = buildRequest(`group/sort?when=${date}`, 'GET')

        fetch(request)
            .then(checkResponse)
            .then( (json) => {
                this.setState({cards: json});
                json.forEach(group => {
                    this.setState({
                        options: [...this.state.options, {value: group.id, label: group.name}]
                    })

                    sorts.push(group.sort)
                })
            })
            .catch(error => console.error('Error:', error));

    }

    getSports = (date, groupId) => {
        const request = buildRequest(`sport/group?when=${date}&groupId=${groupId}`, 'GET')

        fetch(request)
            .then(checkResponse)
            .then( (json) => {
                this.setState({cards: json});
                json.forEach(sport => {
                    sorts.push(sport.sort)
                })
            })
            .catch(error => console.error('Error:', error));

    }

    setSports = date => {
        const url = this.state.selectedOption ? `sport/rank?when=${date}&groupId=${this.state.selectedOption.value}` : `group/rank?when=${date}`;
        const request = buildRequest(url, 'PUT', JSON.stringify(this.state.cards))

        fetch(request)
        .then(this.checkResponse);

    }

    moveCard(dragIndex, hoverIndex) {
        const { cards } = this.state;
        const dragCard = cards[dragIndex]

        this.setState(
            update(this.state, {
                cards: {
                    $splice: [[dragIndex, 1], [hoverIndex, 0, dragCard]],
                },
            }),
        )

        // update all sports ranks
        this.state.cards.forEach((card, i) => {
            card.sort = sorts[i]
        })

        const { date } = this.state;

        this.setSports(date);

    }

    handleGroupOverride = () => {
        const request = buildRequest(`group/override?when=${this.state.date}`, 'DELETE');

        fetch(request)
            .then(this.checkResponse)
            .then(this.getSportGroups(this.state.date))
            .catch(error => console.error('Error:', error));
    }

    handleSportOverride = () => {
        const request = buildRequest(`sport/override?when=${this.state.date}&groupId=${this.state.selectedOption.value}`, 'DELETE');

        fetch(request)
            .then(this.checkResponse)
            .then(this.getSports(this.state.date, this.state.selectedOption.value))
            .catch(error => console.error('Error:', error));
    }

    showOverrideConfirmDialog = () => {
        this.setState({overrideConfirmDialogOpen: true})
    }

    closeOverrideConfirmDialog = () => {
        this.setState({overrideConfirmDialogOpen: false})
    }

    confirmOverride = () => {
        this.state.sortOverridePrefix === 'group' ? this.handleGroupOverride() : this.handleSportOverride();
        this.setState({overrideConfirmDialogOpen: false})
    }

    render() {
        const { translate } = this.props;
        const { date, cards, selectedOption } = this.state;
        return (
                <CardContent>
                    <div style={{ width: '60%', margin: 'auto' }}>
                        <Hcalendar setDate={this.setDate} selectedDate={date} />
                        <CardContent>
                            <div style={{ width: '50%', margin: 'auto' }}>
                                <Select
                                    value={selectedOption}
                                    onChange={this.handleChange}
                                    options={this.state.options}
                                    placeholder={translate('pos.configuration.select.placeholder')}
                                />
                            </div>
                            <div style={{ width: '50%', margin: 'auto' }}>
                                <Button variant="contained" onClick={this.showOverrideConfirmDialog} label="Reset Override" >
                                    {translate('pos.configuration.sort.override.button.title')}
                                </Button>
                                <Dialog
                                  open={this.state.overrideConfirmDialogOpen}
                                  onClose={this.handleClose}
                                  disableBackdropClick={true}
                                  aria-labelledby="alert-dialog-title"
                                  aria-describedby="alert-dialog-description"
                                >
                                      <DialogTitle id="alert-dialog-title">{translate('pos.configuration.sort.override.dialog.title')} {this.state.date}</DialogTitle>
                                      <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                          {translate('pos.configuration.sort.override.dialog.message')}
                                        </DialogContentText>
                                      </DialogContent>
                                      <DialogActions>
                                        <Button onClick={this.closeOverrideConfirmDialog} color="primary" autoFocus>
                                          {translate('pos.configuration.sort.override.dialog.no')}
                                        </Button>
                                        <Button onClick={this.confirmOverride} color="primary">
                                          {translate('pos.configuration.sort.override.dialog.yes')}
                                        </Button>
                                      </DialogActions>
                                </Dialog>
                            </div>
                        </CardContent>
                        <div style={{ width: '50%', margin: 'auto' }}>
                            <DndProvider backend={HTML5Backend}>
                                <Container moveCard={this.moveCard} cards={cards} />
                            </DndProvider>
                        </div>
                    </div>
                </CardContent>
    )
    }
}

export default compose(
    translate,
    withStyles(styles)
)(SportOrder);