import React from 'react';
import { Edit, TextField, BooleanInput, EditButton, TextInput, SimpleForm, ImageField } from 'react-admin';
import { DefaultToolbar } from '../helpers/DefaultToolbar';
import { ResourceActions } from '../buttons/ResourceAction';
import isEmpty from 'lodash/isEmpty';
import { SportAdminPage } from '../components/customs';
import CustomImageInput from '../fileInput/CustomImageInput'

const EventTitle = (props: any) => {
    const { record } = props;
    return <span>Event {record ? record.name ? `"${record.name}"` : record.nameDE ? `"${record.nameDE}"` : `"${record.id}"` : ''}</span>;
};

const GenericEventEditFields = props => {
    const { record } = props;
    const fieldsBottom: any = [];
    const fieldsTop: any = [];
    if(!isEmpty(record)) {
        for (const [key, value] of Object.entries(record)) {
            if(typeof value === 'boolean') {
                fieldsBottom.push(<BooleanInput source={key} key={key} />)
            }
            else if(key === 'nameDe') {
                fieldsBottom.push(<TextInput multiple parse={v => isEmpty(v) ? null : v} source="nameDe" label="nameDE" key={key} />)
            }
            else if(key === 'shortName') {
                fieldsBottom.push(<TextInput multiple parse={v => isEmpty(v) ? null : v} source="shortName" label="shortName" key={key} />)
            }
            else if(key === 'shortNameDe') {
                fieldsBottom.push(<TextInput multiple parse={v => isEmpty(v) ? null : v} source="shortNameDe" label="shortNameDE" key={key} />)
            }
            else if(key === 'disciplineDe') {
                fieldsBottom.push(<TextInput multiple parse={v => isEmpty(v) ? null : v} source="disciplineDe" label="disciplineDE" key={key} />)
            }
            else {
                if(typeof value === 'string') {
                    fieldsTop.push(<TextInput disabled source={key} key={key} />)
                }
            }
        }
    }
    return (
        <SimpleForm toolbar={<DefaultToolbar />} {...props}>
            {fieldsTop.concat(fieldsBottom)}
            <CustomImageInput source="logo" label="Logo" accept="image/png" multiple={false}>
                <ImageField source="src" />
            </CustomImageInput>
        </SimpleForm>
    )
}

const EventEdit = (props) => (
    <SportAdminPage>
        <Edit title={<EventTitle />} {...props} actions={<ResourceActions />}>
            <GenericEventEditFields />
        </Edit>
    </SportAdminPage>
);

export default EventEdit;