import { RefereeList } from './RefereeList';
import { RefereeEdit } from './RefereeEdit';
import Icon from '@material-ui/icons/Sports';


export const RefereeIcon = Icon;

export default {
    list: RefereeList,
    edit: RefereeEdit,
}