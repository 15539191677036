const getAPI_URL = () => {
    return process.env.NODE_ENV === 'development' ? 'http://localhost:8254' : window.location.origin;
}

export const API_URL = getAPI_URL();

const getAppURL = () => {
    return process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : API_URL;
}

export const getLogoutUrl = () => {
    return `${localStorage.getItem('authServerUrl')}/realms/${localStorage.getItem('realm')}/protocol/openid-connect/logout?redirect_uri=${getAppURL()}&client_id=${localStorage.getItem('clientId')}`;
}
