// @ts-nocheck
import React, { FC } from 'react';
import { Button } from 'react-admin';
import { useHistory } from "react-router-dom";

const BackButtonComponent: FC = ({ children }) => {
    const history = useHistory();
    return (
        <Button label="ra.action.back" onClick={() => history.goBack()} >
            {children}
        </Button>
    )
}

export default BackButtonComponent;