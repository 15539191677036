import React from 'react';
import { Datagrid } from 'react-admin';
import { colors } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import typography from '../../../theme/typography';
import palette from '../../../theme/palette';

const useStyles = makeStyles(theme => ({
    thead: {
    },
    headerCell: {
    backgroundColor: colors.grey[50],
    color: colors.indigo[500],
      padding: '10px 12px',
      '&:last-child': {
          padding: '10px 12px',
      },
      ...typography.body2,
      borderBottom: `1px solid ${palette.divider}`
    },
}));

const CustomDatagrid = props => {
    const classes = useStyles();
    return <Datagrid classes={classes} {...props} />
}

export default CustomDatagrid;
