import React from 'react'
import moment from 'moment'
import { Text, translate } from '@sport1/universal-components'

export const LiveMinute = ({ minute, ...rest }) => (
    <Text py={1} px={2} bg="highlight.main" color="font.enclosed" {...rest}>
        {minute || 'LIVE'}
    </Text>
)

export const TeamName = ({ children, ...rest }) => <Text {...rest}>{children}</Text>
TeamName.defaultProps = {
    numberOfLines: 1,
    size: 'medium',
    p: 2,
    grow: true,
}

export const MatchScore = ({
    homeScore,
    awayScore,
    homePenaltyScore,
    awayPenaltyScore,
    period,
    status,
    scheduledStartTime,
    ...rest
}) => {
    if (period !== 'PRE_MATCH' && status !== 'NOT_STARTED') {
        return (
            <Text {...rest}>
                {homeScore !== undefined
                    ? parseScore(homeScore) + parseScore(homePenaltyScore)
                    : '-'}
                {' : '}
                {awayScore !== undefined
                    ? parseScore(awayScore) + parseScore(awayPenaltyScore)
                    : '-'}
            </Text>
        )
    }
    return scheduledStartTime ? <Text>{moment(scheduledStartTime).format('HH:mm')}</Text> : null
}
MatchScore.defaultProps = {
    size: 'medium',
    bold: true,
}

const parseScore = score => parseInt(score || 0)

export const CompetitionTitle = ({ match }) => {
    if (match.roundType && !match.groupName) {
        let roundNumber =
            (match.roundType === 'ROUND' || match.roundType === 'QUALIFIER_ROUND') &&
            (match.roundNumber || match.matchDay)
                ? `${match.roundNumber || match.matchDay}. `
                : ''
        return (
            <Text py={2} center>
                {`${match.competition.name}, ${roundNumber}${translate(
                    `gameplan_entries.${match.roundType}`
                )}`}
            </Text>
        )
    }
    if (match.matchDay) {
        return (
            <Text py={2} center>
                {`${match.competition.name}, ${match.matchDay}. ${translate('match_day')}`}
            </Text>
        )
    }
    return <Text py={2} center>{`${match.competition.name}`}</Text>
}
