import React, { useState, FC, ReactElement } from 'react';
import {
    ButtonProps,
    useTranslate
} from 'react-admin';
import PropTypes from 'prop-types';
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import { Button } from '@material-ui/core';
import { Record } from 'ra-core';
import { isEmpty } from 'lodash';
import { buildRequest } from '../../helpers/request';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';


const playerTrArry = ['40x40', '80x80', '100x100', '200x200'];
const logoTrArry = ['17x11', '18x18', '20x20', ' 34x22', '36x36', '40x40', '43x43', '60x60', '80x80', '86x86', '120x120', '200x200'];

interface Props {
    basePath?: string;
    record?: Record;
    icon?: ReactElement;
}

type PurgeButtonProps = Props & ButtonProps & MuiButtonProps;

const useStyles = makeStyles(theme =>({
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
          backgroundColor: green[700],
        },
        width: '78px',
    },
    checkIcon: {
        marginRight: 5,
        fontSize: 20,
    }
}))

const PurgeButton: FC<PurgeButtonProps> = ({
     basePath,
     record,
     label= 'Purge'
}) => {

    const [purgeIsRunning, setPurgeIsRunning] = useState(false);
    const [purgeSuccess, setPurgeSuccess] = useState(false);

    const constructAndPurge = pathArr => {
        setPurgeIsRunning(true);
        const urls: string[] = [];
        pathArr.forEach(el => {
            const path = `${record ? record.logo.imageServiceUrl : ""}${el}/smart/${record ? record.logo.src : ""}`;
            urls.push(path);
        });

        const request = buildRequest(`images/purge?pathUrls=${urls.join()}`, 'POST')
        fetch(request)
        .then(response => response.json())
        .then(json => {
            if(json.status === "ok") {
                setPurgeSuccess(true);
                setPurgeIsRunning(false);
            }
        })
        .catch(error => {
            console.log(error);
            setPurgeIsRunning(false);
        })

    }

    const purgeImages = () => {
        //@ts-ignore
        if(!isEmpty(record) && !isEmpty(record.logo)) {
            switch(basePath) {
                case "/player":
                    constructAndPurge(playerTrArry);
                    break;
                default:
                    constructAndPurge(logoTrArry);
            }
        }
    }

    const classes = useStyles();
    const buttonClassname = clsx({
        [classes.buttonSuccess]: purgeSuccess,
    });
    const translate = useTranslate();

    return (
        <Tooltip title={translate('resources.purge.title')}>
            <Button
                variant="contained"
                color="secondary"
                className={buttonClassname}
                onClick={purgeImages}
                size="small"
            >
                {purgeSuccess ? <CheckIcon className={classes.checkIcon} /> : label }
                {purgeIsRunning && <CircularProgress size={24} className={classes.buttonProgress} />}
            </Button>
        </Tooltip>
    )
}

PurgeButton.propTypes = {
    basePath: PropTypes.string,
    label: PropTypes.string,
    record: PropTypes.any,
};

export default PurgeButton;