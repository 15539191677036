import React from 'react';
import { BooleanField, TextField, EditButton, DateField, Responsive, Filter } from 'react-admin';
import { List, Datagrid } from '../components/customs';
import MobileGrid from './MobileGrid';
import {isAdmin, isEditor} from '../permissions';
import { SearchInput } from '../components/customs/Input';

const SeasonFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

const SeasonList = ({permissions, ...props}) => (
    <List {...props} filters={<SeasonFilter />} bulkActionButtons={false}>
        <Responsive
            medium={
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="name" />
                    <TextField source="nameDE" label="Name DE"/>
                    <TextField source="startDate" />
                    <TextField source="endDate" />
                    <TextField source="year" />
                    <TextField source="competitionId" />
                    <BooleanField source="isCurrent" />
                    <TextField source="sportId" />
                    <TextField source="isHidden" />
                    <DateField source="hiddenAt" showTime />
                    {(isAdmin(permissions) || isEditor(permissions)) && <EditButton />}
                </Datagrid>
            }
        />
    </List>
);

export default SeasonList;