import React, { useState, useEffect, Component } from 'react';
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  Divider,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withRouter } from "react-router";
import { makeStyles } from '@material-ui/core/styles';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { TextField, CreateButton, DateField, crudGetList as crudGetListAction, useTranslate, SaveButton, Toolbar, Responsive, useGetList, withDataProvider } from 'react-admin';
import moment from 'moment';
import Drawer from '@material-ui/core/Drawer';
import TaskAssignCreate from '../../tasks_assign/TaskAssignCreate';
import TaskAssignEdit from '../../tasks_assign/TaskAssignEdit';
import isEmpty from 'lodash/isEmpty';
import "react-perfect-scrollbar/dist/css/styles.css";
import {  Datagrid } from '../../components/customs'
import ContentCreate from '@material-ui/icons/Create';
import StatusTextField from './StatusTextField';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(4)
        }
    },
    content: {
        padding: 0
    },
    inner: {
    },
    drawerPaper: {
        width: 400,
    },
    innerDrawer: {
        marginTop: theme.spacing(-1.3),
        marginLeft: theme.spacing(3),
    },
    innerDrawerEdit: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
    },
    statusContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    status: {
        marginRight: theme.spacing(1)
    },
    actions: {
        justifyContent: 'flex-end'
    },
    editButton: {
        fontSize: 20,
        marginRight: theme.spacing(0.5)
    }
}));

const UpdateData = ({resource, pagination, sort, filter}) => {
    useGetList('task', pagination, sort, filter);
    return null;
}

const TaskAssign = (props) => {
    const {dataProvider, className, ...rest} = props;
    const [recordId, setRecordId] = useState(null);
    const [isTaskCreate, setIsTaskCreate] = useState(false);
    const [isTaskEdit, setIsTaskEdit] = useState(false);
    const [task, setTask] = useState([]);
    const [pagination, setPagination] = useState({page: 1, perPage: 6});
    const [sort, setSort] = useState({field: 'updatedAt', order: 'DESC'});
    const [filter, setFilter] = useState({});

    const history = useHistory();

    useEffect(() => {
        dataProvider('GET_LIST', 'task', {pagination, sort, filter});
        //UpdateData('task', pagination, sort, filter);
    });

    const handleSave = () => {
        dataProvider('GET_LIST', 'task', {pagination, sort, filter});
        //UpdateData('task', pagination, sort, filter);
        setIsTaskCreate(false);
        setIsTaskEdit(false);
    };

    const handleCreateClick = () => {
        setIsTaskCreate(true);
    }

    const handleCreateClose = () => {
        setIsTaskCreate(false);
    }

    const handleEditClick = (id) => {
        setIsTaskEdit(true);
        setRecordId(id);
    }

    const handleEditClose = () => {
        setIsTaskEdit(false);
    }

    const navigateToList = () => {
        history.push('/task');
    }

    const customToolbar = props => (
        <Toolbar {...props} >
            <SaveButton
                label="save"
                redirect={handleSave}
                submitOnEnter={true}
            />
        </Toolbar>
    );

    const EditButton = (props) => {
        const { record } = props;
        return (
            <Responsive
                small={
                    <Button
                        color="primary"
                        size="small"
                        onClick={() => handleEditClick(record.id)}
                    >
                        <ContentCreate className={classes.editButton} />
                    </Button>
                }
                medium={
                    <Button
                        color="primary"
                        size="small"
                        onClick={() => handleEditClick(record.id)}
                    >
                        <ContentCreate className={classes.editButton} />
                        {translate('ra.action.edit')}
                    </Button>
                }
             />

        )
    }

    const translate = useTranslate();
    const classes = useStyles();
    const data = useSelector(state => state.admin.resources['task'].data);
    const ids = useSelector(state => state.admin.resources['task'].list.ids);
    return (
        <Card
          {...rest}
          className={classes.root}
        >
          <CardHeader
            action={
              <Button
                color="primary"
                size="small"
                variant="outlined"
                onClick={handleCreateClick}
              >
                {translate('resources.task.create.title')}
              </Button>
            }
            title="Tasks"
          />
          <Divider />
          <CardContent className={classes.content}>
            <PerfectScrollbar>
              <div className={classes.inner}>
                <Datagrid
                    basePath='/'
                    ids={ids}
                    data={data}
                    resource='task'
                    currentSort= {{}}
                >
                    <TextField source="id"/>
                    <TextField source="description"/>
                    <TextField source="createdBy"/>
                    <DateField source="createdAt" showTime />
                    <TextField source="assignedTo"/>
                    <DateField source="updatedAt" showTime />
                    <StatusTextField source="status" />
                    <EditButton classes={classes} translate={translate} />
                </Datagrid>
              </div>
            </PerfectScrollbar>
          </CardContent>
          <Divider />
          <CardActions className={classes.actions}>
            <Button
              color="primary"
              size="small"
              variant="text"
              onClick={navigateToList}
            >
              {translate('resources.task.viewAll')} <ArrowRightIcon />
            </Button>
          </CardActions>
          <Drawer
              variant="temporary"
              open={isTaskCreate}
              anchor="right"
              onClose={handleCreateClose}
              classes={{
                  paper: classes.drawerPaper
              }}
          >
            <div className={classes.innerDrawer}>
              <TaskAssignCreate
                basePath='/'
                location={history.location}
                resource='task'
                isTaskCreate={isTaskCreate}
                onCancel={handleCreateClose}
                customToolBar={<customToolbar />}
                showResourceActions = {false}
                {...props}
              />
             </div>
          </Drawer>
          <Drawer
              variant="temporary"
              open={isTaskEdit}
              anchor="right"
              onClose={handleEditClose}
              classes={{
                  paper: classes.drawerPaper
              }}
          >
            <div className={classes.innerDrawerEdit}>
              {!isEmpty(recordId) ? (
                  <TaskAssignEdit
                    basePath='/'
                    location={history.location}
                    resource='task'
                    id={recordId}
                    onCancel={handleEditClose}
                    customToolBar={<customToolbar />}
                    showResourceActions = {false}
                  />
              ) : null}
             </div>
          </Drawer>
        </Card>
    )
}

export default withDataProvider(TaskAssign);