import React from 'react';
import { Edit, SimpleForm, TextInput, Responsive, BooleanField, ImageField } from 'react-admin';
import { DefaultToolbar } from '../../helpers/DefaultToolbar';
import { SportAdminPage } from '../../components/customs';

const StandingsGroupTitle = (props: any) => {
    return <span>Standing Group {props.record ? `"${props.record.name}"` : `"${props.record.id}"`}</span>;
};

const StandingsGroupEdit = (props) => (
    <SportAdminPage>
        <Edit title={<StandingsGroupTitle />} {...props}>
            <SimpleForm toolbar={<DefaultToolbar />}>
                 <TextInput disabled source="id" />
                 <TextInput disabled source="parentId" />
                 <TextInput disabled source="groupName" />
                 <TextInput multiple source="groupNameDE" label='Group name DE' />
                 <TextInput disabled source="name" />
                 <TextInput multiple source="nameDE" label='Name DE' />
                 <TextInput disabled source="sportId" />
                 <TextInput disabled source="type" />
                 <TextInput disabled source="phase" />
            </SimpleForm>
        </Edit>
    </SportAdminPage>
);

export default StandingsGroupEdit;