import React from 'react'
//import { Column, Row } from '../../../elements/Basic'
import Tab from './Tab'
import { Column, Row } from '@sport1/universal-components';
import isEmpty from 'lodash/isEmpty';

class TabView extends React.Component {
    constructor(props) {
        super(props)
        this.renderedIndexes = []
        this.state = {
            selectedIndex: props.startIndex || 0,
        }
    }

    onPress = (index, label) => {
        const { onPress } = this.props

        if (onPress) {
            onPress(index, label)
        }

        if (this.state.selectedIndex !== index) {
            this.setState({
                selectedIndex: index,
            })
        }
    }

    render() {
        const { labels, children, bigTabs, renderTab, rowStyle, ...rest } = this.props
        const { selectedIndex } = this.state

        if (!labels || !children) return null

        return (
            <Column flexing width="100%" {...rest}>
                <Row
                    isFlex
                    alignItems="center"
                    justifyContent="center"
                    borderBottom={1}
                    borderColor="border.main"
                    {...rowStyle}
                >
                    {labels.map((item, index) => (
                        <Column flexing={bigTabs} key={index}>
                            {typeof renderTab === 'function' ? (
                                renderTab({
                                    title: item,
                                    isActive: selectedIndex === index,
                                    bigTabs,
                                    onPress: /* istanbul ignore next */ () =>
                                        this.onPress(index, labels[index]),
                                })
                            ) : (
                                <Tab
                                    title={item}
                                    onPress={
                                        /* istanbul ignore next */ () =>
                                            this.onPress(index, labels[index])
                                    }
                                    isActive={selectedIndex === index}
                                    bigTab={bigTabs}
                                />
                            )}
                        </Column>
                    ))}
                </Row>

                {React.Children.map(children.filter(child => {
                        return !isEmpty(child);
                    }), (child, i) => {
                    if (i === selectedIndex && !this.renderedIndexes.includes(i))
                        this.renderedIndexes.push(i)
                    return (
                        <Column display={i === selectedIndex ? 'block' : 'none'}>
                            {this.renderedIndexes.includes(i) ? child : null}
                        </Column>
                    )
                })}

            </Column>
        )
    }
}
export default TabView
