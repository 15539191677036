import React, { Component, forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { colors, Button } from '@material-ui/core';
import clsx from 'clsx';

const styles = theme => ({
    button: {
        color: colors.blueGrey[800],
        padding: '10px 0px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightMedium
    },
    icon: {
        color: theme.palette.icon,
        display: 'flex',
        alignItems: 'center',
        width: 24,
        height: 24,
    },
    standAloneIcon: {
        paddingRight: theme.spacing(2)
    },
      active: {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        '& $icon': {
          color: theme.palette.primary.main
        }
      }
});

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

class MenuItemLink extends Component {

    handleMenuTap = e => {
        this.props.onClick && this.props.onClick(e);
    };

    render() {
        const { classes, route, itemName, icon } = this.props;
        return(
            <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                to={`${route}`}
                key={itemName}
                {...this.props}
                onClick={this.handleMenuTap}
            >
                <div className={clsx({[classes.icon]: true, [classes.standAloneIcon]: true})}>{icon}</div>
                {itemName}
            </Button>
        );
    }
}

export default compose(
    withStyles(styles)
)(MenuItemLink)