import React from 'react';
import { ReferenceField, TextField } from 'react-admin';

const StandingGroupReferenceField = props => (
    <ReferenceField label="Standing Group" source="groupId" reference="standings-group" {...props} linkType="show">
        <TextField source="id" />
    </ReferenceField>
);

StandingGroupReferenceField.defaultProps = {
    source: 'groupId',
    addLabel: true,
    label: 'Standing Group'
};

export default StandingGroupReferenceField;