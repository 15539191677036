import React from 'react';
import { Edit, Datagrid, SimpleForm, TextInput, ImageField, ReferenceInput, AutocompleteInput } from 'react-admin';
import { DefaultToolbar } from '../helpers/DefaultToolbar';
import CustomImageInput from '../fileInput/CustomImageInput';
import { ResourceActions } from '../buttons/ResourceAction';
import { SportAdminPage } from '../components/customs';


const TeamTitle = (props: any) => {
    return <span>Team {props.record ? `"${props.record.name}"` : ''}</span>;
};

const TeamEdit = (props) => (
    <SportAdminPage>
        <Edit title={<TeamTitle />} {...props} actions={<ResourceActions />}>
            <SimpleForm toolbar={<DefaultToolbar />}>
                <TextInput disabled source="id" />
                <TextInput disabled source="name" />
                <TextInput disabled source="code" />
                <TextInput disabled source="country" />
                <TextInput disabled source="city" />
                <TextInput disabled source="founded" />
                <TextInput disabled source="postalCode" />
                <TextInput disabled source="regionName" />
                <TextInput disabled source="shortName" />
                <TextInput disabled source="street" />
                <TextInput disabled source="webAddress" />
                <ReferenceInput label="Country Code" source="countryCode" sort={{ field: 'code', order: 'ASC' }} perPage={253} reference="country/all">
                    <AutocompleteInput optionText='alpha3Code' optionValue="alpha3Code" />
                </ReferenceInput>
                <TextInput source="nameDE" label="name DE"/>
                <TextInput source="shortNameDE" label="short name DE"/>
                <CustomImageInput source="logo" label="Logo" accept="image/png" multiple={false}>
                    <ImageField source="src" />
                </CustomImageInput>
            </SimpleForm>
        </Edit>
    </SportAdminPage>
);

export default TeamEdit;