import React, { Component, useRef, useState, useEffect } from 'react'
import { View, Row, Button, FontelloIcon, Column, translate } from '@sport1/universal-components'
import Dates from './Dates'
import moment from 'moment/moment'

/*class Hcalendar extends Component {
    constructor(props) {
        super(props)
        this.state = { week: 0 }
    }

    nextWeek = () => this.setState(prevState => ({ week: (prevState.week += 7) }))

    prevWeek = () => this.setState(prevState => ({ week: (prevState.week -= 7) }))

    render() {
        return (
            <Column width={['50%', 'auto']} height={90}>
                <Row height={60}>
                    <Button onPress={this.prevWeek} bold customStyle textColor="font.main">
                        <View textAlign="center" rotate={180} mt={3}>
                            <FontelloIcon
                                name="arrow-front"
                                size="custom"
                                fontSize="28px"
                                lineHeight="28px"
                            />
                        </View>
                    </Button>
                    <Dates
                        week={this.state.week}
                        setDate={this.props.setDate}
                        selectedDate={this.props.selectedDate}
                    />
                    <Button onPress={this.nextWeek} bold customStyle textColor="font.main">
                        <View textAlign="center" mt={3}>
                            <FontelloIcon
                                name="arrow-front"
                                size="custom"
                                fontSize="28px"
                                lineHeight="28px"
                            />
                        </View>
                    </Button>
                </Row>
                {this.state.week !== 0 ? (
                    <Row center>
                        <Button
                            title={translate('today')}
                            textColor="highlight.main"
                            borderColor="highlight.main"
                            px={2}
                            mt={2}
                            onPress={() => {
                                this.setState({ week: 0 })
                                this.props.setDate(moment().format('YYYY-MM-DD'))
                            }}
                        />
                    </Row>
                ) : null}
            </Column>
        )
    }
}

export default Hcalendar*/

const Hcalendar = ({ setDate, selectedDate }) => {
    const [week, setWeek] = useState(0);

    const prevWeekRef = useRef<number>();
    useEffect(() => {
        prevWeekRef.current = week;
    })
    let prevWeekVal = prevWeekRef.current || 0;

    const nextWeek = () => setWeek((prevWeekVal += 7));

    const prevWeek = () =>  setWeek((prevWeekVal -= 7));
    return (
        <Column width={['50%', 'auto']} height={90}>
            <Row height={60}>
                <Button onPress={prevWeek} bold customStyle textColor="font.main">
                    <View textAlign="center" rotate={180} mt={3}>
                        <FontelloIcon
                            name="arrow-front"
                            size="custom"
                            fontSize="28px"
                            lineHeight="28px"
                        />
                    </View>
                </Button>
                <Dates
                    week={week}
                    setDate={setDate}
                    selectedDate={selectedDate}
                />
                <Button onPress={nextWeek} bold customStyle textColor="font.main">
                    <View textAlign="center" mt={3}>
                        <FontelloIcon
                            name="arrow-front"
                            size="custom"
                            fontSize="28px"
                            lineHeight="28px"
                        />
                    </View>
                </Button>
            </Row>
            {week !== 0 ? (
                <Row center>
                    <Button
                        title={translate('today')}
                        textColor="highlight.main"
                        borderColor="highlight.main"
                        px={2}
                        mt={2}
                        onPress={() => {
                            setWeek(0)
                            setDate(moment().format('YYYY-MM-DD'))
                        }}
                    />
                </Row>
            ) : null}
        </Column>
    )
}

export default Hcalendar
