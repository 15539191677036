import MatchGroupList from './MatchGroupList';
import MatchGroupEdit from './MatchGroupEdit';
import Icon from '@material-ui/icons/Flag';

export const CountryIcon = Icon;

export default {
    list: MatchGroupList,
    edit: MatchGroupEdit
}

