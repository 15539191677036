import React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom'
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import compose from 'recompose/compose';

const useStyles = makeStyles(theme => ({
    icon: {
        fontSize: 20,
        paddingRight: '0.3em'
    },
}));

export const ConfigurationButton = (props) => {
    const translate = useTranslate();
    const classes = useStyles();
    return (
      <Button
          size="small"
          color='primary'
          component={Link}
          to={{pathname: '/configuration'}}
       >
          <SettingsIcon className={classes.icon} />
          {translate('pos.configuration.title')}
      </Button>
    );

}