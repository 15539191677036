import React from 'react';
import { CardActions } from 'react-admin';
import IconBack from '@material-ui/icons/ArrowBack';
import BackButtonComponent from './BackButtonComponent';

export const BackButton = () => (
    <BackButtonComponent>
       <IconBack />
    </BackButtonComponent>
)

export const ResourceActions = () => (
   <CardActions>
       <BackButton />
   </CardActions>
);