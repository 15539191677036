import React from 'react';
import { Edit, SimpleForm, TextInput, SelectInput, Toolbar, SaveButton } from 'react-admin';
import { ResourceActions } from '../../buttons/ResourceAction';
import { validate } from './CommentaryCreate';

export const Title = (props: any) => {
    return <span>Commentary {props.record ? `"${props.record.id}"` : ''}</span>;
};

export const DefaultToolbar = props => (
    <Toolbar {...props} >
        <SaveButton
            label="save"
            redirect="edit"
            submitOnEnter={true}
        />
    </Toolbar>
);

export const CommentaryEdit = (props: any) => (
    <Edit title={<Title />} {...props} actions={<ResourceActions />} >
        <SimpleForm toolbar={<DefaultToolbar />} validate={validate} >
            <TextInput disabled source="id" />
            <TextInput disabled source="sessionId" />
            <TextInput source="langId" />
            <TextInput source="sort" />
            <TextInput source="lap" />
            <TextInput multiline source="message" />
            <SelectInput source="eventType" choices={[
                { id: 'comment', name: 'comment' },
                { id: 'safety_car', name: 'safety car' },
                { id: 'start', name: 'start' },
                { id: 'finish', name: 'finish' },
                { id: 'crash', name: 'crash' },
                { id: 'time_penalty', name: 'time penalty' },
                { id: 'fastest_lap', name: 'fastest lap' },
                { id: 'pitstop', name: 'pitstop' }
            ]} />
        </SimpleForm>
    </Edit>
);