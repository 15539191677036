import React from 'react';
import { Edit, SimpleForm, TextInput, Responsive, BooleanField, ImageField } from 'react-admin';
import { DefaultToolbar } from '../helpers/DefaultToolbar';
import CustomImageInput from '../fileInput/CustomImageInput'
import { SportAdminPage } from '../components/customs';

const CountryTitle = (props: any) => {
    return <span>Country {props.record ? `"${props.record.en}"` : ''}</span>;
};

const CountryEdit = (props) => (
    <SportAdminPage>
        <Edit title={<CountryTitle />} {...props}>
            <SimpleForm toolbar={<DefaultToolbar />}>
                 <TextInput source="code" label="code" />
                 <TextInput source="ioc" />
                 <TextInput source="en" />
                 <TextInput source="de" />
                 <TextInput source="es" />
                 <TextInput source="fr" />
                 <TextInput source="it" />
                 <TextInput source="ru" />
                 <TextInput source="optaCode" />
                 <CustomImageInput source="logo" label="Logo" accept="image/png" multiple={false}>
                     <ImageField source="src" />
                 </CustomImageInput>
            </SimpleForm>
        </Edit>
    </SportAdminPage>
);

export default CountryEdit;