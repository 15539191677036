// Part of code taken from
// https://github.com/marmelab/react-admin/blob/079f792e7c1b2c8fef7195154112272db6e680c2/packages/ra-ui-materialui/src/button/EditButton.js

import React from 'react';
import PropTypes from 'prop-types';
import shouldUpdate from 'recompose/shouldUpdate';
import ContentCreate from '@material-ui/icons/Create';
import { Link } from 'react-router-dom';

import { Button } from 'react-admin'

const linkToRecord = (basePath, id, langId, linkType = 'edit') => {
    localStorage.setItem('langId', langId);
    const link = `${basePath}/${encodeURIComponent(id)}`;

    if (linkType === 'show') {
        return `${link}/show`;
    }

    return link;
}

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = e => e.stopPropagation();

const CommentaryEditButton = ({
    basePath = '',
    label = 'ra.action.edit',
    record,
    icon = <ContentCreate />,
    ...rest
}) => (
    <Button
        component={Link}
        to={linkToRecord(basePath, record.id, record.langId)}
        label={label}
        onClick={stopPropagation}
        {...rest}
    >
        {icon}
    </Button>
);

CommentaryEditButton.propTypes = {
    basePath: PropTypes.string,
    className: PropTypes.string,
    classes: PropTypes.object,
    label: PropTypes.string,
    record: PropTypes.object,
    icon: PropTypes.element,
};

const enhance = shouldUpdate(
    (props, nextProps) =>
        props.translate !== nextProps.translate ||
        (props.record &&
            nextProps.record &&
            props.record.id !== nextProps.record.id) ||
        props.basePath !== nextProps.basePath ||
        (props.record == null && nextProps.record != null)
);

export default enhance(CommentaryEditButton);