import React from 'react';
import { Edit, SimpleForm, TextInput, SelectInput, Toolbar, SaveButton } from 'react-admin';
import { DefaultToolbar } from '../../commentaries/motorsport/CommentaryEdit';
import { ResourceActions } from '../../buttons/ResourceAction';
import CommentaryEditButton from '../../commentaries/EditButton'
import { validateCommentary, eventTypeChoices } from '../../commentaries/basketball/CommentaryCreate';

export const Title = (props: any) => {
    return <span>Commentary {props.record ? `"${props.record.id}"` : ''}</span>;
};

export const CommentaryEdit = (props) => (
    <Edit title={<Title />} {...props} actions={<ResourceActions />} >
        <SimpleForm toolbar={<DefaultToolbar />} validate={validateCommentary} >
            <TextInput disabled source="id" />
            <TextInput source="langId" />
            <TextInput disabled source="matchId" />
            <TextInput source="set" />
            <TextInput source="sort" />
            <TextInput multiline source="message" />
            <SelectInput source="eventType" choices={eventTypeChoices} />
        </SimpleForm>
    </Edit>
);