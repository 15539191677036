import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput } from 'react-admin';
import { DefaultToolbar } from '../DefaultToolbar';
import { ResourceActions } from '../../buttons/ResourceAction';
import { eventTypeChoices } from '../../commentaries/basketball/CommentaryCreate';

interface Error {
    langId?: string[],
    eventType?: string[]
}

export const validateCommentary = (values) => {
    const errors: Error = {};
    if(!values.langId) {
        errors.langId = ['lang id is required'];
    }
    if(!values.eventType) {
        errors.eventType = ['eventType is required'];
    }
    return errors;
}

export const CommentaryCreate = (props) => (

        <Create {...props} actions={<ResourceActions />} >
            <SimpleForm toolbar={<DefaultToolbar />} validate={validateCommentary} >
                <TextInput source="langId" label="Lang Id" />
                <TextInput disabled source="matchId" />
                <TextInput source="set" />
                <TextInput source="sort" />
                <TextInput multiline source="message" />
                <SelectInput source="eventType" choices={eventTypeChoices} />
            </SimpleForm>
        </Create>
);