import React from 'react';
import { ReferenceField, TextField } from 'react-admin';

const TeamReferenceField = props => (
    <ReferenceField label="Team" source="team.id" reference="team" {...props} linkType="show">
        <TextField source="name" />
    </ReferenceField>
);

TeamReferenceField.defaultProps = {
    source: 'team.id',
    addLabel: true,
    label: 'Team'
};

export default TeamReferenceField;