// theme.js

// breakpoint values
// any array length works with styled-system
const breakpoints = ['52.5em', '64em']

const colors = {
    border: {
        main: '#dddddd',
        accent: '#ff0000',
    },
    background: {
        main: '#fff',
        secondary: '#f5f5f5',
        alternate: '#000',
        halfTransparent: 'rgba(0, 0, 0, 0.5)',
    },
    font: {
        main: '#000',
        secondary: '#b2b2b2',
        alternate: '#fff',
        enclosed: '#fff',
        half: '#808080',
    },
    highlight: {
        main: '#ff0000',
        secondary: '#7da1d0',
    },
    form: {
        WIN: '#38761d',
        DRAW: '#b2b2b2',
        LOST: '#e06666',
    },
    shadows: {
        main: '#024',
    },
    switch: {
        on: '#7da1d0',
        off: '#e5e5e5',
    },
    calendar: {
        text: '#000',
        today: '#ff0000',
    },
    tabHighlight: {
        main: '#000',
    },
    black: '#000',
    white: '#fff',
    standings_qualify: {
        champions_league: '#38761d',
        champions_league_quali: '#93c47d',
        europa_league: '#577dab',
        europa_league_quali: '#7da1d0',
        way_down_relegation: '#f8e832',
        way_down: '#e06666',
    },
    YELLOW_CARD: '#f8e833',
    RED_CARD: '#ff0000',
    transfer: {
        standard: '#000',
        leaving: '#ff0000',
        joining: '#00ff00',
    },
}

// space is used for margin and padding scales
// it's recommended to use powers of two to ensure alignment
// when used in nested elements
// numbers are converted to px
const space = [0, 4, 8, 16, 24, 32, 64, 128, 256, 512]

// typographic scale
const fontSizes = ['10px', '13px', '18px', '40px']

// for any scale, either array or objects will work
const lineHeights = ['40px', '24px', '20px', '20px']
// 8px comes with 16px line lineHeight
// 13px comes with 20px lineHeight
// 18px comes with 24px lineHeight
// 40px comes with 40px lineHeight

const fontWeights = {
    normal: 500,
    bold: 700,
}

const letterSpacings = {
    normal: 'normal',
    caps: '0.25em',
}

// border-radius
const radii = [0, 2, 4, 8]

const borders = [0, '1px solid', '2px solid']

const shadows = [
    `0 1px 2px 0 ${colors.shadows.main}`,
    `0 1px 4px 0 ${colors.shadows.main}`,
    `2px 2px 0 ${colors.border.main}`,
]

const theme = {
    breakpoints,
    colors,
    space,
    fontSizes,
    lineHeights,
    fontWeights,
    letterSpacings,
    radii,
    borders,
    shadows,
}

export default theme
