import React from 'react';
import { TextField, EditButton, Responsive, BooleanField, ImageField, Filter } from 'react-admin';
import { List, Datagrid } from '../components/customs';
import MobileGrid from './MobileGrid';
import {isAdmin, isEditor} from '../permissions';
import { LogoField } from '../helpers/utils';
import { SearchInput } from '../components/customs/Input';
import { PurgeButton } from '../components/buttons';

const CountryFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

const CountryList = ({permissions, ...props}) => (
    <List {...props} filters={<CountryFilter />} sort={{ field: 'code', order: 'DESC' }} bulkActionButtons={false}>
        <Responsive
            medium={
                <Datagrid>
                    <LogoField />
                    <TextField source="code" label="code" />
                    <TextField source="ioc" />
                    <TextField source="en" />
                    <TextField source="de" />
                    <TextField source="es" />
                    <TextField source="fr" />
                    <TextField source="it" />
                    <TextField source="ru" />
                    <TextField source="optaCode" />
                    <BooleanField source="isImageAvailable" />
                    {(isAdmin(permissions) || isEditor(permissions)) && <PurgeButton />}
                    {(isAdmin(permissions) || isEditor(permissions) ) && <EditButton />}
                </Datagrid>
            }
        />
    </List>
);

export default CountryList;