import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { makeStyles, Theme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import { useTranslate } from 'react-admin';
import { buildRequest, checkResponse } from '../../helpers/request';
import { usePrevious } from '../../helpers/utils';
import { isEqual } from 'lodash';

const useStyles = makeStyles(theme => ({
  main: {
    flex: '1',
    marginRight: '1em',
  },
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  }
}));

interface ActiveSportParams {
  length?: number
}

const TickerSports: FC = () => {
  const [activeSports, setActiveSports] = useState<ActiveSportParams>({});
  const classes = useStyles();
  const translate = useTranslate();

  const fetchTickerSportCount = () => {
    const request = buildRequest('sport/active', 'GET');

    fetch(request)
        .then(checkResponse)
        .then( (json) => {
            setActiveSports(json)
        })
        .catch(error => console.error('Error:', error));
  }

  const prevActiveSports = usePrevious(activeSports);
  useEffect(() => {
    if(!isEqual(prevActiveSports, activeSports)) {
      fetchTickerSportCount();
    }
  }, [activeSports, prevActiveSports]);

  return (
    <Card
      className={classes.root}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              { translate('pos.dashboard.tickerSports.title') }
            </Typography>
            <Typography variant="h3">{activeSports && activeSports.length}</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <EventAvailableIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TickerSports;