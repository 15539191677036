import React, { Component } from 'react';
import { DragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import Card from './Card'
import isEmpty from 'lodash/isEmpty'

const style = {
	width: 400,
}

const Container = ({ cards, moveCard }) => (
    <div>
        {!isEmpty(cards) ? (
            <div style={style}>
                {cards.map((sport, i) => (
                    <Card
                        key={sport.id}
                        index={i}
                        id={sport.id}
                        text={sport.name}
                        moveCard={moveCard}
                    />
                ))}
            </div>
        ) : null}
    </div>
);

export default Container;