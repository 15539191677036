import React from 'react';
import { Edit, BooleanInput, SimpleForm, TextInput } from 'react-admin';
import { DefaultToolbar } from '../helpers/DefaultToolbar';
import { SportAdminPage } from '../components/customs';

const SeasonTitle = (props: any) => {
    const { record } = props;
    return <span>Season {record ? `"${record.name}"` : ''}</span>;
};

const SeasonEdit = (props) => (
    <SportAdminPage>
        <Edit title={<SeasonTitle />} {...props}>
            <SimpleForm toolbar={<DefaultToolbar />}>
                <TextInput disabled source="id" />
                <TextInput disabled source="name" />
                <TextInput disabled source="startDate" />
                <TextInput disabled source="endDate" />
                <TextInput multiple source="nameDE" label="Name DE"/>
                <BooleanInput source="isCurrent" />
                <TextInput source="year" />
                <TextInput disabled source="competitionId" />
                <TextInput disabled source="sportId" label="sport Id" />
                <BooleanInput source="isHidden" />
            </SimpleForm>
        </Edit>
    </SportAdminPage>
);

export default SeasonEdit;