import React from 'react';
import { BooleanField, DateField, TextField, EditButton } from 'react-admin';
import { Datagrid } from '../../../components/customs';
import isEmpty from 'lodash/isEmpty';
import {isAdmin, isEditor} from '../../../permissions';

const getGenericFields = record => {
    const fields = [] as any;
    if(!isEmpty(record)) {
        const entries = Object.entries(record);
        for (const [key, value] of entries) {
            if(typeof value === 'boolean') {
                fields.push(<BooleanField source={key} key={key} />)
            }
            else if(key === 'gameDate') {
                fields.push(<DateField source={key} key={key} showTime />)
            }
            else if(key == 'hiddenAt') {
                fields.push(<DateField source={key} key={key} showTime />)
            }
            else if(typeof value === 'string' || typeof value === 'number') {
                fields.push(<TextField source={key} key={key} />)
            }
            else if(key === 'homeTeam') {
                fields.push(<TextField label='Home team' source="homeTeam.name" key={key} />)
            }
            else if(key === 'awayTeam') {
                fields.push(<TextField label='Away team' source="awayTeam.name" key={key} />)
            }
        }
    }

    return fields;
}

const GenericMatchDatagrid = (props) => {
    const { permissions, data, ids } = props;
    return(
        <Datagrid {...props}>
            {getGenericFields(data[ids[0]])}
            {(isAdmin(permissions) || isEditor(permissions) ) && <EditButton />}
        </Datagrid>
    )
}

export default GenericMatchDatagrid;