import PlayerList from './PlayerList';
import PlayerEdit from './PlayerEdit';
import Icon from '@material-ui/icons/AccountBox';

export const PlayerIcon = Icon;

export default {
    list: PlayerList,
    edit: PlayerEdit
}
