import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import compose from 'recompose/compose';
import withStyles from '@material-ui/core/styles/withStyles';
import InputLabel from '@material-ui/core/InputLabel';

const styles = {
  formControl: {
    margin: 1,
    minWidth: 250,
    maxWidth: 10,
  },
  noLabel: {
    margin: 1,
  },
};

const RedactionResetDropdown = (data) => {
    const {classes, items, selectedItem, handleChange, title} = data;
    return (
        <FormControl className={classes.formControl} >
            <InputLabel htmlFor="select">{title}</InputLabel>
            <Select
                value={selectedItem}
                onChange={handleChange}
            >
              {items.map(item => (
                <MenuItem key={item} value={item} >
                  {item}
                </MenuItem>
              ))}
            </Select>
        </FormControl>
    )
};

export default compose(
    withStyles(styles)
)(RedactionResetDropdown);


