import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import groupBy from 'lodash/groupBy'
import values from 'lodash/values'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import {
    View,
    Text,
    Column,
    withDataFetch,
    translate,
    CompetitionTitle,
} from '@sport1/universal-components'
import Match from './pages'
import { ThemeProvider } from 'styled-components'
import theme from '../theme/big'
import TeamMatch from './TeamMatch'
import SessionMatch from './SessionMatch'

const getGroupedCompetitions = matches => values(groupBy(matches, match => match.competition.countryCode))

class MatchScores extends Component {
    static propTypes = {
        sportId: PropTypes.string.isRequired,
    }
    
    renderMatches = (matches, path) => {
        if(matches[0][0].renderMotorSport) {
            return this.renderMotorsportEvents(matches, path)
        }
        else {
            return this.renderGenericMatches(matches, path)
        }
    }
        
    renderGenericMatches = (matches, path) => (
        <React.Fragment>
            {matches.map(competition => (
            <Column
                key={`competition_title_${competition[0].competition.name}_${
                    competition[0].competition.id
                }`}
            >
                <CompetitionTitle
                    key={`competition_title_${competition[0].id}`}
                    match={competition[0]}
                />
                {competition.map(match => (

                    <Match
                        match={match}
                        key={match.id}
                        sportId={this.props.sportId}
                        path={path}
                    >
                        <TeamMatch {...match} />
                    </Match>

            ))}
            </Column>
            ))}
        </React.Fragment>
    )

    renderMotorsportEvents = (events, path) => (
        <React.Fragment>
            {events.map(group => (
            <Column
                key={`competition_title_${group[0].competition.name}_${
                    group[0].competition.id
                }`}
            >
                <CompetitionTitle
                    key={`competition_title_${group[0].id}`}
                    match={group[0]}
                />
                {group.map(event => (
                    <Match
                        key={event.id}
                        match={{ id: event.id, name: event.roundType }}
                        sportId={this.props.sportId}
                        path={path}
                    >
                        <SessionMatch competitionName={event.competition.name} sessionName={event.roundType}/>
                    </Match>
            ))}
            </Column>
            ))}
        </React.Fragment>
    )

    render() {
        const matchesJs = this.props.matches
        const topMatches = getGroupedCompetitions(matchesJs.top)
        const standardMatches = getGroupedCompetitions(matchesJs.standard)
        const path = matchesJs.route;
        return (
            <View width="100%" mt={1}>
                {!isEmpty(topMatches) ? (
                    <React.Fragment>
                        <ThemeProvider theme={theme}>
                        <div style={{ textAlign: 'center' }}>
                            <Text bold center>
                                {translate('top_competitions')}
                            </Text>
                        </div>
                        </ThemeProvider>
                        {this.renderMatches(topMatches, path)}
                    </React.Fragment>
                ) : null}
                {!isEmpty(standardMatches) ? (
                    <React.Fragment>
                        <ThemeProvider theme={theme}>
                            <div style={{ textAlign: 'center' }}>
                                <Text bold center>
                                    {translate('all_competitions')}
                                </Text>
                            </div>
                        </ThemeProvider>
                        {this.renderMatches(standardMatches, path)}
                    </React.Fragment>
                ) : null}

            </View>
        )
    }
}
export default withDataFetch(withRouter(MatchScores))
