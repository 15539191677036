import React from 'react';
import { Edit, TabbedForm, FormTab, TextInput, SelectInput, AutocompleteInput, Toolbar, SaveButton } from 'react-admin';
import { typeChoices } from './CommentaryList';
import { ResourceActions } from '../../buttons/ResourceAction';

export const DefaultToolbar = props => (
    <Toolbar {...props} >
        <SaveButton
            label="save"
            redirect="edit"
            submitOnEnter={true}
        />
    </Toolbar>
);

const CommentaryTitle = (props: any) => {
    return <span>Commentary {props.record ? `"${props.record.id}"` : ''}</span>;
};

export const CommentaryEdit = (props) => (
    <Edit title={<CommentaryTitle />} {...props} actions={<ResourceActions />} >
        <TabbedForm toolbar={<DefaultToolbar />}>
            <FormTab label="Main Content">
                <TextInput disabled source="id" />
                <TextInput multiline source="message" />
                <AutocompleteInput source="eventType" choices={typeChoices} />
                <TextInput source="second" />
                <TextInput source="matchMinute" />
                <TextInput source="homeScore" />
                <TextInput source="awayScore" />
                <SelectInput source="period" choices={[
                    { id: 0, name: 'PRE_MATCH' },
                    { id: 1, name: 'FIRST_HALF' },
                    { id: 2, name: 'FIRST_HALF_STOP' },
                    { id: 3, name: 'SECOND_HALF' },
                    { id: 4, name: 'SECOND_HALF_STOP' },
                    { id: 5, name: 'FIRST_EXTRA_PERIOD' },
                    { id: 6, name: 'FIRST_EXTRA_PERIOD_STOP' },
                    { id: 7, name: 'SECOND_EXTRA_PERIOD' },
                    { id: 8, name: 'SECOND_EXTRA_PERIOD_STOP' },
                    { id: 9, name: 'PENALTY_SHOOTOUT' },
                    { id: 10, name: 'END_OF_MATCH' }
                ]} />
            </FormTab>
            <FormTab label="Additional Content (Json)">
                <TextInput source="additionalContent.title" label="title"/>
                <TextInput source="additionalContent.description" label="description" />
                <TextInput source="additionalContent.link" label="link" />
                <TextInput source="additionalContent.imageUrl" label="image url" />
                <TextInput source="additionalContent.ozID" label="ozID" />
            </FormTab>
        </TabbedForm>
    </Edit>
);