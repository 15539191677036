import React, { FC, useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  LinearProgress
} from '@material-ui/core';
import InsertChartIcon from '@material-ui/icons/InsertChartOutlined';
import { buildRequest, checkResponse } from '../../helpers/request';

const useStyles = makeStyles(theme => ({
  main: {
      flex: '1',
  },
  root: {
    height: '100%',
    minHeight: 135
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  progress: {
    marginTop: theme.spacing(3)
  }
}));

const TaskProgress: FC = () => {
  const [totalDone, setTotalDone] = useState(0.0);
  
  const fetchTotalDone = () => {
    const request = buildRequest('task/percent-done', 'GET');

    fetch(request)
        .then(checkResponse)
        .then( (json) => {
            setTotalDone(json.percentDone);
        })
        .catch(error => console.error('Error:', error));
  }

  useEffect(() => {
    fetchTotalDone();
  })

  const classes = useStyles();
  return (
    <Card
      className={classes.root}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              TASKS PROGRESS
            </Typography>
            { totalDone && <Typography variant="h3">{totalDone}%</Typography> }
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <InsertChartIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        <LinearProgress
          className={classes.progress}
          value={totalDone}
          variant="determinate"
        />
      </CardContent>
    </Card>
  )
}

export default TaskProgress;
