import React from 'react';
import { Edit, SimpleForm, TextInput, ImageField, Responsive } from 'react-admin';
import { ResourceActions } from '../buttons/ResourceAction';
import { DefaultToolbar } from '../helpers/DefaultToolbar';
import CustomImageInput from '../fileInput/CustomImageInput';
import { SportAdminPage } from '../components/customs';

const RefereeTitle = (props: any) => {
    const { record } = props;
    return <span>Referee {record ? `"${record.lastName} ${record.firstName}"` : ''}</span>;
};

export const RefereeEdit = (props) => (
    <SportAdminPage>
        <Edit title={<RefereeTitle />} {...props} actions={<ResourceActions />}>
            <SimpleForm toolbar={<DefaultToolbar />}>
                <TextInput disabled source="id" />
                <TextInput disabled source="firstName" />
                <TextInput disabled source="lastName" />
                <TextInput disabled source="birthDate" />
                <TextInput disabled source="nickName" />
                <TextInput disabled source="birthPlace" />
                <TextInput disabled source="country" />

                <CustomImageInput source="logo" label="Logo" accept="image/jpeg" multiple={false}>
                    <ImageField source="src" />
                </CustomImageInput>
            </SimpleForm>
        </Edit>
    </SportAdminPage>
);