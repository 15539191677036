import { API_URL } from './consts';
import isEmpty from 'lodash/isEmpty';
import { checkLogoutRedirect } from '../authProvider';

const getRequest = (urlPostfix, method, body=null, token) => {
    var serverPrefix = process.env.NODE_ENV === 'development' ? API_URL : ''
    var headers = new Headers({ Accept: 'application/json' });
    const finalToken = isEmpty(token) ? localStorage.getItem('token') : token;
    headers.set('Content-Type', 'application/json');
    headers.set('Authorization', `Bearer ${finalToken}`);

    const request = new Request(`${serverPrefix}/api/${urlPostfix}`, {
       method: method,
       headers: headers,
       body: body,
    });

    return request;
}

export const buildRequest = (urlPostfix, method, body=null) => {

    return getRequest(urlPostfix, method, body, null);
}

export const buildRequestWithToken = (urlPostfix, method, token, body) => {
    return getRequest(urlPostfix, method, body, token);
}

export const buildMultipartRequest = (urlPostfix, method, body) => {
    var serverPrefix = process.env.NODE_ENV === 'development' ? API_URL : ''
    var headers = new Headers({ Accept: 'application/json' });
    headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);

    const request = new Request(`${serverPrefix}/api/${urlPostfix}`, {
       method: method,
       headers: headers,
       body: body,
    });

    return request;
}


export const checkResponse = response => {
    if(response.status === 401 || response.status === 403){
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        checkLogoutRedirect();
    }
    if(response.status !== 200) {
        if(response.status === 400) {
            return Promise.reject(new Error('Bad request'));
        }
        return Promise.reject(new Error('Failed to fetch'));
    }

    return response.json();
}

export const checkResponseRaw = response => {
    if(response.status === 401 || response.status === 403){
            localStorage.removeItem('token');
            localStorage.removeItem('role');
            checkLogoutRedirect();
    }
    if(response.status !== 200) {
        if(response.status === 400) {
            return Promise.reject(new Error('Bad request'));
        }
        return Promise.reject(new Error('Failed to fetch'));
    }
    return response;
}

export const saveTempFile = file => new Promise((resolve, reject) => {
        let form = new FormData();
        form.set('file', file);
        const request = buildMultipartRequest(`images/tmp`, 'POST', form);

        fetch(request)
            .then(checkResponse)
            .then(json => resolve(json.location))
            .catch(error => reject(error));
});

export const removeTempFile = file => new Promise((resolve, reject) => {
        let form = new FormData();
        form.set('file', file);
        const request = buildMultipartRequest(`images/tmp`, 'DELETE', form);

        fetch(request)
            .then(checkResponseRaw)
            .then(json => resolve(json))
            .catch(error => reject(error));
});





