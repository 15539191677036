import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import {
    EditButton,
    translate,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import isEmpty from 'lodash/isEmpty';

import TrueValueField from '../TrueValueField';
import NameField from '../NameField';
import {isAdmin, isEditor} from '../permissions';

const listStyles = theme => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: theme.typography.body1,
    cardContentRow: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        margin: '0.5rem 0',
    },
});

export const MobileGrid = ({permissions, classes, ids, data, basePath, translate}) => (
    <div style={{ margin: '1em' }}>
        {ids.map(id => (
            <Card key={id} className={classes.card}>
                <CardHeader
                    title={
                        <div className={classes.cardTitleContent}>
                            <span>
                                <NameField record={data[id]} name={ !isEmpty(data[id].de) ? data[id].de : data[id].en } />
                            </span>
                            {
                                (isAdmin(permissions) || isEditor(permissions)) &&
                                <EditButton
                                    resource="country"
                                    basePath={basePath}
                                    record={data[id]}
                                />
                            }
                        </div>
                    }
                />
                <CardContent className={classes.cardContent}>
                    <TrueValueField className={classes.cardContentRow} label={translate('resources.country.fields.code')} record={data[id]}  source="code" />

                    <TrueValueField className={classes.cardContentRow} label={translate('resources.country.fields.ioc')} record={data[id]}  source="ioc" />

                    <TrueValueField className={classes.cardContentRow} label={translate('resources.country.fields.en')} record={data[id]}  source="en" />

                    <TrueValueField className={classes.cardContentRow} label={translate('resources.country.fields.de')} record={data[id]}  source="de" />

                    <TrueValueField className={classes.cardContentRow} label={translate('resources.country.fields.es')} record={data[id]}  source="es" />

                    <TrueValueField className={classes.cardContentRow} label={translate('resources.country.fields.fr')} record={data[id]}  source="fr" />

                    <TrueValueField className={classes.cardContentRow} label={translate('resources.country.fields.it')} record={data[id]}  source="it" />

                    <TrueValueField className={classes.cardContentRow} label={translate('resources.country.fields.ru')} record={data[id]}  source="ru" />

                    <TrueValueField className={classes.cardContentRow} label={translate('resources.country.fields.optaCode')} record={data[id]}  source="optaCode" />

                </CardContent>

            </Card>
        ))}
    </div>
);

const enhance = compose(
    withStyles(listStyles),
    translate
);

export default enhance(MobileGrid);