import StandingsGroupList from './StandingsGroupList';
import StandingsGroupEdit from './StandingsGroupEdit';
import Icon from '@material-ui/icons/Flag';

export const CountryIcon = Icon;

export default {
    list: StandingsGroupList,
    edit: StandingsGroupEdit
}

