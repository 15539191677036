import React from 'react';
import { SimpleForm, TextInput, Create, ImageField } from 'react-admin';
import { ResourceActions } from '../buttons/ResourceAction';
import { DefaultToolbar } from '../helpers/DefaultToolbar';
import CustomImageInput from '../fileInput/CustomImageInput';
import { SportAdminPage } from '../components/customs';


const SportGroupCreate = (props) => (
    <SportAdminPage>
        <Create {...props} actions={<ResourceActions />}>
            <SimpleForm toolbar={<DefaultToolbar />}>
                <TextInput source="id" />
                <TextInput source="name" />
                <TextInput source="nameDE" />
                <TextInput source="sort" />
                <CustomImageInput source="logo" label="Icon" accept="image/png" multiple={false}>
                    <ImageField source="src" />
                </CustomImageInput>
            </SimpleForm>
        </Create>
    </SportAdminPage>
);

export default SportGroupCreate