import SportGroupList from './SportGroupList';
import SportGroupEdit from './SportGroupEdit';
import SportGroupCreate from './SportGroupCreate';
import Icon from '@material-ui/icons/Class';

export const GroupIcon = Icon;

export default {
    list: SportGroupList,
    edit: SportGroupEdit,
    create: SportGroupCreate
}