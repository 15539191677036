import React from 'react';
import { ReferenceField, TextField } from 'react-admin';

const SportReferenceField = props => (
    <ReferenceField label="Sport" source="sport.id" reference="sport" {...props} linkType="show">
        <TextField source="name" />
    </ReferenceField>
);

SportReferenceField.defaultProps = {
    source: 'sport.id',
    addLabel: true,
    label: 'Sport'
};

export default SportReferenceField;