import TeamStandingsList from './TeamStandingsList';
//import TeamStandingsEdit from './TeamStandingsEdit';
import Icon from '@material-ui/icons/Flag';

export const CountryIcon = Icon;

export default {
    list: TeamStandingsList,
    //edit: StandingsGroupEdit
}

