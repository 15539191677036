import React, {Fragment, useEffect, useState} from 'react';
import isEmpty from 'lodash/isEmpty'
import {ReferenceInput, Filter, usePermissions, BulkDeleteButton} from 'react-admin'
import { List } from '../../../components/customs';
import { SearchInput, SelectInput } from '../../../components/customs/Input';
import GenericMatchDatagrid from './GenericMatchDatagrid';
import { fetchDefaultSport } from '../../MatchList';
import {isAdmin} from "../../../permissions";

const MatchResourcePage = (props) => {
    const [sportId, setSportId] = useState(null);
    useEffect(() => {
        const defaultSportId = localStorage.getItem('defaultSportId');
        if(isEmpty(defaultSportId)) {
            fetchDefaultSport()
                .then(json => {
                    localStorage.setItem('defaultSportId', json.sport.id);
                    setSportId(json.sport.id);
                })
                .catch(error => console.error('Failed to fetch default sport:', error));
        }
        else{
            //@ts-ignore
            setSportId(defaultSportId);
        }
    }, [sportId]);

    const MatchFilter = (props) => (
        <Filter {...props}>
            <ReferenceInput
                label="Sports"
                source="sportId"
                reference="sport/match"
                inputProps={{
                    onChange: (e) => {
                        setDefaultSportId(e.target.value);
                    }
                }}
                alwaysOn
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <SearchInput source="q" alwaysOn />
        </Filter>
    );

    const setDefaultSportId = sportId => {
        localStorage.setItem('defaultSportId', sportId);
    }

    const PostBulkActionButtons = props => (
        <Fragment>
            {/* default bulk delete action */}
            <BulkDeleteButton {...props} />
        </Fragment>
    );

    const { permissions } = usePermissions();


    return sportId ? (
        <List {...props} filters={<MatchFilter />} filterDefaultValues={{ sportId: sportId }} bulkActionButtons={isAdmin(permissions) && <PostBulkActionButtons />} >
            <GenericMatchDatagrid permissions={permissions} />
        </List>
    ): null
}



export default MatchResourcePage;