import SeasonList from './SeasonList';
import SeasonEdit from './SeasonEdit';
import Icon from '@material-ui/icons/DateRange';

export const SeasonIcon = Icon;

export default {
    list: SeasonList,
    edit: SeasonEdit
}




