import React from 'react';
import { TextField, EditButton, CardActions, ExportButton, CreateButton, Responsive, BooleanField, Filter } from 'react-admin';
import { List, Datagrid } from '../components/customs';
import {isAdmin, isEditor} from '../permissions';
import ConfigurationButton from '../configuration/button';
import Avatar from '@material-ui/core/Avatar';
import { SearchInput } from '../components/customs/Input';
import { PurgeButton } from '../components/buttons';

const GroupActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    permissions
}) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }

        {isAdmin(permissions) ? <CreateButton basePath={basePath} /> : null}

        <ConfigurationButton />
        <ExportButton
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
        />
    </CardActions>
);

const GroupFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

const BlackIconField = ({ record, size }) => (
    <Avatar
        src={record && record.logo ? `${record.logo.src}?size=${size}x${size}` : null}
        size={size}
        style={{ width: size, height: size }}
    />
);

const SportGroupList = ({permissions, ...props}) => (
    <List {...props} filters={<GroupFilter />} actions={<GroupActions permissions={permissions} />} bulkActionButtons={false}>
        <Responsive
            medium={
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="name" />
                    <TextField source="nameDE" label="Name DE"/>
                    <BlackIconField label="Icon" size={30}/>
                    <TextField source="sort" />
                    <TextField source="iconName" label="Icon Name" />
                    <BooleanField source="isImageAvailable" />
                    {(isAdmin(permissions) || isEditor(permissions)) && <PurgeButton />}
                    {(isAdmin(permissions) || isEditor(permissions)) && <EditButton />}
                </Datagrid>
            }
        />
    </List>
);

export default SportGroupList;