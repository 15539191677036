import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { CompetitionIcon } from '../competitions';
import { TeamIcon } from '../teams';
import { PlayerIcon } from '../players';
import { CountryIcon } from '../countries';
import { SeasonIcon } from '../seasons';
import { OrderIcon, SportIcon } from '../sports';
import { GroupIcon } from '../sport_groups';
import { MatchIcon } from '../matches';
import { CommentaryIcon } from '../commentaries';
import { EventIcon } from '../events';
import { SessionIcon } from '../sessions';
import SubMenu from './SubMenu';
import { IconReset } from '../redaction';
import { colors, Button, Typography } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ProductIcon from '@material-ui/icons/Collections';
import EventsIcon from '@material-ui/icons/Event';
import { OfficialIcon } from '../officials';
import { RefereeIcon } from '../referee';
import { isAdmin, isView, isEditor, isMatchEditor } from '../permissions';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from '@material-ui/icons/Settings';
import Hidden from '@material-ui/core/Hidden';
import InputIcon from '@material-ui/icons/Input';
import { MenuItemLink } from '../components/customs/Menu';
import { useSelector } from 'react-redux';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import People from '@material-ui/icons/People';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';

const itemsTicker = [
    { name: 'ticker', route: 'match', icon: <CommentaryIcon />}
];

const itemsCatalog = [
    { name: 'team', icon: <TeamIcon /> },
    { name: 'player', icon: <PlayerIcon /> },
];

const itemsSetup = [
    { name: 'competition', icon: <CompetitionIcon /> },
    { name: 'event', icon: <EventsIcon /> },
    { name: 'session', icon: <SessionIcon /> },
    { name: 'standings-group', icon: <GroupWorkIcon /> },
    { name: 'match-group', icon: <GroupWorkIcon /> },
    { name: 'team-standings', icon: <People /> },
    { name: 'soccer-team-standing', icon:  <SportsSoccerIcon/> },
    { name: 'country', icon: <CountryIcon /> },
    { name: 'season', icon: <SeasonIcon /> },
    { name: 'sport', icon: <SportIcon /> },
    { name: 'group', icon: <GroupIcon /> },
    { name: 'officials', icon: <OfficialIcon /> },
    { name: 'referee', icon: <RefereeIcon /> },
];

const useStyles = makeStyles(theme => ({
    menuItemIcon: {
        color: colors.blueGrey[800],
        fontWeight: theme.typography.fontWeightMedium,
    },
    standAloneIcon: {
        marginRight: theme.spacing(2)
    },
    menuIcon: {

    },
    icon: {
        color: theme.palette.icon,
        display: 'flex',
        alignItems: 'center',
        width: 24,
        height: 24,
    },
    listItemIcon: {
    },
    button: {
        color: colors.blueGrey[800],
        padding: '10px 0px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightMedium
    },
    active: {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        '& $icon': {
          color: theme.palette.primary.main
        }
    },
    title: {
        fontWeight: theme.typography.fontWeightMedium
    }
}));

const Menu = ({ onMenuClick, logout, userLogout }) => {
    const [state, setState] = useState({
        menuTicker: false,
        menuCatalog: false,
        menuSetup: false,
    });
    const translate = useTranslate();
    const open = useSelector((state) => state.admin.ui.sidebarOpen);
    useSelector((state) => state.theme); // force rerender on theme change

    const handleToggle = (menu) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    let roles = localStorage.getItem('role');
    if(roles === null) {
        roles = "";
    }
    const classes = useStyles();

    return (
        <div>
            { isView(roles) || isAdmin(roles) || isEditor(roles) || isMatchEditor(roles) ?
                <div>
                    <MenuItemLink exact route={'/'} itemName={'Dashboard'} icon={<DashboardIcon />} onClick={onMenuClick} />
                    <SubMenu
                        handleToggle={() => handleToggle('menuTicker')}
                        isOpen={state.menuTicker}
                        sidebarIsOpen={open}
                        name="pos.menu.ticker"
                        icon={<AssignmentIcon  />}
                        classes
                    >
                        {itemsTicker.map(item => (
                            <MenuItemLink route={item.route ? `/${item.route}` : `/${item.name}`} itemName={translate(`resources.${item.name}.name`, {smart_count:2})} icon={item.icon} onClick={onMenuClick} />
                        ))}
                        {(isAdmin(roles) || isEditor(roles)) && <MenuItemLink route={'/sport-order'} itemName={translate('resources.sportOrder.name', {smart_count:2})} icon={<OrderIcon />} onClick={onMenuClick} />}

                    </SubMenu>
                    <SubMenu
                        handleToggle={() => handleToggle('menuCatalog')}
                        isOpen={state.menuCatalog}
                        sidebarIsOpen={open}
                        name="pos.menu.catalog"
                        icon={<ProductIcon  />}
                    >
                        {itemsCatalog.map(item => (
                            <MenuItemLink route={`/${item.name}`} itemName={translate(`resources.${item.name}.name`, {smart_count:2})} icon={item.icon} onClick={onMenuClick} />
                        ))}
                    </SubMenu>
                    <SubMenu
                        handleToggle={() => handleToggle('menuSetup')}
                        isOpen={state.menuSetup}
                        sidebarIsOpen={open}
                        name="pos.menu.setup"
                        icon={<EventIcon   />}
                    >
                        {itemsSetup.map(item => (
                            <MenuItemLink route={`/${item.name}`} itemName={translate(`resources.${item.name}.name`, {smart_count:2})} icon={item.icon} onClick={onMenuClick} />
                        ))}
                        {<MenuItemLink route={`/match-sports`} itemName={translate('resources.match.name', {smart_count:2})} icon={<MatchIcon />} onClick={onMenuClick} />}
                        {(isAdmin(roles) || isEditor(roles)) && <MenuItemLink route={`/redaction-reset`} itemName={translate('resources.redaction.name', {smart_count:2})} icon={<IconReset />} onClick={onMenuClick} />}
                    </SubMenu>
                    <MenuItemLink route={'/configuration'} itemName={translate('pos.configuration.title')} icon={<SettingsIcon />} onClick={onMenuClick} />
                    <Hidden mdUp>
                      <Button
                          //className={classes.button}
                          onClick={userLogout}
                      >
                          <div ><InputIcon /></div>
                          {translate('ra.auth.logout')}
                      </Button>
                    </Hidden>
                </div> : null }
        </div>
    )
}

export default Menu;