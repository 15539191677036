import React from 'react';
import {
    TextField,
    CloneButton,
    CardActions,
    ExportButton,
    Filter,
    TextInput
} from 'react-admin';
import { List, Datagrid } from '../../components/customs';
import CommentaryEditButton from '../../commentaries/EditButton'
import CommentaryCreateButton from '../../commentaries/CreateButton'
import isEmpty from 'lodash/isEmpty'
import { BackButton } from '../../buttons/ResourceAction';
import { isAdmin, isMatchEditor } from '../../permissions';
import { SearchInput } from '../../components/customs/Input';
import { BulkActionButtons } from '../../components/buttons';


import Icon from '@material-ui/icons/Comment';
export const CommentaryIcon = Icon;

export const getUrlVars = (val) => {
    const vars = {};
    val.replace(/[?&]+([^=&]+)=([^&]*)/gi,
    function(m,key,value) {
      vars[key] = value;
    })

    return vars;
}

export const getDefaultRecord = (search) => {
    const data = getUrlVars(decodeURIComponent(search));
    if(!isEmpty(data) && !isEmpty(data.sessionId)) {
        localStorage.setItem('sessionId', data.sessionId);
    }

    const filter = !isEmpty(data) ? JSON.parse(data.filter) : {};

    return !isEmpty(data) && !isEmpty(data.sessionId) ? {'sessionId': data.sessionId, langId: filter && filter.langId ? filter.langId : 'de' , eventType: 'comment'} :
                    {'sessionId': localStorage.getItem('sessionId'), langId: 'de', eventType: 'comment'};
}

export const CommentaryFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <TextInput label="LangId" source="langId" alwaysOn />
    </Filter>
);

export const CommentaryMotorSportActions = (props) => {
    const {
        basePath,
        currentSort,
        exporter,
        filterValues,
        resource,
        permissions,
        record
    } = props;
    return (
        <CardActions>
            <BackButton />
            {(isAdmin(permissions) || isMatchEditor(permissions)) && <CommentaryCreateButton basePath={basePath} record={record} />}
            <ExportButton
                resource={resource}
                sort={currentSort}
                filter={filterValues}
                exporter={exporter}
            />
        </CardActions>
    )
};

export const CommentaryList = ({permissions, ...props}) => {
    const record = getDefaultRecord(props.location.search);
    return (
        <List {...props} sort={{ field: 'sort', order: 'DESC' }} filters={<CommentaryFilter />} actions={<CommentaryMotorSportActions permissions={permissions} record={record} />}
            bulkActionButtons={(isAdmin(permissions) || isMatchEditor(permissions)) ? <BulkActionButtons /> : false} >
            <Datagrid>
                <TextField source="id" />
                <TextField source="langId" label="Lang ID" />
                <TextField source="sessionId" label="Session ID" />
                <TextField source="sort" />
                <TextField source="lap" />
                <TextField source="message" />
                <TextField source="eventType" label="Event Type" />
                {(isAdmin(permissions) || isMatchEditor(permissions)) && <CloneButton />}
                {(isAdmin(permissions) || isMatchEditor(permissions)) && <CommentaryEditButton />}
            </Datagrid>
        </List>
)};

const Title = ({ record }) => {
    return <span>Commentary {record ? `"${record.id}"` : ''}</span>;
};





