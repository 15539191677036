import React from 'react';
import { Edit, SimpleForm, TextInput, DateTimeInput, ReferenceInput } from 'react-admin';
import { ResourceActions } from '../buttons/ResourceAction';
import { DefaultToolbar } from '../helpers/DefaultToolbar';
import { SportAdminPage } from '../components/customs';
import isEmpty from 'lodash/isEmpty';
import { SelectInput } from '../components/customs/Input';

const statusChoice = [
    { id: 'assigned', name: 'assigned' },
    { id: 'doing', name: 'doing' },
    { id: 'done', name: 'done' },
]

const TaskTitle = (props: any) => {
    const { record } = props;
    const shortDescription = record && record.description && record.description.substring(0, 30);
    return <span>Task {record && record.description && shortDescription && record.description.length > shortDescription.length ? `"${shortDescription}..."` : `"${record.description}"`}</span>;
};

const TaskAssignEdit = (props) => (
    <SportAdminPage>
        <Edit title={<TaskTitle />} {...props} actions={props.showResourceActions && <ResourceActions />}>
            <SimpleForm
                toolbar={!isEmpty(props.customToolBar) ? props.customToolBar : <DefaultToolbar />}
             >
                <TextInput disabled source="description"/>
                <SelectInput source="status" choices={statusChoice} customInput={false} />
                <TextInput disabled source="createdBy" />
                <DateTimeInput source="createdAt" disabled />
                <ReferenceInput label="Assigned To" source="assignedTo" reference="user" >
                    <SelectInput optionText="emailAddress" customInput={false} />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    </SportAdminPage>
);

export default TaskAssignEdit;