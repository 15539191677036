import React from 'react';
import { Edit, SimpleForm, TextInput, SelectInput, Toolbar, SaveButton } from 'react-admin';
import { CommentaryFilter } from '../motorsport/CommentaryList';
import { ResourceActions } from '../../buttons/ResourceAction';
import { DefaultToolbar, Title } from '../motorsport/CommentaryEdit';
import { validate } from '../motorsport/CommentaryCreate';

export const CommentaryEdit = (props) => (
    <Edit title={<Title />} {...props} actions={<ResourceActions />} >
        <SimpleForm toolbar={<DefaultToolbar />} validate={validate} >
            <TextInput disabled source="id" />
            <TextInput disabled source="sessionId" />
            <TextInput source="langId" />
            <TextInput source="sort" />
            <TextInput source="minute" />
            <TextInput multiline source="message" />
            <SelectInput source="eventType" choices={[
                { id: 'comment', name: 'comment' },
                { id: 'start', name: 'start' },
                { id: 'end', name: 'end' }
            ]} />
        </SimpleForm>
    </Edit>
);