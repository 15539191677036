import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import { FieldTitle, addField, translate, SelectInput as DefaultSelectInput } from 'react-admin';
import { SelectInputBase } from './InputBase';
import ResettableTextField from './ResettableTextField';

/*const sanitizeRestProps = ({
    addLabel,
    allowEmpty,
    basePath,
    choices,
    className,
    component,
    crudGetMatching,
    crudGetOne,
    defaultValue,
    filter,
    filterToQuery,
    formClassName,
    initializeForm,
    input,
    isRequired,
    label,
    locale,
    meta,
    onChange,
    options,
    optionValue,
    optionText,
    perPage,
    record,
    reference,
    resource,
    setFilter,
    setPagination,
    setSort,
    sort,
    source,
    textAlign,
    translate,
    translateChoice,
    validation,
    ...rest
}) => rest;*/

/*const styles = theme => ({
    input: {
        minWidth: theme.spacing.unit * 20,

    },
});

export class SelectInput extends Component {*/
    /*
     * Using state to bypass a redux-form comparison but which prevents re-rendering
     * @see https://github.com/erikras/redux-form/issues/2456
     */
    /*state = {
        value: this.props.input.value,
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.input.value !== this.props.input.value) {
            this.setState({ value: nextProps.input.value });
        }
    }

    handleChange = eventOrValue => {
        const value = eventOrValue.target
            ? eventOrValue.target.value
            : eventOrValue;
        this.props.input.onChange(value);

        // HACK: For some reason, redux-form does not consider this input touched without calling onBlur manually
        this.props.input.onBlur();
        this.setState({ value });
    };

    addAllowEmpty = choices => {
        if (this.props.allowEmpty) {
            return [<MenuItem value="" key="null" />, ...choices];
        }

        return choices;
    };
    renderMenuItemOption = choice => {
        const { optionText, translate, translateChoice } = this.props;
        if (React.isValidElement(optionText))
            return React.cloneElement(optionText, {
                record: choice,
            });
        const choiceName =
            typeof optionText === 'function'
                ? optionText(choice)
                : get(choice, optionText);
        return translateChoice
            ? translate(choiceName, { _: choiceName })
            : choiceName;
    };

    renderMenuItem = choice => {
        const { optionValue } = this.props;
        return (
            <MenuItem
                key={get(choice, optionValue)}
                value={get(choice, optionValue)}
            >
                {this.renderMenuItemOption(choice)}
            </MenuItem>
        );
    };

    render() {
        const {
            customInput,
            allowEmpty,
            choices,
            classes,
            className,
            input,
            isRequired,
            label,
            meta,
            options,
            resource,
            source,
            ...rest
        } = this.props;
        if (typeof meta === 'undefined') {
            throw new Error(
                "The SelectInput component wasn't called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details."
            );
        }
        const { touched, error, helperText = false } = meta;

        return (
            customInput ?
            <FormControl>
                <InputLabel>
                    <FieldTitle
                        label={label}
                        source={source}
                        resource={resource}
                        isRequired={isRequired}
                    />
                </InputLabel>
                <Select
                    value={this.state.value}
                    onChange={this.handleChange}
                    input={<SelectInputBase className={classes.input} name={source} />}
                >
                {this.addAllowEmpty(choices.map(this.renderMenuItem))}
               </Select>
            </FormControl> :

            <ResettableTextField
                select
                margin="normal"
                value={this.state.value}
                label={
                    <FieldTitle
                        label={label}
                        source={source}
                        resource={resource}
                        isRequired={isRequired}
                    />
                }
                name={input.name}
                className={`${classes.input} ${className}`}
                clearAlwaysVisible
                error={!!(touched && error)}
                helperText={(touched && error) || helperText}
                {...options}
                {...sanitizeRestProps(rest)}
                onChange={this.handleChange}
            >
                {this.addAllowEmpty(choices.map(this.renderMenuItem))}
            </ResettableTextField>
        );
    }
}

SelectInput.propTypes = {
    customInput: PropTypes.bool,
    allowEmpty: PropTypes.bool.isRequired,
    choices: PropTypes.arrayOf(PropTypes.object),
    classes: PropTypes.object,
    className: PropTypes.string,
    input: PropTypes.object,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    meta: PropTypes.object,
    options: PropTypes.object,
    optionText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.element,
    ]).isRequired,
    optionValue: PropTypes.string.isRequired,
    resource: PropTypes.string,
    source: PropTypes.string,
    translate: PropTypes.func.isRequired,
    translateChoice: PropTypes.bool,
};

SelectInput.defaultProps = {
    customInput: true,
    allowEmpty: false,
    classes: {},
    choices: [],
    options: {},
    optionText: 'name',
    optionValue: 'id',
    translateChoice: true,
};

export default compose(
    addField,
    translate,
    withStyles(styles)
)(SelectInput);*/

const SelectInput = props => (
    <DefaultSelectInput {...props} />
)

export default SelectInput;