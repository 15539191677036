import React from 'react'
import { SaveButton, Toolbar } from 'react-admin'

export const DefaultToolbar = props => (
    <Toolbar {...props} >
        <SaveButton
            label="save"
            redirect="list"
            submitOnEnter={true}
        />
    </Toolbar>
);