import React from 'react';
import { Create, AutocompleteInput, SimpleForm, TextInput, SelectInput, CardActions, CreateButton, SaveButton, Toolbar } from 'react-admin';
import { ResourceActions, BackButton } from '../../buttons/ResourceAction';
import { validate } from '../motorsport/CommentaryCreate';
import { DefaultToolbar } from '../DefaultToolbar';

export const CommentaryCreate = (props) => (
    <Create {...props} actions={<ResourceActions />}>
        <SimpleForm toolbar={<DefaultToolbar />} validate={validate} >
            <TextInput disabled source="sessionId" />
            <TextInput source="langId" label="Lang Id"/>
            <TextInput source="minute" />
            <TextInput multiline source="message" />
            <SelectInput source="eventType" choices={[
                { id: 'comment', name: 'comment' },
                { id: 'start', name: 'start' },
                { id: 'end', name: 'end' }
            ]} />
        </SimpleForm>
    </Create>
);