import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { LogoField } from './helpers/utils';
import { ProfileField } from './helpers/utils';


const AvatarField = ({ record, size, type='profile' }) => (
    type === 'profile' ? <ProfileField record={record} size={size} /> : <LogoField record={record} size={size} />
);

AvatarField.defaultProps = {
    size: 25,
};

export default AvatarField;