import { AuthProvider } from 'ra-core';
import { API_URL } from './helpers/consts';


const authProvider: AuthProvider = {

    login: ({username, password}) => {
        let token = username + ":" + password;
        let data = {
            "userName": username,
            "password": password
        }
        const request = new Request(API_URL + "/api/user/login", {
            method: "post",
            headers: new Headers({
                Authorization: "Basic " + btoa(token),
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(data)
          });

        return fetch(request)
            .then(response => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
                return response.json();
            })
            .then(({ token, permissions }) => {
                localStorage.setItem("token", token);
                localStorage.setItem("role", permissions);
            }); 
    },
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('role');

        const request = new Request(API_URL + "/api/user/logout", {
            method: "post"
        });

        return fetch(request)
            .then(response => {
                console.log(response);
            })
            .catch(error => console.log(error));
        //checkLogoutRedirect();

        return Promise.resolve();
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            localStorage.removeItem('role');

            return Promise.reject();

        }
        return Promise.resolve();
    },
    checkAuth: () => {
        return localStorage.getItem('token')
         ? Promise.resolve() 
         : Promise.reject();
    },
    getPermissions: () => {
        const role = localStorage.getItem('role');
        return role ? Promise.resolve(role) : Promise.reject();
    }
};

export const checkLogoutRedirect = () => {
    if(localStorage.getItem('logoutUrl')) {
        const logoutUrl = localStorage.getItem('logoutUrl');
        localStorage.removeItem('logoutUrl');
        window.location.href = logoutUrl || '/';
    }

}

export default authProvider;
