import React from 'react'
import PropTypes from 'prop-types'
import { TeamName, MatchScore } from './MatchComponents'

import { View, translate, Row, Text, TeamLogo } from '@sport1/universal-components'

class TeamMatch extends React.Component {
    static propTypes = {
        homeTeam: PropTypes.object.isRequired,
        awayTeam: PropTypes.object.isRequired,
        homeScore: PropTypes.number.isRequired,
        awayScore: PropTypes.number.isRequired,
        homePenaltyScore: PropTypes.number,
        awayPenaltyScore: PropTypes.number,
        period: PropTypes.string,
        matchInfo: PropTypes.object,
        containerStyles: PropTypes.object,
    }

    render() {
        const {
            homeTeam,
            awayTeam,
            homeScore,
            awayScore,
            homePenaltyScore,
            awayPenaltyScore,
            period,
            status,
            isLive,
            scheduledStartTime,
            matchInfo,
            containerStyles,
            ...rest
        } = this.props

        const resultType = period !== 'PRE_MATCH' && matchInfo ? matchInfo.resultType : ''

        const showBottomSpace =
            resultType !== 'AFTER_EXTRA_TIME' && resultType !== 'PENALTY_SHOOTOUT' && !isLive

        return (
            <View {...containerStyles}>
                <Row center mt={2} mb={showBottomSpace ? 2 : 0}>
                    <TeamName py={0} {...{ homeTeam, ...rest }} textAlign="left">
                        {homeTeam && (homeTeam.shortName || homeTeam.name)}
                    </TeamName>
                    <TeamLogo size={20} mx={2} my={0} src={homeTeam && homeTeam.logo && homeTeam.logo.src} />
                    <Row center width={60}>
                        {(() => {
                            switch (resultType) {
                                case 'POSTPONED':
                                    return <Text size="small">{translate('match_postponed')}</Text>
                                case 'ABANDONED':
                                    return <Text size="small">{translate('match_abandoned')}</Text>
                                default:
                                    return (
                                        <MatchScore
                                            size="large"
                                            {...{
                                                homeScore,
                                                awayScore,
                                                homePenaltyScore,
                                                awayPenaltyScore,
                                                period,
                                                status,
                                                scheduledStartTime,
                                                ...rest,
                                            }}
                                        />
                                    )
                            }
                        })()}
                    </Row>
                    <TeamLogo size={20} mx={2} my={0} src={awayTeam && awayTeam.logo && awayTeam.logo.src} />
                    <TeamName alignRight py={0} {...{ awayTeam, ...rest }}>
                        {awayTeam && (awayTeam.shortName || awayTeam.name)}
                    </TeamName>
                </Row>
            </View>
        )
    }
}

export default TeamMatch
