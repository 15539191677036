import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { userLogout as userLogoutAction, translate } from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import InputIcon from '@material-ui/icons/Input';
import Tooltip from '@material-ui/core/Tooltip';

class UserLogout extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        const { classes, userLogout, translate } = this.props;
        return(
            <Tooltip title={translate('ra.auth.logout')}>
                <IconButton
                    color="inherit"
                    onClick={userLogout}
                >
                    <InputIcon />
                </IconButton>
            </Tooltip>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    userLogout: () => dispatch(userLogoutAction()),
});

export default compose(
    translate,
    connect(
        undefined,
        mapDispatchToProps,
    ),
)(UserLogout)