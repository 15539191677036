import React, { useState, useEffect, FC, Fragment } from 'react';
import { Responsive, 
        ReferenceInput,
        usePermissions,
        useDataProvider,
        useNotify,
        BulkDeleteButton,
        BooleanField,
        TextField,
        DateField,
        Filter,
        TextInput,
        SelectInput,
        GET_LIST 
    }
from 'react-admin';
import { List } from '../components/customs';
import { SearchInput } from '../components/customs/Input';
import { buildRequest, checkResponse } from '../helpers/request';
import isEmpty from 'lodash/isEmpty';
import DatagridView from '../events/DatagridView';
import EventReferenceField from '../events/EventReferenceField';
import {isAdmin} from "../permissions";

const GenericField = ( record ) => {
    const fields: any = []
    if(!isEmpty(record)) {
        let isImageAvailableField;
        for (const [key, value] of Object.entries(record)) {
            if(key !== 'result') {
                if(typeof value === 'boolean') {
                    if(key === 'isImageAvailable') {
                        isImageAvailableField = <BooleanField source={key} key={key} />;
                    }
                    else {
                        fields.push(<BooleanField source={key} key={key} />)
                    }
                }
                else if(key === 'startDate' || key === 'endDate') {
                    fields.push(<DateField source={key} key={key} showTime />)
                }
                else if(key === 'event') {
                    fields.push(<EventReferenceField key={key} />)
                }
                else {
                    fields.push(<TextField source={key} key={key} />)
                }
            }

        }

        if(!isEmpty(isImageAvailableField)) {
            fields.push(isImageAvailableField)
        }
    }

    return fields;
}

interface SportParams {
    controllerPostfix?: string
}

const SessionList: FC = (props: any) => {
    const [sport, setSport] = useState<SportParams>({});
    const { permissions } = usePermissions();

    const dataProvider = useDataProvider();
    const notify = useNotify();

    useEffect(() => {
        const defaultSportId = localStorage.getItem('eventControllerPostFix');
        if(isEmpty(defaultSportId) || defaultSportId === 'undefined') {
            fetchDefaultEventSport(null);
        }
        else {
            fetchDefaultEventSport(defaultSportId);
        }
    }, []);

    const UpdateData = (sportType, props) => {
        if(!isEmpty(sportType) && typeof sportType === 'string') {
            localStorage.setItem('eventControllerPostFix', sportType);
        }
        const { resource, currentSort, page, perPage, filterValues } = props;
        const defaultFilter = { sportType: sportType };

        const filter = !isEmpty(filterValues) ? Object.assign(filterValues, defaultFilter) : defaultFilter ;

        //@ts-ignore
        dataProvider(GET_LIST, `${resource}`, { sort: currentSort, pagination: { page: page, perPage: perPage }, filter: filter })
            .catch(e => {
                notify('Error: failed to fetch', e);
            });
    }

    const fetchDefaultEventSport = (sportType) => {
        const url = isEmpty(sportType) ? `sport/events/default-sport` : `sport/events/default-sport?sport=${sportType}`;
        const request = buildRequest(url, 'GET')
        fetch(request)
            .then(checkResponse)
            .then( (json) => {
                localStorage.setItem('eventControllerPostFix', json.controllerPostfix);
                setSport(json);
            })
            .catch(error => console.error('Error:', error));

    }

    const PostBulkActionButtons = props => (
        <Fragment>
            {/* default bulk delete action */}
            <BulkDeleteButton {...props} />
        </Fragment>
    );

    const SessionFilter = (props) => (
        <Filter {...props}>
            <ReferenceInput
                label="Sports"
                source="sportType"
                reference="sport/events"
                inputProps={{
                    onChange: (e) => {
                        UpdateData(e.target.value, props);
                    }
                }}
                sort={{ field: 'id', order: 'ASC' }}
                filter={{}}
                alwaysOn
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput label="Search" source="q" alwaysOn />
        </Filter>
    );

    return !isEmpty(sport) ? (
        <List {...props} filters={<SessionFilter />} filterDefaultValues={{ sportType: sport.controllerPostfix }}
              bulkActionButtons={isAdmin(permissions) &&<PostBulkActionButtons />} >
            <Responsive
                medium={
                    // @ts-ignore
                    <DatagridView sportId={sport.controllerPostfix} permissions={permissions} genericField={GenericField} />
                }
            />
        </List>
    ): null;
}

export default SessionList;