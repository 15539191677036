import React from 'react';
import compose from 'recompose/compose';
import MuiGridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import { linkToRecord } from 'ra-core';
import Match from './pages';
import TeamMatch from './TeamMatch';
import SessionMatch from './SessionMatch';


const styles = theme => ({
    root: {
        marginRight: '-20px',
        padding: theme.spacing(3.5)
    },
    gridList: {
        width: '100%',
    },
    tileBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)',
    },
    placeholder: {
        backgroundColor: theme.palette.grey[300],
        height: '100%',
    },
    price: {
        display: 'inline',
        fontSize: '1em',
    },
    link: {
        color: '#fff',
        textDecoration: 'none',
    },
    gridListTile: {
        textDecoration: 'none',
    },

});

const getColsForWidth = width => {
    if (width === 'xs') return 1;
    if (width === 'sm') return 2;
    if (width === 'md') return 3;
    if (width === 'lg') return 4;
    return 5;
};

const GridListBase = ({ classes, ids, data, basePath, width, sport, path, sort, sortDir }) => (
    <div className={classes.root}>
        <MuiGridList
            cellHeight={100}
            cols={getColsForWidth(width)}
            className={classes.gridList}
            spacing={20}
        >
            {ids.map(id => (
                <GridListTile

                    key={id}
                    to={linkToRecord(basePath, data[id].id)}
                    classes={classes.gridListTile}
                >
                    <Match
                        match={data[id]}
                        key={data[id].id}
                        sportId={sport.id}
                        path={path}
                        sort={sort}
                        sortDir={sortDir}
                    >
                        {
                            data[id].renderMotorSport || data[id].renderCyclingSport ?
                            <SessionMatch competitionName={data[id].competition.name} sessionName={data[id].roundType || data[id].name}/> :
                            <TeamMatch {...data[id]} />
                        }

                    </Match>

                </GridListTile>
            ))}
        </MuiGridList>
    </div>
);

const MatchGridList = ({...props}) =>
    <GridListBase {...props} /> ;

const enhance = compose(
    withWidth(),
    withStyles(styles)
);

export default enhance(MatchGridList);