import React, { PureComponent } from 'react'
import { Text, Touchable } from '@sport1/universal-components';

export default class Tab extends PureComponent {
    render() {
        const { isActive, title, onPress, bigTab } = this.props

        return (
            <Touchable onPress={onPress} isFlex center px={3}>
                <Text
                    py={bigTab ? 3 : 2}
                    center
                    borderBottom={isActive ? 2 : 0}
                    borderColor="tabHighlight.main"
                    bold={isActive}
                    size="custom"
                    fontSize={bigTab ? '16px' : '13px'}
                >
                    {title}
                </Text>
            </Touchable>
        )
    }
}
