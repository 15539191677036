import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { FormControl, InputLabel } from '@material-ui/core';
import { InputBase } from './InputBase';
import { FieldTitle, InputProps } from 'ra-core';
import { TextFieldProps } from '@material-ui/core/TextField';
import { SearchInput as DefaultSearchInput } from 'react-admin';

/*const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    flexBasis: 420,
  },
  icon: {
    color: theme.palette.text.secondary
  },
  input: {
    flexGrow: 1,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '-0.05px'
  },
  label: {
    paddingTop: theme.spacing(1),
  }
}));

/* export type SearchInputProps = InputProps<TextFieldProps> &
    Omit<TextFieldProps, 'label' | 'helperText'>; */

/*interface Props {
  className?: any,
  source: string,
  resource?: string,
  isRequired?: Boolean,
  style?: any,
  label: string,
  input?: any,
  alwaysOn?: Boolean
}

const SearchInput: FC<Props> = ({
  className,
  source,
  resource,
  isRequired,
  style,
  label,
  input,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <FormControl>
       <InputLabel shrink className={classes.label} >
            <FieldTitle
                label={label}
                source={source}
                resource={resource}
                // @ts-ignore
                isRequired={isRequired}
            />
       </InputLabel>
        <InputBase*/
/*            startAdornment={
                <InputAdornment position="start">
                  <SearchIcon className={classes.icon} />
                </InputAdornment>
            }*/
/*            {...rest}
            // @ts-ignore
            disableUnderline
            onChange={input.onChange}
            placeholder={label}
        />
    </FormControl>
  );
};*/

/*SearchInput.propTypes = {
  className: PropTypes.string,
  // @ts-ignore
  onChange: PropTypes.func,
  style: PropTypes.object
};

export default SearchInput;*/

const SearchInput = props => (
  <DefaultSearchInput {...props} />
)

export default SearchInput;
