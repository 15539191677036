import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import {
    EditButton,
    translate,
} from 'react-admin';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import AvatarNameField from '../AvatarNameField';
import isEmpty from 'lodash/isEmpty';

import TrueValueField from '../TrueValueField';
import { isAdmin, isEditor } from '../permissions';

const listStyles = ({ typography }: Theme) => createStyles({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: typography.body1,
    cardContentRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '0.5rem 0',
    },
});

export const MobileGrid = ({permissions, classes, ids, data, basePath, translate}) => (
    <div style={{ margin: '1em' }}>
        {ids.map(id => (
            <Card key={id} className={classes.card}>
                <CardHeader
                    title={
                        <div className={classes.cardTitleContent}>
                            <span>
                                <AvatarNameField record={data[id]} name={ !isEmpty(data[id].nameDE) ? data[id].nameDE : data[id].name } />
                            </span>
                            {
                                (isAdmin(permissions) || isEditor(permissions )) &&
                                <EditButton
                                    resource="competition"
                                    basePath={basePath}
                                    record={data[id]}
                                />
                            }
                        </div>
                    }
                />
                <CardContent className={classes.cardContent}>
                    <TrueValueField className={classes.cardContentRow} label={translate('resources.competition.fields.id')} record={data[id]}  source="id" />

                    <TrueValueField className={classes.cardContentRow} label={translate('resources.competition.fields.nameDE')} record={data[id]}  source="nameDE" />

                    <TrueValueField className={classes.cardContentRow} label={translate('resources.competition.fields.isActive')} record={data[id]}  source="isActive" />

                    <TrueValueField className={classes.cardContentRow} label={translate('resources.competition.fields.hasTicker')} record={data[id]}  source="hasTicker" />

                    <TrueValueField className={classes.cardContentRow} label={translate('resources.competition.fields.hasStandings')} record={data[id]}  source="hasStandings" />

                    <TrueValueField className={classes.cardContentRow} label={translate('resources.competition.fields.isTop')} record={data[id]}  source="isTop" />

                    <TrueValueField className={classes.cardContentRow} label={translate('resources.competition.fields.countryCode')} record={data[id]}  source="countryCode" />

                    <TrueValueField className={classes.cardContentRow} label={translate('resources.competition.fields.topSort')} record={data[id]}  source="topSort" />

                </CardContent>

            </Card>
        ))}
    </div>
);

const enhance = compose(
    withStyles(listStyles),
    translate
);

export default enhance(MobileGrid);