import React from 'react';
import { SportAdminPage } from '../components/customs';
import { SimpleForm, TextInput, Create, ReferenceInput } from 'react-admin';
import { ResourceActions } from '../buttons/ResourceAction';
import { DefaultToolbar } from '../helpers/DefaultToolbar';
import isEmpty from 'lodash/isEmpty';
import { SelectInput } from '../components/customs/Input';


const TaskAssignCreate = (props) => (
    <SportAdminPage>
        <Create {...props} actions={props.showResourceActions && <ResourceActions />}>
            <SimpleForm toolbar={!isEmpty(props.customToolBar) ? props.customToolBar : <DefaultToolbar />}>
                <TextInput multiple source="description"/>
                <ReferenceInput label="Assigned To" source="assignedTo" reference="user" >
                    <SelectInput label="Email" optionText="emailAddress" customInput={false} />
                </ReferenceInput>

            </SimpleForm>
        </Create>
    </SportAdminPage>
);

TaskAssignCreate.defaultProps = {
    showResourceActions: true,
}

export default TaskAssignCreate