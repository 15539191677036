import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import {
    EditButton,
    translate,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import isEmpty from 'lodash/isEmpty';

import TrueValueField from '../TrueValueField';
import NameField from '../NameField';
import {isAdmin, isEditor} from '../permissions';

const listStyles = theme => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: theme.typography.body1,
    cardContentRow: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        margin: '0.5rem 0',
    },
});

export const MobileGrid = ({permissions, classes, ids, data, basePath, translate}) => (
    <div style={{ margin: '1em' }}>
        {ids.map(id => (
            <Card key={id} className={classes.card}>
                <CardHeader
                    title={
                        <div className={classes.cardTitleContent}>
                            <span>
                                <NameField name={ !isEmpty(data[id].name) ? data[id].name : data[id].nameDE } />
                            </span>
                            {
                                (isAdmin(permissions) || isEditor(permissions)) &&
                                <EditButton
                                    resource="season"
                                    basePath={basePath}
                                    record={data[id]}
                                />
                            }
                        </div>
                    }
                />
                <CardContent className={classes.cardContent}>
                    <TrueValueField className={classes.cardContentRow} label={translate('resources.season.fields.id')} record={data[id]}  source="id" />

                    <TrueValueField className={classes.cardContentRow} label={translate('resources.season.fields.name')} record={data[id]}  source="name" />

                    <TrueValueField className={classes.cardContentRow} label={translate('resources.season.fields.nameDE')} record={data[id]}  source="nameDE" />

                    <TrueValueField className={classes.cardContentRow} label={translate('resources.season.fields.startDate')} record={data[id]}  source="startDate" />

                    <TrueValueField className={classes.cardContentRow} label={translate('resources.season.fields.endDate')} record={data[id]}  source="endDate" />

                    <TrueValueField className={classes.cardContentRow} label={translate('resources.season.fields.year')} record={data[id]}  source="year" />

                    <TrueValueField className={classes.cardContentRow} label={translate('resources.season.fields.competitionId')} record={data[id]}  source="competitionId" />

                    <TrueValueField className={classes.cardContentRow} label={translate('resources.season.fields.isCurrent')} record={data[id]}  source="isCurrent" />

                    <TrueValueField className={classes.cardContentRow} label={translate('resources.season.fields.sportId')} record={data[id]}  source="sportId" />

                </CardContent>

            </Card>
        ))}
    </div>
);

const enhance = compose(
    withStyles(listStyles),
    translate
);

export default enhance(MobileGrid);