import React, { Component } from 'react';
import { buildRequest, checkResponse } from './helpers/request';

const updateDBFromS3 = (parent) => {
    const request = buildRequest('temp/update-db-from-s3', 'POST');

    fetch(request)
        .then(checkResponse)
        .then(parent.props.history.push('/'))
        .catch(error => console.error('Error:', error));
}

class Temp extends Component{

    componentDidMount(){
        updateDBFromS3(this);
    }

    render() {
        return null;
    }

}
export default Temp;