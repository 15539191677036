import React from 'react';
import { ReferenceField, TextField } from 'react-admin';

const SeasonReferenceField = props => (
    <ReferenceField label="Season" source="season.id" reference="season" {...props} linkType="show">
        <TextField source="name" />
    </ReferenceField>
);

SeasonReferenceField.defaultProps = {
    source: 'season.id',
    addLabel: true,
    label: 'Season'
};

export default SeasonReferenceField;