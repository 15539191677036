// @ts-nocheck
import React, { useEffect } from 'react';
import { Admin, Resource, resolveBrowserLocale } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import authProvider from './authProvider';
import dataProvider from './dataProvider';
import Dashboard from './Dashboard';
import Layout from './layout/AppLayout';
import competitions from './competitions';
import teams from './teams';
import players from './players';
import countries from './countries';
import sports from './sports';
import SportList from './sports/SportList';
import sportgroups from './sport_groups';
import seasons from './seasons';
import tasks from './tasks_assign';
import soccerCommentaries from './commentaries/soccer'
import basketballCommentaries from './commentaries/basketball';
import tennisCommentaries from './commentaries/tennis';
import handballCommentaries from './commentaries/handball';
import icehockeyCommentaries from './commentaries/icehockey';
import volleyballCommentaries from './commentaries/volleyball';
import motorsports from './commentaries/motorsport';
import cyclings from './commentaries/cycling';
import MatchList from './matches/MatchList';
import matchResourcePages from './matches/pages/matchResourcePages';
import events from './events';
import officials from './officials';
import referee from './referee';
import changes from './changes';
import sessions from './sessions';
import standingsGroup from './standings/standings_group';
import matchGroup from './matches/match_group';
import teamStandings from './standings/team-standings';
import soccerTeamStanding from './standings/soccer_team_standing';
import providerUploadFeature from './providerUploadFeature';
import routes from './routes';
import englishMessages from './i18n/en';
import germanMessages from './i18n/de';
import LoginPage from './login/LoginPage';
import Temp from './Temp';


const App = () => {
  useEffect(() => {
    document.title = "Sport Admin";
  },[]);

  const uploadCapableDataProvider = providerUploadFeature(dataProvider);

  const messages = {
    de: germanMessages,
    en: englishMessages
  };
  const i18nProvider = polyglotI18nProvider(
    locale => messages[locale] ? messages[locale] : messages.en,
    resolveBrowserLocale()
  );

  return (
    <Admin
      title="Sport Admin"
      loginPage={LoginPage}
      appLayout={Layout}
      customRoutes={routes}
      dashboard={Dashboard}
      authProvider={authProvider}
      dataProvider={uploadCapableDataProvider}
      locale="de"
      i18nProvider={i18nProvider}
    >

      <Resource name="competition" {...competitions} />
      <Resource name="team" {...teams} />
      <Resource name="player" {...players} />
      <Resource name="country" {...countries} />
      <Resource name="country/all" {...countries} />
      <Resource name="season" {...seasons} />
      <Resource name="sport" {...sports} />
      <Resource name="sport/all" list={SportList} />
      <Resource name="sport/active" list={SportList} />
      <Resource name="sport/events" list={SportList} />
      <Resource name="sport/team-standings-tables" list={SportList} />
      <Resource name="sport/match" list={SportList} />
      <Resource name="soccer/commentary" {...soccerCommentaries} />
      <Resource name="basketball/commentary" {...basketballCommentaries} />
      <Resource name="tennis/commentary" {...tennisCommentaries} />
      <Resource name="handball/commentary" {...handballCommentaries} />
      <Resource name="icehockey/commentary" {...icehockeyCommentaries} />
      <Resource name="volleyball/commentary" {...volleyballCommentaries} />
      <Resource name="formula1/commentary" {...motorsports} />
      <Resource name="cycling/commentary" {...cyclings} />
      <Resource name="group" {...sportgroups} />
      <Resource name="match" list={MatchList} />
      <Resource name="match-sports" {...matchResourcePages} />
      <Resource name="user" />
      <Resource name="task" {...tasks} />
      <Resource name="user" />
      <Resource name="event" {...events} />
      <Resource name="session" {...sessions} />
      <Resource name="standings-group" {...standingsGroup} />
      <Resource name="team-standings" {...teamStandings} />
      <Resource name="soccer-team-standing" {...soccerTeamStanding} />
      <Resource name="match-group" {...matchGroup} />
      <Resource name="officials" {...officials} />
      <Resource name="referee" {...referee} />
      <Resource name="changes" {...changes} />
      <Resource name="temp" list={Temp} />
    </Admin>
  )
}

export default App;

