import React from 'react';
import { connect } from 'react-redux';
import Layout from './Layout';
import Menu from './Menu';
import Appbar from './Appbar';
import Sidebar from './Sidebar';
import theme from '../theme';

const AppLayout = (props) => <Layout appBar={Appbar} {...props} menu={Menu} sidebar={Sidebar} />;

const darkTheme = {
    palette: {
        type: 'dark', // Switching the dark mode on is a single property value change.
    },
};

const lightTheme = {
    palette: {
        secondary: {
            light: '#5f5fc4',
            main: '#283593',
            dark: '#001064',
            contrastText: '#fff',
        },
    },
};

export default connect(
    state => ({
        theme: state.theme === 'dark' ? darkTheme : theme,
    }),
    {}
)(AppLayout);