import React from 'react';
import { TextInput, Edit, SimpleForm, BooleanInput } from 'react-admin';
import { DefaultToolbar } from '../helpers/DefaultToolbar';
import { SportAdminPage } from '../components/customs';

const SportTitle = (props: any) => {
    const { record } = props;
    return <span>Sport {record ? `"${record.name}"` : ''}</span>;
};

const SportEdit = (props) => (
    <SportAdminPage>
        <Edit title={<SportTitle />} {...props}>
            <SimpleForm toolbar={<DefaultToolbar />}>
                <TextInput disabled source="id" />
                <TextInput disabled source="name" label="Name" />
                <BooleanInput source="isActive" />
                <TextInput multiple source="nameDE" label="Name DE"/>
                <TextInput source="sort" />
            </SimpleForm>
        </Edit>
    </SportAdminPage>
);

export default SportEdit;