import React from 'react';
import { TextField, EditButton, ImageField, Responsive, BooleanField, TextInput, Filter, SearchInput } from 'react-admin';
import { List, Datagrid } from '../components/customs';
import MobileGrid from './MobileGrid';
import { isAdmin, isEditor } from '../permissions';
import { ProfileField } from '../helpers/utils';
import { PurgeButton } from '../components/buttons';

const PlayerFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

const PlayerList = ({permissions, ...props}) => (
    <List {...props} filters={<PlayerFilter />} bulkActionButtons={false}>
        <Responsive
            medium={
                <Datagrid>
                    <ProfileField size={40}/>
                    <TextField source="id" />
                    <TextField source="standardPosition" />
                    <TextField source="firstName" />
                    <TextField source="lastName" />
                    <TextField source="birthDate" />
                    <TextField source="weight" />
                    <TextField source="height" />
                    <TextField source="nickName" />
                    <TextField source="birthPlace" />
                    <TextField source="nationality" />
                    <TextField source="preferredFoot" />
                    <TextField source="realPosition" />
                    <TextField source="realPositionSide" />
                    <TextField source="country" />
                    <BooleanField source="isImageAvailable" />
                    {isAdmin(permissions) && <PurgeButton />}
                    {(isAdmin(permissions) || isEditor(permissions)) && <EditButton />}
                </Datagrid>
            }
        />
    </List>
);

export default PlayerList;