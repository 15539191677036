import CompetitionList from './CompetitionList';
import CompetitionEdit from './CompetitionEdit';
import Icon from '@material-ui/icons/LocalPlay';

export const CompetitionIcon = Icon;

export default {
    list: CompetitionList,
    edit: CompetitionEdit
}

