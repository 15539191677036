import React, { FC, isValidElement, Children, cloneElement } from 'react';
import { BooleanField, TextField, ImageField, DateField, EditButton } from 'react-admin';
import { Datagrid } from '../components/customs';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import SeasonReferenceField from '../seasons/SeasonReferenceField';
import SportReferenceField from '../sports/SportReferenceField';
import CompetitionReferenceField from '../competitions/CompetitionReferenceField';
import {isAdmin, isEditor} from '../permissions';
import { LogoField } from '../helpers/utils';
import { PurgeButton } from '../components/buttons';

const GenericField = ( record ) => {
    const fields: any = []
    if(!isEmpty(record)) {
        let isImageAvailableField;
        for (const [key, value] of Object.entries(record)) {
            if(key !== 'venue' && key !== 'logo') {
                if(typeof value === 'boolean') {
                    if(key === 'isImageAvailable') {
                        isImageAvailableField = <BooleanField source={key} key={key} />;
                    }
                    else {
                        fields.push(<BooleanField source={key} key={key} />)
                    }
                }
                else if(key === 'startDate' || key === 'endDate') {
                    fields.push(<DateField source={key} key={key} showTime />)
                }
                else if(key === 'season') {
                    fields.push(<SeasonReferenceField key={key} />)
                }
                else if(key === 'sport') {
                    if(typeof value !== 'string') {
                        fields.push(<SportReferenceField key={key} />)
                    }
                }
                else if(key === 'competition') {
                    fields.push(<CompetitionReferenceField key={key} />)
                }
                else {
                    fields.push(<TextField source={key} key={key} />)
                }
            }

        }

        if(!isEmpty(isImageAvailableField)) {
            fields.push(isImageAvailableField)
        }
    }

    return fields;
}

const DatagridView: FC = (props: any) => {
    const { permissions, data, ids, genericField=GenericField } = props;
    return (
        <Datagrid {...props} sportId={props.sportId}>
            <LogoField />
            {genericField(data[ids[0]])}
            {(isAdmin(permissions) || isEditor(permissions)) && <PurgeButton />}
            {(isAdmin(permissions) || isEditor(permissions))  && <EditButton />}
        </Datagrid>
    )
}

export default DatagridView;