import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import pure from 'recompose/pure';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import StatusBullet from './StatusBullet'

const styles = (theme => ({
  status: {
    marginRight: theme.spacing(1)
  },
}));

const statusColors = {
  done: 'success',
  doing: 'info',
  assigned: 'neutral'
};

const StatusTextField = ({ className, classes, source, record, ...rest }) => {
    const value = get(record, source);
    return (
        <Typography
            component="span"
            className={className}
            {...rest}
        >
            <StatusBullet
              className={classes.status}
              color={statusColors[record && record.status && record.status.toLowerCase()]}
              size="sm"
            />
            {value && typeof value !== 'string' ? JSON.stringify(value) : value}
        </Typography>
    );
};

StatusTextField.propTypes = {
    addLabel: PropTypes.bool,
    basePath: PropTypes.string,
    className: PropTypes.string,
    cellClassName: PropTypes.string,
    headerClassName: PropTypes.string,
    label: PropTypes.string,
    record: PropTypes.object,
    sortBy: PropTypes.string,
    source: PropTypes.string.isRequired,
};

// wat? TypeScript looses the displayName if we don't set it explicitly
StatusTextField.displayName = 'StatusTextField';
const PureTextField = pure(StatusTextField);

PureTextField.defaultProps = {
    addLabel: true,
};

export default withStyles(styles)(PureTextField);