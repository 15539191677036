import React, { FC, useState, useEffect } from 'react';
import compose from 'recompose/compose';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  Avatar,
} from '@material-ui/core';
import { buildRequest, checkResponse } from '../../helpers/request';
import { usePrevious } from '../../helpers/utils';
import { CompetitionIcon } from '../../competitions';
import { TeamIcon } from '../../teams';
import { PlayerIcon } from '../../players';
import { CountryIcon } from '../../countries';
import { GroupIcon } from '../../sport_groups';
import { OfficialIcon } from '../../officials';
import { RefereeIcon } from '../../referee';
import { SportIcon } from '../../sports';
import isEmpty from 'lodash/isEmpty';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useTranslate } from 'react-admin';
import { isEqual } from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
  },
  content: {
    padding: 0
  },
  image: {
    height: 25,
    width: 25
  },
  actions: {
    justifyContent: 'flex-end'
  },
  avatar: {
    backgroundColor: theme.palette.text.secondary,
    height: 35,
    width: 35,
    margin: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  imgProgressWrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    width: 45
  },
  progressSingle: {
    position: 'absolute',
    top: -15,
    left: -12,
    zIndex: 1,
  },
  progressDouble: {
    position: 'absolute',
    top: -17,
    left: -10,
    zIndex: 1,
  },
  progressTriple: {
    position: 'absolute',
    top: -17,
    left: -6,
    zIndex: 1,
  },
}));

const resourcesIcons = {
  competition: <CompetitionIcon />,
  team: <TeamIcon />,
  player: <PlayerIcon />,
  country: <CountryIcon />,
  sportGroup: <GroupIcon />,
  sport: <SportIcon />,
  officials: <OfficialIcon />,
  referee: <RefereeIcon />,
};

const taskProgressStyles = percentage => {
    return {
        root: {},
        path: {
          stroke: `rgba(62, 152, 199, ${percentage / 100})`,
          strokeLinecap: 'butt',
          transition: 'stroke-dashoffset 0.5s ease 0s',
          transform: 'rotate(0.25turn)',
          transformOrigin: 'center center',
        },
        trail: {
          stroke: '#d6d6d6',
          strokeLinecap: 'butt',
          transform: 'rotate(0.25turn)',
          transformOrigin: 'center center',
        },
        text: {
          fontSize: '25px',
        },
        background: {
          fill: '#3e98c7',
        },
    }

}

const ImageAvailableCount = () => {
  const [resourcesImageAvailableCounts, setResourcesImageAvailableCounts] = useState({});

  const classes = useStyles();
  const translate = useTranslate();

  const fetchResourcesImageAvailableCounts = () => {
    const request = buildRequest('images/resources/available', 'GET');

    fetch(request)
        .then(checkResponse)
        .then( (json) => {
            setResourcesImageAvailableCounts(json)
        })
        .catch(error => console.error('Error:', error));
  }
  
  const prevResourcesImageAvailableCounts = usePrevious(resourcesImageAvailableCounts);
  useEffect(() => {
    if(!isEqual(prevResourcesImageAvailableCounts, resourcesImageAvailableCounts)) {
      fetchResourcesImageAvailableCounts();
    }  
  }, [resourcesImageAvailableCounts, prevResourcesImageAvailableCounts]);

  const resourcesImageAvailableKeys = !isEmpty(resourcesImageAvailableCounts) ? Object.keys(resourcesImageAvailableCounts) : [];

  return (
    <Card
      className={classes.root}
    >
      <CardHeader
        title={translate('pos.dashboard.imageAvailableCount.title')}
      />
      <Divider />
      <CardContent className={classes.content}>
        <List>
            {resourcesImageAvailableKeys.map((key, i) => (
                <ListItem
                  divider={i < resourcesImageAvailableKeys.length - 1}
                  key={i}
                >
                  <Avatar className={classes.avatar}>
                    {resourcesIcons[key]}
                  </Avatar>
                  <ListItemText
                    primary={key}
                  />
                  <div className={classes.imgProgressWrapper}>
                    <CircularProgressbar value={resourcesImageAvailableCounts[key]} text={`${resourcesImageAvailableCounts[key]}%`} styles={taskProgressStyles(resourcesImageAvailableCounts[key])} />
                  </div>
                </ListItem>
            ))}
        </List>
      </CardContent>
      <Divider />
    </Card>
  )
}

export default ImageAvailableCount;