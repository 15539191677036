import {
    GET_LIST,
    GET_ONE,
    CREATE,
    UPDATE,
    GET_MANY,
    DELETE,
    DELETE_MANY,
    fetchUtils,
} from 'react-admin';
import { API_URL } from './helpers/consts';

import { stringify } from 'query-string';
import isEmpty from 'lodash/isEmpty';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    options.headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
    return fetchUtils.fetchJson(url, options);
}


const convertDataProviderRequestToHTTP = (type, resource, params) => {
    var serverPrefix = process.env.NODE_ENV === 'development' ? API_URL : ''
    switch (type) {
    case GET_LIST: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort || {field: 'id', order: 'DESC'};
        let query;
        if(resource === `match/${params.sport}/between`){
            query = {
                fromDate:params.fromDate,
                toDate:params.toDate,
                langId:'de',
                filter: JSON.stringify(params.filter)
            }
        }
        else if (resource === 'match'){
            query = {
                sportId: params.filter.sportType,
                sort: JSON.stringify([field, order]),
                filter: JSON.stringify(params.filter),
                range: JSON.stringify([page, perPage]),
            };
        }
        else if (resource === 'match-sports'){
            query = {
                sort: JSON.stringify([field, order]),
                filter: JSON.stringify(params.filter),
                range: JSON.stringify([page, perPage]),
            };
            return { url: `${serverPrefix}/api/match/page?${stringify(query)}` }
        }
        else if (resource === 'sport/sort'){
            query = {
                when:params.when,
            };
        }
        else if (resource === 'event' || resource === 'session' || resource === 'team-standings'){
            const sportType = params.filter.sportType || params.filter.initialValues.sportType;
            query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([page, perPage]),
                filter: JSON.stringify(params.filter),
            };
            return { url: `${serverPrefix}/api/${resource}/${sportType}?${stringify(query)}` }
        }
        else{
            query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([page, perPage]),
                filter: JSON.stringify(params.filter),
            };
        }
        return { url: `${serverPrefix}/api/${resource}?${stringify(query)}` }
    }
    case GET_MANY:
        let query;
        if(resource === 'country/all') {
            query = {
                filter: JSON.stringify({ code: params.ids }),
            };
        }
        else {
            query = {
                filter: JSON.stringify({ id: params.ids }),
            };
            if(resource === 'event') {
                const sportType =localStorage.getItem('eventControllerPostFix');
                return {url: `${serverPrefix}/api/${resource}/${sportType}?${stringify(query)}`}
            }
        }
        return { url: `${serverPrefix}/api/${resource}?${stringify(query)}` }
    case GET_ONE:
        if(resource === 'soccer/commentary') {
            
            const query = {
                langId: localStorage.getItem('langId'),
            };
            return { url: `${serverPrefix}/api/${resource}/${params.id}?${stringify(query)}` };
        }
        else if (resource === 'event' || resource === 'session'){
            const sportType =localStorage.getItem('eventControllerPostFix');
            return { url: `${serverPrefix}/api/${resource}/${sportType}/${params.id}` };
        }
        else if (resource === 'match-sports'){
            const sportId =localStorage.getItem('defaultSportId');
            return { url: `${serverPrefix}/api/match/page/${sportId}/${params.id}` };
        }
        return { url: `${serverPrefix}/api/${resource}/${params.id}` };
    case UPDATE:
        let url;
        if (resource === 'sport/rank'){
            url = `${serverPrefix}/api/${resource}?when=${params.when}`
        }
        else if(resource === 'event' || resource === 'session') {
            const sportType =localStorage.getItem('eventControllerPostFix');
            url = `${serverPrefix}/api/${resource}/${sportType}`;
        }
        else if (resource === 'match-sports'){
            const sportId =localStorage.getItem('defaultSportId');
            url = `${serverPrefix}/api/match/page/${sportId}`;
        }
        else{
            url = `${serverPrefix}/api/${resource}`;
        }
        return {
            url: url,
            options: { method: 'PUT', body: JSON.stringify(params.data) },
        };
    case CREATE:
        return {
            url: `${serverPrefix}/api/${resource}`,
            options: { method: 'POST', body: JSON.stringify(params.data) },
        };
    case DELETE:
        if(resource === 'match-sports') {
            const defaultSportId = localStorage.getItem('defaultSportId')
            return {
                url: `${serverPrefix}/api/match/${defaultSportId}/${params.id}`,
                options: { method: 'DELETE' },
            };
        }
        else {
            throw new Error(`Unsupported fetch action type ${type} on resource ${resource}`);
        }
        break;
    case DELETE_MANY:
        if(resource === 'match-sports') {
            const defaultSportId = localStorage.getItem('defaultSportId')
            const query = {
                filter: JSON.stringify({ matchIds: params.ids }),
            };
            return {
                url: `${serverPrefix}/api/match/${defaultSportId}?${stringify(query)}`,
                options: { method: 'DELETE' },
            };
        }
        else if(resource === 'event' || resource === 'session' ) {
            const crtlPostfix = localStorage.getItem('eventControllerPostFix');
            return {
                url: `${serverPrefix}/api/${resource}/${crtlPostfix}?eventIds=${params.ids.join()}`,
                options: { method: 'DELETE' },
            }
        }
        else if(resource.includes('/commentary')) {
            let ids;
            let query;
            if(resource === 'soccer/commentary' && !isEmpty(params.ids)) {
                ids = params.ids.map(id => {
                    const idArr = id.split(',');
                    return {id: idArr[0], langId: idArr[1]}
                })
            }
            query = {
                filter: JSON.stringify({ commentaryIds: ids || params.ids }),
            };
            return {
                url: `${serverPrefix}/api/${resource}?${stringify(query)}`,
                options: { method: 'DELETE' },
            };
        }
        else {
            throw new Error(`Unsupported fetch action type ${type} on resource ${resource}`);
        }
        break;
    default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
};


const convertHTTPResponseToDataProvider = (response, type, resource, params) => {
    const { headers, json } = response;
    switch (type) {
    case GET_LIST:
        let returnData;
        switch (resource){
            case `match/${params.sport}/between`:
            case 'sport/sort':
            case 'sport/rank':
                return {
                    data: json,
                    total: parseInt(headers.get('content-range'), 10),
                    status: response.status,
                }
            case 'country':
            case 'country/all':
                returnData = json.map(resource => ({ ...resource, id: resource.code }));
                break;
            case 'user':
                returnData = json.map(resource => ({ ...resource, id: resource.emailAddress }));
                break;
            case 'sport/events':
            case 'sport/team-standings-tables':
                returnData = Object.keys(json).map((key, index) => ({...json[key], id: json[key].controllerPostfix}))
                break;
            case 'soccer/commentary':
                returnData = json.map(resource => ({ ...resource, id: `${resource.id},${resource.langId}` }));
                break;
            case 'team-standings':
                returnData = json.map(resource => ({ ...resource, id: `${resource.competitionId},${resource.teamId},${resource.seasonId},${resource.groupId},${resource.type}` }));
                break;
            case 'soccer-team-standing':
                returnData = json.map(resource => ({ ...resource, id: `${resource.competitionId}/${resource.teamId}/${resource.seasonId}` }));
                break;
            case 'officials':
                returnData = json.map(resource => ({ ...resource, id: `${resource.id}/${resource.team.id}`,
                    officialsId: `${resource.id}`}));
                break;
            default:
                returnData = json.map(x => x)
        }
        return {
            data: returnData,
            total: parseInt(headers.get('content-range'), 10),
        };
    case GET_ONE:
    case UPDATE:
        switch(resource) {
            case 'soccer-team-standing':
                return { data: JSON.parse("[" + JSON.stringify(json) + "]").map(resource => ({ ...resource, id: `${resource.competitionId}/${resource.teamId}/${resource.seasonId}` }))[0] }
            default:
                return (resource === 'country')? {data : JSON.parse("[" + JSON.stringify(json) + "]").map(resource => ({...resource, id: resource.code}))[0]} : { data: json };
        }
    case CREATE:
        return { data: { ...params.data, id: json.id } };
    case GET_MANY:
        if(resource === 'user') {
            return { data: json.map(resource => ({ ...resource, id: resource.emailAddress })) };
        }
        else if(resource === 'country/all') {
            return { data: json.map(resource => ({ ...resource, id: resource.code })) };
        }
        else if(resource === 'sport/events'|| resource === 'sport/team-standings-tables') {
            return { data: Object.keys(json).map((key, index) => ({...json[key], id: json[key].controllerPostfix})) };
        }
        return {data: json.map(x => x), total: parseInt(headers.get('content-range'), 10)}
    default:
        return { data: json };
    }
};


export default (type, resource, params) => {
    const { url, options } = convertDataProviderRequestToHTTP(type, resource, params);
    return httpClient(url, options)
        .then(response => convertHTTPResponseToDataProvider(response, type, resource, params));

};