import React, { useState, useEffect, FC, Fragment } from 'react';
import { Responsive, 
        ReferenceInput,
        usePermissions,
        useDataProvider,
        useNotify,
        BulkDeleteButton,
        BooleanField,
        TextField,
        DateField,
        Filter,
        TextInput,
        SelectInput,
        GET_LIST 
    }
from 'react-admin';
import { List } from '../../components/customs';
import { SearchInput } from '../../components/customs/Input';
import { buildRequest, checkResponse } from '../../helpers/request';
import isEmpty from 'lodash/isEmpty';
import DatagridView from './DatagridView';
import EventReferenceField from '../../events/EventReferenceField';
import StandingsGroupReferenceField from './StandingsGroupReferenceField';

const GenericField = ( record ) => {
    const fields: any = []
    const firstFields: any = []
    const secondFields: any = []
    if(!isEmpty(record)) {
        let isImageAvailableField;
        for (const [key, value] of Object.entries(record)) {
            if(key !== 'result' && key !== 'id') {
                if(key === 'competitionId' || key === 'teamId' || key === 'seasonId' || key === 'type') {
                    firstFields.push(<TextField source={key} key={key} />)
                }
                else if(key === 'groupId') {
                    firstFields.push(<StandingsGroupReferenceField record={record} />)
                }
                else if(typeof value === 'boolean') {
                    if(key === 'isImageAvailable') {
                        isImageAvailableField = <BooleanField source={key} key={key} />;
                    }
                    else {
                        secondFields.push(<BooleanField source={key} key={key} />)
                    }
                }
                else if(key === 'startDate' || key === 'endDate') {
                    secondFields.push(<DateField source={key} key={key} showTime />)
                }
                else {
                    secondFields.push(<TextField source={key} key={key} />)
                }
            }

        }
        fields.push(firstFields.concat(secondFields));
    }

    return fields;
}

interface SportParams {
    controllerPostfix?: string
}

const TeamStandingsList: FC = (props: any) => {
    const [sport, setSport] = useState<SportParams>({});
    const { permissions } = usePermissions();

    const dataProvider = useDataProvider();
    const notify = useNotify();

    useEffect(() => {
        const defaultSportId = localStorage.getItem('teamStandingsControllerPostFix');
        if(isEmpty(defaultSportId) || defaultSportId === 'undefined') {
            fetchDefaultEventSport(null);
        }
        else {
            fetchDefaultEventSport(defaultSportId);
        }
    }, []);

    const UpdateData = (sportType, props) => {
        if(!isEmpty(sportType) && typeof sportType === 'string') {
            localStorage.setItem('teamStandingsControllerPostFix', sportType);
        }
        const { resource, currentSort, page, perPage, filterValues } = props;
        const defaultFilter = { sportType: sportType };

        const filter = !isEmpty(filterValues) ? Object.assign(filterValues, defaultFilter) : defaultFilter ;

        dataProvider.getList(`${resource}`, { sort: currentSort, pagination: { page: page, perPage: perPage }, filter: filter});
    }

    const fetchDefaultEventSport = (sportType) => {
        const url = isEmpty(sportType) ? `sport/team-standings-tables/default-sport` : `sport/team-standings-tables/default-sport?sport=${sportType}`;
        const request = buildRequest(url, 'GET')
        fetch(request)
            .then(checkResponse)
            .then( (json) => {
                localStorage.setItem('teamStandingsControllerPostFix', json.controllerPostfix);
                setSport(json);
            })
            .catch(error => console.error('Error:', error));

    }

    const PostBulkActionButtons = props => (
        <Fragment>
            {/* default bulk delete action */}
            <BulkDeleteButton {...props} />
        </Fragment>
    );

    const TeamStandingsFilter = (props) => (
        <Filter {...props}>
            <ReferenceInput
                label="Sports"
                source="sportType"
                reference="sport/team-standings-tables"
                inputProps={{
                    onChange: (e) => {
                        UpdateData(e.target.value, props);
                    }
                }}
                sort={{ field: 'id', order: 'ASC' }}
                filter={{}}
                alwaysOn
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput label="Search" source="q" alwaysOn />
        </Filter>
    );

    return !isEmpty(sport) ? (
        <List {...props} filters={<TeamStandingsFilter />} sort={{ field: 'competitionId', order: 'ASC' }} filterDefaultValues={{ sportType: sport.controllerPostfix }} bulkActionButtons={false} >
            <Responsive
                medium={
                    // @ts-ignore
                    <DatagridView sportId={sport.controllerPostfix} permissions={permissions} genericField={GenericField} />
                }
            />
        </List>
    ): null;
}

export default TeamStandingsList;