import React from 'react';
import { Create, AutocompleteInput, SimpleForm, TextInput, SelectInput, CardActions, CreateButton, SaveButton, Toolbar } from 'react-admin';
import { ResourceActions, BackButton } from '../../buttons/ResourceAction';
import { DefaultToolbar } from '../DefaultToolbar';

interface Error {
    langId?: string[],
    eventType?: string[]
}

export const validate = (values) => {
    const errors: Error = {};
    if(!values.langId) {
        errors.langId = ['langId is required'];
    }
    if(!values.eventType) {
        errors.eventType = ['event type is required'];
    }
    return errors;
}

export const CommentaryCreate = (props) => (
    <Create {...props} actions={<ResourceActions />}>
        <SimpleForm toolbar={<DefaultToolbar />} validate={validate} >
            <TextInput disabled source="sessionId" />
            <TextInput source="langId" label="Lang Id"/>
            <TextInput source="lap" />
            <TextInput multiline source="message" />
            <SelectInput source="eventType" choices={[
                { id: 'comment', name: 'comment' },
                { id: 'safety_car', name: 'safety car' },
                { id: 'start', name: 'start' },
                { id: 'finish', name: 'finish' },
                { id: 'crash', name: 'crash' },
                { id: 'time_penalty', name: 'time penalty' },
                { id: 'fastest_lap', name: 'fastest lap' },
                { id: 'pitstop', name: 'pitstop' }
            ]} />
        </SimpleForm>
    </Create>
);