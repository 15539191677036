import React, { PureComponent } from 'react'
import { Text, Row, Button, Column } from '@sport1/universal-components'
import moment from 'moment'
import 'moment/locale/de'

const defaultProps = {
    weekVisible: 0,
    // Num of days visible in calendar (default = 7)
    numDaysVisible: [...Array(7).keys()],
    // Starting day (-3 means three days ago)
    startingDay: moment().diff(moment().startOf('week'), 'days') * -1,
}

type DatesProps = {
    week: any,
    setDate: any,
    selectedDate: any
}

export default class Dates extends PureComponent<DatesProps> {
    setDate = date =>
        moment()
            .add(date, 'days')
            .format('dd DD MMM')

    compareDates = (date1, date2) =>
        JSON.stringify(this.calculateDay(date1)) === JSON.stringify(date2)

    formatNewDate = date => {
        const day = this.setDate(date)
        return day.split(' ')
    }

    calculateDay = visibleDay =>
        this.formatNewDate(visibleDay + defaultProps.startingDay + this.props.week)

    format = visibleDay =>
        moment()
            .add(visibleDay + defaultProps.startingDay + this.props.week, 'days')
            .format('YYYY-MM-DD')

    textColor = date => {
        const today = this.formatNewDate(this.setDate(0))
        if (this.compareDates(date, today)) {
            return 'highlight.main'
        }
        return 'font.main'
    }

    render() {
        const { numDaysVisible } = defaultProps
        const { selectedDate } = this.props

        return (
            <Row flexing>
                {numDaysVisible.map(visibleDay => (
                    <Column flexing key={visibleDay} >
                        <Button
                            onPress={() => this.props.setDate(this.format(visibleDay))}
                            bold
                            customStyle
                            textColor="font.main"
                            bg="background.main"
                            key={visibleDay}
                        >
                            <Column
                                textAlign="center"
                                px={1}
                                borderBottom={this.format(visibleDay) === selectedDate ? 2 : 0}
                                borderColor={this.textColor(visibleDay)}
                            >
                                <Text
                                    color={this.textColor(visibleDay)}
                                    bold={this.format(visibleDay) === selectedDate}
                                >
                                    {this.calculateDay(visibleDay)[0]}
                                </Text>
                                <Text
                                    color={this.textColor(visibleDay)}
                                    bold={this.format(visibleDay) === selectedDate}
                                >
                                    {`${this.calculateDay(visibleDay)[1]}.`}
                                </Text>
                                <Text
                                    color={this.textColor(visibleDay)}
                                    size="small"
                                    bold={this.format(visibleDay) === selectedDate}
                                >
                                    {this.calculateDay(visibleDay)[2]}
                                </Text>
                            </Column>
                        </Button>
                    </Column>
                ))}
            </Row>
        )
    }
}
