import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Responsive, ReferenceInput, WithPermissions, Filter, TextInput } from 'react-admin';
import { List } from '../components/customs';
import DatagridView from './DatagridView';
import { buildRequest, checkResponse } from '../helpers/request';
import isEmpty from 'lodash/isEmpty';
import { GET_LIST, showNotification, withDataProvider } from 'react-admin';
import { SearchInput, SelectInput } from '../components/customs/Input';
import { BulkDeleteButton } from 'react-admin';
import {isAdmin} from "../permissions";


class EventListBase extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sport: {},
        }

    }

    componentDidMount() {
        const defaultSportId = localStorage.getItem('eventControllerPostFix');
        if(isEmpty(defaultSportId) || defaultSportId === 'undefined') {
            this.fetchDefaultEventSport();
        }
        else {
            this.fetchDefaultEventSport(defaultSportId);
        }
    }

    fetchDefaultEventSport = (sportType) => {
        const url = isEmpty(sportType) ? `sport/events/default-sport` : `sport/events/default-sport?sport=${sportType}`;
        const request = buildRequest(url, 'GET')
        fetch(request)
            .then(checkResponse)
            .then( (json) => {
                localStorage.setItem('eventControllerPostFix', json.controllerPostfix);
                this.setState({
                    sport: json,
                });
            })
            .catch(error => console.error('Error:', error));

    }

    updateData(sportType, props) {
        localStorage.setItem('eventControllerPostFix', sportType);
        const { resource, currentSort, page, perPage, filterValues } = props.resource;
        const defaultFilter = { sportType: sportType };

        const filter = !isEmpty(filterValues) ? Object.assign(filterValues, defaultFilter) : defaultFilter ;

        const { dataProvider } = this.props;
        dataProvider(GET_LIST, resource, { sort: currentSort, pagination: { page: page, perPage: perPage }, filter: filter })
            .catch((e) => {
                showNotification('Error: failed to fetch', 'error');
            });
    }

    PostBulkActionButtons = props => (
        <Fragment>
            {/* default bulk delete action */}
            <BulkDeleteButton {...props} />
        </Fragment>
    );

    EventFilter = (props) => (
        <Filter {...props}>
            <ReferenceInput                  
                label="Sports"
                source="sportType"
                reference="sport/events"
                inputProps={{
                    onChange: (e) => {
                        this.updateData(e.target.value, props);
                    }
                }}
                sort={{ field: 'id', order: 'ASC' }}
                filter={{}}
                alwaysOn
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <SearchInput source="q" alwaysOn />
            <TextInput label="Name DE" source="nameDE" />
        </Filter>
    );

    render() {
        const { sport } = this.state;
        const { permissions } = this.props;

        return !isEmpty(sport) ? (
            <List {...this.props} filters={<this.EventFilter />} filterDefaultValues={{ sportType: sport.controllerPostfix }}
                  bulkActionButtons={isAdmin(permissions) && <this.PostBulkActionButtons />} >
                <Responsive
                    medium={
                        <DatagridView sportId={sport.controllerPostfix} permissions={permissions} />
                    }
                />
            </List>
        ): null;
    }
}

const EventList = (props) => (
    <WithPermissions
        authParams={{ key: props.match.path, params: props.match.params }}
        location={props.location}
        render={({ permissions }) => <EventListBase permissions={permissions} {...props} /> }
    />
);

EventListBase.propTypes = {
   dataProvider: PropTypes.func.isRequired,
   dispatch: PropTypes.func.isRequired,
};

export default withDataProvider(EventList);