import React, { Children } from 'react';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { LoadingIndicator } from 'react-admin';
import Hidden from '@material-ui/core/Hidden';
import { Logout } from '../components/customs/actions';
import Logo from './Logo';


const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: 'none',
    },
    toolbar: {
        paddingRight: 24,
    },
    menuButton: {
        marginLeft: '0.5em',
        marginRight: '0.5em',
    },
    menuButtonIconClosed: {
        transition: theme.transitions.create(['transform'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        transform: 'rotate(0deg)',
    },
    menuButtonIconOpen: {
        transition: theme.transitions.create(['transform'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        transform: 'rotate(180deg)',
    },
    title: {
        flex: 1,
        fontSize: 'x-large',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    logo: {
        flex: 1.2,
    }
}));

const AppBar = ({
     children,
     className,
     logo,
     logout,
     open,
     title,
     toggleSidebar,
     userMenu,
     width,
     onSidebarOpen,
     ...rest
}) => {
    const classes = useStyles();

    return (
        <MuiAppBar
            className={classes.root}
            {...rest}
        >
            <Toolbar

                className={classes.toolbar}
            >
                {Children.count(children) === 0 ? (
                    <Typography
                        variant="h6"
                        color="inherit"
                        className={classes.title}
                        id="react-admin-title"
                    />
                ) : (
                    children
                )}
                <div className={classes.logo}>
                    <Logo />
                </div>
                <LoadingIndicator />
                <Hidden mdDown>
                  <Logout />
                </Hidden>
                <Hidden lgUp>
                  <IconButton
                    color="inherit"
                    onClick={onSidebarOpen}
                  >
                    <MenuIcon />
                  </IconButton>
                </Hidden>
            </Toolbar>
        </MuiAppBar>
    )
}

export default AppBar;