import React from 'react';
import { TextField, EditButton, ImageField, Responsive, BooleanField, Filter, SearchInput } from 'react-admin';
import { List, Datagrid } from '../components/customs';
import MobileGrid from './MobileGrid';
import { isAdmin, isEditor } from '../permissions';
import { LogoField } from '../helpers/utils';
//import { SearchInput } from '../components/customs/Input';
import { PurgeButton } from '../components/buttons';

const TeamFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

const TeamList = ({permissions, ...props}) => (
    <List {...props} filters={<TeamFilter />} bulkActionButtons={false}>
        <Responsive
            medium={
                <Datagrid>
                    <LogoField />
                    <TextField source="id" />
                    <TextField source="name" />
                    <TextField source="code" />
                    <TextField source="country" />
                    <TextField source="countryCode" />
                    <TextField source="city" />
                    <TextField source="founded" />
                    <TextField source="postalCode" />
                    <TextField source="regionName" />
                    <TextField source="shortName" />
                    <TextField source="street" />
                    <TextField source="webAddress" />
                    <TextField source="nameDE" label="Name DE"/>
                    <TextField source="shortNameDE" label="Short name DE"/>
                    <BooleanField source="isImageAvailable" />
                    {isAdmin(permissions) && <PurgeButton />}
                    {(isAdmin(permissions) || isEditor(permissions)) && <EditButton />}
                </Datagrid>
            }
        />
    </List>
);

export default TeamList;