import React from 'react';
import { TextField, EditButton, Responsive, BooleanField, Filter } from 'react-admin';
import { List, Datagrid } from '../components/customs';
import MobileGrid from './MobileGrid';
import {isAdmin, isEditor} from '../permissions';
import { SearchInput } from '../components/customs/Input';
import { PurgeButton } from '../components/buttons';

const RefereeFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

export const RefereeList = ({permissions, ...props}) => (
    <List {...props} filters={<RefereeFilter />} bulkActionButtons={false}>
        <Responsive
            medium={
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="firstName" />
                    <TextField source="lastName" />
                    <TextField source="birthDay" />
                    <TextField source="nickName" />
                    <TextField source="birthPlace" />
                    <TextField source="country" />
                    <BooleanField source="isImageAvailable" />
                    {(isAdmin(permissions) || isEditor(permissions)) && <PurgeButton />}
                    {(isAdmin(permissions) || isEditor(permissions)) && <EditButton />}
                </Datagrid>
            }
        />
    </List>
);