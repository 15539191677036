import React, { Fragment, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ReactCrop from 'react-image-crop';
import IconCancel from '@material-ui/icons/Cancel';
import IconSave from '@material-ui/icons/Save';
import { Button } from 'react-admin';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

export const CropComponent = props => {
    const { src, crop, onImageLoaded, onCropComplete, onCropChange, action } = props;
    return (
        <ReactCrop
            src={src}
            crop={crop}
            onImageLoaded={onImageLoaded}
            onComplete={onCropComplete}
            onChange={onCropChange}
            keepSelection={action === 'focusPoint'}
            locked={action === 'focusPoint'}
            circularCrop={action === 'focusPoint'}
        />
    );
}

export const PreviewComponent = props => {
    const { action, urlPreview, urlPreview1, urlPreview2 } = props;
    return (
        <List>
            <li>
                <Typography
                  color="textSecondary"
                  display="block"
                  variant="caption"
                >
                  {action === 'crop' ? 'Preview' : 'Previews (50x50  image)'}.
                </Typography>
            </li>
            <Divider component="li" />
                {action === 'crop'?
                    <ListItem>
                        <Box m={1}>
                            <Badge
                              overlap="circle"
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                              }}
                            >
                              <img src={urlPreview} alt='' />
                            </Badge>
                        </Box>
                    </ListItem>
                    :
                    <ListItem>
                        <Box m={1}>
                            <Badge
                              overlap="circle"
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                              }}
                            >
                              <img src={urlPreview1} alt='' />
                            </Badge>
                        </Box>
                        <Box m={1}>
                            <Badge
                              overlap="circle"
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                              }}
                            >
                              <img src={urlPreview2} alt='' />
                            </Badge>
                        </Box>
                    </ListItem>
                }
            <Divider component="li" />
        </List>
    );
}

export const CropActions = props => {
    const { translate, saveEditImage, handleEditClose } = props;
    return (
        <div>
            <Button label={translate('pos.getty.dialog.image.edit.accept')} onClick={saveEditImage}>
                <IconSave />
            </Button>

            <Button label="ra.action.cancel" onClick={handleEditClose}>
                <IconCancel />
            </Button>
        </div>
    );
}

export const CropToolbar = props => {
    const { translate, action, handleChange, handleImgEditActionChange } = props;

    return (
        <div>
            <FormControl component="fieldset" >
                <RadioGroup aria-label="action" name="action" value={action} onChange={handleChange} style={{display: 'inline'}} >
                    <FormControlLabel
                        value="crop"
                        control={<Radio />}
                        label="Crop"
                    />
                    <FormControlLabel
                        value="focusPoint"
                        control={<Radio />}
                        label="Focus Point"
                    />
                </RadioGroup>
            </FormControl>
            <Divider component="div" />
        </div>
    );
}

const EditBox = (props) => {
    const { translate, src, crop, action, handleActionChange, onImageLoaded, onCropComplete, onCropChange, saveEditImage, handleEditClose, urlPreview1, urlPreview2,
            urlPreview, classes, ...rest } = props;

    return (
        <Fragment>
            <Grid container spacing={1}>
                <Grid container item xs={12} spacing={3}>
                    <Grid item xs={12}>
                        <div className={classes.editToolbar} >
                            <CropToolbar translate={translate} action={action} handleChange={handleActionChange} />
                        </div>
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={3}>
                    <Grid item xs={12}>
                        <div className={classes.imgCropper}>
                            { src &&
                                <CropComponent
                                    src={src}
                                    crop={crop}
                                    onImageLoaded={onImageLoaded}
                                    onCropComplete={onCropComplete}
                                    onCropChange={onCropChange}
                                    action={action}
                                />
                            }
                        </div>
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={3}>
                    <Grid item xs={12}>
                        <div className={classes.preview} >
                            <PreviewComponent action={action} urlPreview={urlPreview} urlPreview1={urlPreview1} urlPreview2={urlPreview2} />
                        </div>
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={3}>
                    <Grid item xs={12}>
                        <div className={classes.editToolbar} >
                            <CropActions translate={translate} saveEditImage={saveEditImage} handleEditClose={handleEditClose} />
                        </div>
                    </Grid>
                </Grid>

            </Grid>
        </Fragment>
    );
}

export default EditBox;
