import React, { FC, isValidElement, Children, cloneElement } from 'react';
import { BooleanField, TextField, ImageField, DateField, EditButton } from 'react-admin';
import { Datagrid } from '../../components/customs';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import SeasonReferenceField from '../../seasons/SeasonReferenceField';
import SportReferenceField from '../../sports/SportReferenceField';
import CompetitionReferenceField from '../../competitions/CompetitionReferenceField';
import { isAdmin } from '../../permissions';

const GenericField = ( record ) => {
    const fields: any = []
    if(!isEmpty(record)) {
        let isImageAvailableField;
        for (const [key, value] of Object.entries(record)) {
            if(typeof value === 'boolean') {
                if(key === 'isImageAvailable') {
                    isImageAvailableField = <BooleanField source={key} key={key} />;
                }
                else {
                    fields.push(<BooleanField source={key} key={key} />)
                }
            }
            else if(key === 'startDate' || key === 'endDate') {
                fields.push(<DateField source={key} key={key} showTime />)
            }
            else {
                fields.push(<TextField source={key} key={key} />)
            }

        }
    }

    return fields;
}

const DatagridView: FC = (props: any) => {
    const { permissions, data, ids, genericField=GenericField } = props;
    return (
        <Datagrid {...props} sportId={props.sportId}>
            {genericField(data[ids[0]])}
        </Datagrid>
    )
}

export default DatagridView;