import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import compose from 'recompose/compose';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
  formControl: {
    margin: 1,
    minWidth: 250,
    minHeight: 100,
    height: '170px',
  },
};

const ResourceSelect = (data) => {
    const {classes, resources, selectedResource, handleChange} = data;
    return (
        <FormControl className={classes.formControl}>
            <select
              value={selectedResource}
              native
              onChange={handleChange}
              inputProps={{
                id: 'select-native',
              }}
              size={10}
            >
              <option selected disabled>Resources</option>
              {resources.map(resource => (
                <option key={resource} value={resource}>
                  {resource}
                </option>
              ))}
            </select>
        </FormControl>
    )
};

export default compose(
    withStyles(styles)
)(ResourceSelect);