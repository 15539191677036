import React, { FC, PureComponent, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import Drawer from '@material-ui/core/Drawer';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

export const DRAWER_WIDTH = 240;
export const CLOSED_DRAWER_WIDTH = 55;

const useStyles = makeStyles(theme => ({
    drawer: {
        width: 239,
        [theme.breakpoints.up('lg')]: {
          marginTop: 64,
          height: 'calc(100% - 64px)'
        }
    },
    root: {
        backgroundColor: theme.palette.primary.contrastText,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: theme.spacing(2)
    },
}))

// We shouldn't need PureComponent here as it's connected
// but for some reason it keeps rendering even though mapStateToProps returns the same object
/*class Sidebar extends PureComponent {

    render() {
        const {
            children,
            classes,
            closedSize,
            open,
            size,
            width,
            className,
            variant,
            onClose,
            ...rest
        } = this.props;

        return (
            <Drawer
                variant={variant}
                anchor="left"
                open={open}
                classes={{ paper: classes.drawer }}
                onClose={onClose}
                {...rest}
            >
                <div
                    {...rest}
                    className={classes.root}
                >
                    {cloneElement(Children.only(children), { dense: true })}
                </div>
            </Drawer>
        );
    }
}

Sidebar.propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.object,
    open: PropTypes.bool.isRequired,
    size: PropTypes.number,
    width: PropTypes.string,
};

Sidebar.defaultProps = {
    size: DRAWER_WIDTH,
    closedSize: CLOSED_DRAWER_WIDTH,
};

export default compose(
    withStyles(styles),
    withWidth({ resizeInterval: Infinity }),
)(Sidebar);*/

const SideBar: FC = (props: any) => {
    const classes = useStyles();
    const {
        children,
        closedSize,
        open,
        size,
        width,
        className,
        variant,
        onClose,
        ...rest} = props;
    return (
        <Drawer
            variant={variant}
            anchor="left"
            open={open}
            classes={{ paper: classes.drawer }}
            onClose={onClose}
            {...rest}
        >
            <div
                {...rest}
                className={classes.root}
            >
                {cloneElement(Children.only(children), { dense: true })}
            </div>
        </Drawer>
    )
}

export default withWidth({ resizeInterval: Infinity })(SideBar);