import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import withStyles from '@material-ui/core/styles/withStyles';
import { Grid } from '@material-ui/core';

const styles = theme => ({
    root: {
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(3.5),
    },
});

const SportAdminPage = props => {
    const { classes, children } = props;
    return(
        <div className={classes.root}>
            <Grid
                container
                spacing={1}
            >
                <Grid container item xs={12} >
                    <Grid
                      item
                      xs={12}
                    >
                        {children}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default withStyles(styles)(SportAdminPage);
