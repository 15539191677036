import React from 'react';
import { TextField, EditButton, Responsive, Filter } from 'react-admin';
import { List, Datagrid } from '../../components/customs';
import { isAdmin, isEditor } from '../../permissions';
import { SearchInput } from '../../components/customs/Input';
import SportReferenceField from '../../sports/SportReferenceField';

const StandingsGroupFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
);

const StandingsGroupList = ({ permissions, ...props }) => (
    <List {...props} filters={<StandingsGroupFilter />} bulkActionButtons={false}>
        <Responsive
            medium={
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="parentId" label="Parent Id"/>
                    <TextField source="groupName"/>
                    <TextField source="groupNameDE" label="groupName DE"/>
                    <TextField source="name"/>
                    <TextField source="nameDE" label="Name DE"/>
                    <SportReferenceField />
                    <TextField source="type"/>
                    <TextField source="phase"/>
                    {(isAdmin(permissions) || isEditor(permissions)) && <EditButton />}
                </Datagrid>
            }
        />
    </List>
);

export default StandingsGroupList;