import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual } from 'recompose';
import Dropzone from 'react-dropzone';
import compose from 'recompose/compose';
import withStyles from '@material-ui/core/styles/withStyles';
import classnames from 'classnames';
import { addField, translate } from 'ra-core';

import Labeled from './Labeled';
import FileInputPreview from './FileInputPreview';
import sanitizeRestProps from './sanitizeRestProps';
import GettyImageButton from '../buttons/GettyImageButton';
import { FileInput as DefaultFileInput } from 'react-admin';

import { fetchConfig, ImgEditComponent, getComputedNum, getComputedImageWidth, getComputedImageHeight, getFinalImgUrl,
            getCropFinalImgUrl, fetchFocalPoints, DefaultLoader, fetchCropFocalPoints, fetchCropImagePreview } from '../buttons/GettyImageButton';

import { buildRequest, buildMultipartRequest, checkResponse, saveTempFile } from '../helpers/request';

import { API_URL } from '../helpers/consts';
import { get } from 'lodash';

const styles = {
    dropZone: {
        background: '#efefef',
        cursor: 'pointer',
        padding: '1rem',
        textAlign: 'center',
        color: '#999',
    },
    preview: {},
    removeButton: {},
    root: { width: '100%' },
};

const getSearchTerm = (resource, record) => {
    switch (resource) {
        case 'player':
        case 'officials':
        case 'referee':
            return `${record.firstName} ${record.lastName}`
        case 'competition':
        case 'team':
        case 'group':
        case 'sport':
            return record.name;
        case 'country':
            return record.en;
        default:
            return '';
    }
}

export class FileInput extends Component {
    static propTypes = {
        accept: PropTypes.string,
        children: PropTypes.element,
        classes: PropTypes.object,
        className: PropTypes.string,
        disableClick: PropTypes.bool,
        id: PropTypes.string,
        input: PropTypes.object,
        isRequired: PropTypes.bool,
        label: PropTypes.string,
        labelMultiple: PropTypes.string,
        labelSingle: PropTypes.string,
        maxSize: PropTypes.number,
        minSize: PropTypes.number,
        multiple: PropTypes.bool,
        options: PropTypes.object,
        resource: PropTypes.string,
        source: PropTypes.string,
        translate: PropTypes.func.isRequired,
        placeholder: PropTypes.node,
    };

    static defaultProps = {
        labelMultiple: 'ra.input.file.upload_several',
        labelSingle: 'ra.input.file.upload_single',
        multiple: false,
        onUpload: () => {},
    };

    constructor(props) {
        super(props);
        let files = props.input.value || [];
        if (!Array.isArray(files)) {
            files = [files];
        }

        this.state = {
            files: files.map(this.transformFile),
            imageSrc: null,
            showEditImageDialog: false,
            mainLoading: false,
            crop: {
              width: 20,
              height: 20,
              x: 0,
              y: 0,
            },
            action: 'crop'
        };
        this.canvas = null;
        this.setCanvasRef = element => {
            this.canvas = element;
        }
    }

    componentWillReceiveProps(nextProps) {
        let files = nextProps.input.value || [];
        if (!Array.isArray(files)) {
            files = [files];
        }

        this.setState({ files: files.map(this.transformFile) });
    }

    componentDidMount() {
        fetchConfig()
            .then(json => this.setState({ imageServiceURL: json.thumborInternalUrl, reshapeUrl: json.thumborUrl, config: json }))
            .catch(error => console.error('Failed to fetch config from server: ', error));
    }

    onDrop = files => {
        this.fetchImageAsBlob(files[0].preview);
        this.setState({ mainLoading: true });
        var img = new Image();
        const parent = this;
        const { imageServiceURL } = this.state;
        const file = files[0];
        img.addEventListener('load', function () {
            parent.setState({ imgOriginalWidth:this.width, imgOriginalHeight:this.height, imageSrc: this.src});

            saveTempFile(file)
                .then(location => {
                    const src = location;
                    parent.setState({ src, tempFile: file });
                    fetchFocalPoints(imageServiceURL, file.name)
                        .then(data => {
                            parent.setState({ showEditImageDialog: true, mainLoading: false, imgOriginalWidth: data.imgOriginalWidth, imgOriginalHeight: data.imgOriginalHeight, crop: data.crop })
                        })
                        .catch(error => {
                            parent.setState({ mainLoading: false });
                            parent.removeTempFile(file);
                            console.error('Failed to fetch focal points: ', error)
                        });

                })
                .catch(error => {
                    console.error('Failed to save temp image: ', error);
                    parent.setState({ mainLoading: false });
                });
        }, false);
        img.src = URL.createObjectURL(file);

    };

    removeTempFile = file =>  {
        let form = new FormData();
        form.set('file', file);
        const request = buildMultipartRequest(`images/tmp`, 'DELETE', form);

        fetch(request)
            .then(response => {
                if(response.status === 401 || response.status === 403){
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                     window.location = localStorage.getItem('logoutUrl');
                }
            })
            .catch(error => console.error(error));
    };

    onImageLoaded = (image) => {
        this.onCropComplete(this.state.crop);
        this.imageRef = image;
    };

    onCropChange = crop => {
        this.setState({ crop });
    };

    onCropComplete = (crop, percentCrop) => {
        const {imgOriginalWidth, imgOriginalHeight, action, src, tempFile} = this.state;
        const imgComputedWidth = getComputedImageWidth(imgOriginalWidth, crop.width);
        const imgComputedHeight = getComputedImageHeight(imgOriginalHeight, crop.height);
        this.setState({ percentCrop, imgComputedWidth, imgComputedHeight });
        this.computeImgBox(action, tempFile.name, crop, percentCrop, imgOriginalWidth, imgOriginalHeight);
    };

    computeImgBox = (action, fileName, crop, percentCrop, totalWidth, totalHeight) => {
        const { config } = this.state;
        const { resource } = this.props;
        const left = Math.round(getComputedNum(percentCrop && percentCrop.x || crop.x, totalWidth));
        const right = Math.round(left + getComputedNum(percentCrop && percentCrop.width || crop.width, totalWidth));
        const top = Math.round(getComputedNum(percentCrop && percentCrop.y || crop.y, totalHeight));
        const bottom = Math.round(top + getComputedNum(percentCrop && percentCrop.height || crop.height, totalHeight));

        if(action === 'crop') {
            fetchCropFocalPoints(`${left}x${top}:${right}x${bottom}`, fileName)
               .then(json => {
                    const targetAspectRatio = json.thumbor.target.width / json.thumbor.target.height;
                    const previewHeight = Math.round(200/targetAspectRatio);
                    const imageType = get(config, `resourceData[${resource}].imageType`) || 'jpg';
                    fetchCropImagePreview(`${left}x${top}:${right}x${bottom}/200x${previewHeight}`, fileName, imageType)
                        .then(imgPreview => {
                            this.setState({ urlPreview: imgPreview })
                        })
                        .catch(error => console.log(error));
                    this.setState({ targetAspectRatio:targetAspectRatio })
                })
               .catch(error => console.error('Failed to fetch focal points for crop: ', error));
        }

        if(action == 'focusPoint') {
            const { reshapeUrl, src } = this.state;
            const focusPointPreview1 = `${reshapeUrl}50x50/filters:focal(${left}x${top}:${right}x${bottom})/${src}`;
            const focusPointPreview2 = `${reshapeUrl}50x50/filters:focal(${left}x${top}:${right}x${bottom}):round_corner(255,255,255,255)/${src}`;
            this.setState({ focusPointPreview1, focusPointPreview2 })
        }

        this.setState({ box:{left, top, right, bottom}})
    }

    saveEditImage = () => {
        const { imageServiceURL, imgComputedWidth, imgComputedHeight, imgOriginalWidth, imgOriginalHeight, box, src, config, action, tempFile } = this.state;
        const { resource } = this.props;
        let finalImageUrl;
        if(action === 'crop') {
            const operation = `${box.left}x${box.top}:${box.right}x${box.bottom}`;
            const urlPostfix = get(config, `resourceData[${resource}].imageType`) || 'jpg';
            finalImageUrl = `${API_URL}/api/thumbor/image/crop/preview/${urlPostfix}?operation=${operation}&fileName=${tempFile.name}`;
        }
        else{
            //default is focus point
            finalImageUrl = getFinalImgUrl(imageServiceURL, imgComputedWidth, imgComputedHeight, imgOriginalWidth, imgOriginalHeight, box, src, config, resource)
        }

        fetch(finalImageUrl)
            .then(response => response.blob())
            .then(blob => {
                const croppedImageUrl = blob;
                var files = [];
                var imageExt = this.getImageExt(croppedImageUrl.type);
                //Generate random number to force image upload updates
                //var uniq = Math.random();
                var imageFile = new File([croppedImageUrl], `${tempFile.name}`);

                const preview = URL.createObjectURL(croppedImageUrl);
                imageFile.preview = preview;

                files.push(imageFile);
                const updatedFiles = this.props.multiple
                    ? [...this.state.files, ...files.map(this.transformFile)]
                    : [...files.map(this.transformFile)];

                this.setState({ files: updatedFiles, showEditImageDialog: false });

                if (this.props.multiple) {
                    this.props.input.onChange(updatedFiles);
                } else {
                    this.props.input.onChange(updatedFiles[0]);
                }
            })
            .catch(error => console.error(error));

    }

    onRemove = file => () => {
        const filteredFiles = this.state.files.filter(
            stateFile => !shallowEqual(stateFile, file)
        );

        this.setState({ files: filteredFiles });

        if (this.props.multiple) {
            this.props.input.onChange(filteredFiles);
        } else {
            this.props.input.onChange(null);
        }
    };

    onSelectImage = (src, id) => {
        this.downloadImage(src, id);
    }

    handleEditImageCloseClick = () => {
        const { tempFile } = this.state;
        if(tempFile) {
            this.removeTempFile(tempFile);
            this.setState({ tempFile: null });
        }
        this.setState({ showEditImageDialog: false });
    }

    downloadImage = (blob, id) => {
         var fileArr = [];

         var ext = this.getImageExt(blob.type);
         var imageFile = new File([blob], `${id}.${ext}`);

         const preview = URL.createObjectURL(blob);
         imageFile.preview = preview;

         fileArr.push(imageFile);
         const newFile = fileArr.map(this.transformFile)

         this.setState({ files: newFile });

         this.props.input.onChange(newFile[0]);
    }

    fetchImageAsBlob = (src) => {
          const request = buildRequest('config/thumbor', 'GET');

          fetch(request)
              .then(checkResponse)
              .then(json => this.setState({ imageServiceURL: json.thumborUrl, config: json }))
              .catch(error => console.error('Could not fetch image service url:', error));
    }

    getImageExt = (type) => {
        var ext = type.split("/")[1];
        if (ext === 'jpeg'){
            return 'jpg';
        }
        return ext;
    }

    // turn a browser dropped file structure into expected structure
    transformFile = file => {
        if (!(file instanceof File)) {
            return file;
        }

        const { source, title } = React.Children.toArray(
            this.props.children
        )[0].props;

        const transformedFile = {
            rawFile: file,
            [source]: file.preview,
        };

        if (title) {
            transformedFile[title] = file.name;
        }

        return transformedFile;
    };

    setAction = action => {
        this.setState({ action: action })
    }

    handleActionChange = (event) => {
        const {imgOriginalWidth, imgOriginalHeight, crop, percentCrop, tempFile} = this.state;
        this.computeImgBox(event.target.value, tempFile.name, crop, percentCrop, imgOriginalWidth, imgOriginalHeight);
        this.setAction(event.target.value);
    }

    render() {
        const {
            accept,
            children,
            classes = {},
            className,
            disableClick,
            id,
            isRequired,
            label,
            maxSize,
            minSize,
            multiple,
            resource,
            source,
            translate,
            options = {},
            record,
            ...rest
        } = this.props;

        const { imageServiceURL, imageSrc, src, crop, action, box, showEditImageDialog, mainLoading, targetAspectRatio, urlPreview, focusPointPreview1, focusPointPreview2 } = this.state;

        var searchTerm = getSearchTerm(resource, record);

        return resource === 'team' && !record.shouldUpdateLogo ? null : (
            <div>
                <DefaultFileInput {...this.props} options={{onDrop:this.onDrop}} />
                <DefaultLoader mainLoading={mainLoading} />
                <ImgEditComponent imageServiceURL={imageServiceURL} handleEditImageCloseClick={this.handleEditImageCloseClick} showEditImageDialog={showEditImageDialog} imageSrc={imageSrc} src={src} crop={crop} box={box} onImageLoaded={this.onImageLoaded}
                    onCropComplete={this.onCropComplete} onCropChange={this.onCropChange} saveEditImage={this.saveEditImage} action={action}
                    handleActionChange={this.handleActionChange} targetAspectRatio={targetAspectRatio} urlPreview={urlPreview} focusPointPreview1={focusPointPreview1}
                        focusPointPreview2={focusPointPreview2} />
                <GettyImageButton searchPhrase={searchTerm} resource={resource} onSelectImage={this.onSelectImage} />
                
            </div>    
        );
    }
}

export default compose(
    addField,
    translate,
    withStyles(styles)
)(FileInput);

/*const FileInput = ({
    accept,
    children,
    className,
    classes: classesOverride,
    format,
    helperText,
    label,
    labelMultiple = 'ra.input.file.upload_several',
    labelSingle = 'ra.input.file.upload_single',
    maxSize,
    minSize,
    multiple = false,
    options: { inputProps: inputPropsOptions, ...options } = {},
    parse,
    placeholder,
    resource,
    source,
    record,
    validate,
    ...rest
}) => {

    const [imageSrc, setImageSrc] = useState(null);
    const [showEditImageDialog, setShowEditImageDialog] = useState(false);
    const [mainLoading, setMainLoading] = useState(false);
    const initialCrop = {
        width: 20,
        height: 20,
        x: 0,
        y: 0,
    }
    const [imageServiceURL, setImageServiceURL] = useState('crop');
    const [reshapeUrl, setReshapeUrl] = useState('crop');
    const [config, setConfig] = useState('crop');
    const [crop, setCrop] = useState(initialCrop);
    const [action, setAction] = useState('crop');
    const [src, setSrc] = useState('crop');
    const [box, setBox] = useState('crop');
    const [targetAspectRatio, setTargetAspectRatio] = useState('crop');
    const [urlPreview, setUrlPreview] = useState('crop');
    const [focusPointPreview1, setFocusPointPreview1] = useState('crop');
    const [focusPointPreview2, setFocusPointPreview2] = useState('crop');
    const [percentCrop, setPercentCrop] = useState('crop');
    const [tempFile, setTempFile] = useState('crop');
    const [imgOriginalWidth, setImgOriginalWidth] = useState('crop');
    const [imgOriginalHeight, setImgOriginalHeight] = useState('crop');
    const [imgComputedWidth, setImgComputedWidth] = useState('crop');
    const [imgComputedHeight, setImgComputedHeight] = useState('crop');

    const {
        id,
        input: { onChange, value, ...inputProps },
        meta,
        isRequired,
    } = useInput({
        format: format || transformFiles,
        parse: parse || transformFiles,
        source,
        type: 'file',
        validate,
        ...rest,
    });
    const { touched, error } = meta;
    const files = value ? (Array.isArray(value) ? value : [value]) : [];

    useEffect(() => {
        fetchConfig()
            .then(json => {
                setImageServiceURL(json.thumborInternalUrl);
                setReshapeUrl(json.thumborUrl);
                setConfig(json);
            })
            .catch(error => console.error('Failed to fetch config from server: ', error));
    }, [])

    const onDrop = files => {
        fetchImageAsBlob(files[0].preview);
        setMainLoading(true);
        var img = new Image();
        const parent = this;
        const file = files[0];
        img.addEventListener('load', function () {
            parent.setState({ imgOriginalWidth:this.width, imgOriginalHeight:this.height, imageSrc: this.src});

            saveTempFile(file)
                .then(location => {
                    const src = location;
                    parent.setSrc(src);
                    parent.setTempFile(file);
                    fetchFocalPoints(imageServiceURL, file.name)
                        .then(data => {
                            parent.setShowEditImageDialog(true);
                            parent.setMainLoading(false);
                            parent.setImgOriginalWidth(data.imgOriginalWidth);
                            parent.setImgOriginalHeight(data.imgOriginalHeight);
                            parent.setCrop(data.crop);
                        })
                        .catch(error => {
                            parent.setMainLoading(false);
                            parent.removeTempFile(file);
                            console.error('Failed to fetch focal points: ', error)
                        });

                })
                .catch(error => {
                    console.error('Failed to save temp image: ', error);
                    parent.setState({ mainLoading: false });
                });
        }, false);
        img.src = URL.createObjectURL(file);

    };

    const removeTempFile = file =>  {
        let form = new FormData();
        form.set('file', file);
        const request = buildMultipartRequest(`images/tmp`, 'DELETE', form);

        fetch(request)
            .then(response => {
                if(response.status === 401 || response.status === 403){
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                     window.location = localStorage.getItem('logoutUrl');
                }
            })
            .catch(error => console.error(error));
    };

    const onImageLoaded = (image) => {
        onCropComplete(crop);
        imageRef = image;
    };

    const onCropChange = crop => {
        setCrop(crop);
    };

    const onCropComplete = (crop, percentCrop) => {
        const {imgOriginalWidth, imgOriginalHeight, action, src, tempFile} = this.state;
        const imgComputedWidth = getComputedImageWidth(imgOriginalWidth, crop.width);
        const imgComputedHeight = getComputedImageHeight(imgOriginalHeight, crop.height);
        setPercentCrop(percentCrop);
        setImgComputedWidth(imgComputedWidth);
        setImgComputedHeight(imgComputedHeight);
        this.computeImgBox(action, tempFile.name, crop, percentCrop, imgOriginalWidth, imgOriginalHeight);
    };

    const computeImgBox = (action, fileName, crop, percentCrop, totalWidth, totalHeight) => {
        const left = Math.round(getComputedNum(percentCrop && percentCrop.x || crop.x, totalWidth));
        const right = Math.round(left + getComputedNum(percentCrop && percentCrop.width || crop.width, totalWidth));
        const top = Math.round(getComputedNum(percentCrop && percentCrop.y || crop.y, totalHeight));
        const bottom = Math.round(top + getComputedNum(percentCrop && percentCrop.height || crop.height, totalHeight));

        if(action === 'crop') {
            fetchCropFocalPoints(`${left}x${top}:${right}x${bottom}`, fileName)
               .then(json => {
                    const targetAspectRatio = json.thumbor.target.width / json.thumbor.target.height;
                    const previewHeight = Math.round(200/targetAspectRatio);
                    const imageType = get(config, `resourceData[${resource}].imageType`) || 'jpg';
                    fetchCropImagePreview(`${left}x${top}:${right}x${bottom}/200x${previewHeight}`, fileName, imageType)
                        .then(imgPreview => {
                            setUrlPreview(imgPreview);
                        })
                        .catch(error => console.log(error));
                    setTargetAspectRatio(targetAspectRatio);
                })
               .catch(error => console.error('Failed to fetch focal points for crop: ', error));
        }

        if(action == 'focusPoint') {
            const { reshapeUrl, src } = this.state;
            const focusPointPreview1 = `${reshapeUrl}50x50/filters:focal(${left}x${top}:${right}x${bottom})/${src}`;
            const focusPointPreview2 = `${reshapeUrl}50x50/filters:focal(${left}x${top}:${right}x${bottom}):round_corner(255,255,255,255)/${src}`;
            setFocusPointPreview1(focusPointPreview1);
            setFocusPointPreview2(focusPointPreview2);
        }

        setBox({left, top, right, bottom});
    }

    const saveEditImage = () => {
        let finalImageUrl;
        if(action === 'crop') {
            const operation = `${box.left}x${box.top}:${box.right}x${box.bottom}`;
            const urlPostfix = get(config, `resourceData[${resource}].imageType`) || 'jpg';
            finalImageUrl = `${API_URL}/api/thumbor/image/crop/preview/${urlPostfix}?operation=${operation}&fileName=${tempFile.name}`;
        }
        else{
            //default is focus point
            finalImageUrl = getFinalImgUrl(imageServiceURL, imgComputedWidth, imgComputedHeight, imgOriginalWidth, imgOriginalHeight, box, src, config, resource)
        }

        fetch(finalImageUrl)
            .then(response => response.blob())
            .then(blob => {
                const croppedImageUrl = blob;
                var files = [];
                var imageExt = getImageExt(croppedImageUrl.type);
                //Generate random number to force image upload updates
                //var uniq = Math.random();
                var imageFile = new File([croppedImageUrl], `${tempFile.name}`);

                const preview = URL.createObjectURL(croppedImageUrl);
                imageFile.preview = preview;

                files.push(imageFile);
                const updatedFiles = multiple
                    ? [...files, ...files.map(transformFile)]
                    : [...files.map(transformFile)];

                this.setState({ files: updatedFiles, showEditImageDialog: false });

                if (multiple) {
                    input.onChange(updatedFiles);
                } else {
                    input.onChange(updatedFiles[0]);
                }
            })
            .catch(error => console.error(error));

    }

    const onRemove = file => () => {
        const filteredFiles = this.state.files.filter(
            stateFile => !shallowEqual(stateFile, file)
        );

        this.setState({ files: filteredFiles });

        if (this.props.multiple) {
            this.props.input.onChange(filteredFiles);
        } else {
            this.props.input.onChange(null);
        }
    };

    const onSelectImage = (src, id) => {
        this.downloadImage(src, id);
    }

    const handleEditImageCloseClick = () => {
        const { tempFile } = this.state;
        if(tempFile) {
            this.removeTempFile(tempFile);
            this.setState({ tempFile: null });
        }
        this.setState({ showEditImageDialog: false });
    }

    const downloadImage = (blob, id) => {
         var fileArr = [];

         var ext = this.getImageExt(blob.type);
         var imageFile = new File([blob], `${id}.${ext}`);

         const preview = URL.createObjectURL(blob);
         imageFile.preview = preview;

         fileArr.push(imageFile);
         const newFile = fileArr.map(this.transformFile)

         this.setState({ files: newFile });

         this.props.input.onChange(newFile[0]);
    }

    const fetchImageAsBlob = (src) => {
          const request = buildRequest('config/thumbor', 'GET');

          fetch(request)
              .then(checkResponse)
              .then(json => this.setState({ imageServiceURL: json.thumborUrl, config: json }))
              .catch(error => console.error('Could not fetch image service url:', error));
    }

    const getImageExt = (type) => {
        var ext = type.split("/")[1];
        if (ext === 'jpeg'){
            return 'jpg';
        }
        return ext;
    }

    // turn a browser dropped file structure into expected structure
    const transformFile = file => {
        if (!(file instanceof File)) {
            return file;
        }

        const { source, title } = React.Children.toArray(
            this.props.children
        )[0].props;

        const transformedFile = {
            rawFile: file,
            [source]: file.preview,
        };

        if (title) {
            transformedFile[title] = file.name;
        }

        return transformedFile;
    };

    const transformFiles = (files) => {
        if (!files) {
            return multiple ? [] : null;
        }

        if (Array.isArray(files)) {
            return files.map(transformFile);
        }

        return transformFile(files);
    };

    const setAction = action => {
        setAction(action);
    }

    const handleActionChange = (event) => {
        computeImgBox(event.target.value, tempFile.name, crop, percentCrop, imgOriginalWidth, imgOriginalHeight);
        setAction(event.target.value);
    }

    const searchTerm = getSearchTerm(resource, record);

    const { getRootProps, getInputProps } = useDropzone({
        ...options,
        accept,
        maxSize,
        minSize,
        multiple,
        onDrop,
    });

    return resource === 'team' && !record.shouldUpdateLogo ? null : (
        <Labeled
            id={id}
            label={label}
            className={classnames(classes.root, className)}
            source={source}
            resource={resource}
            isRequired={isRequired}
            {...sanitizeRestProps(rest)}
        >
            <span>
            <div
                data-testid="dropzone"
                className={classes.dropZone}
                {...getRootProps()}
            >
                <input
                    id={id}
                    {...getInputProps({
                        ...inputProps,
                        ...inputPropsOptions,
                    })}
                />

            </div>
                <DefaultLoader mainLoading={mainLoading} />
                <ImgEditComponent imageServiceURL={imageServiceURL} handleEditImageCloseClick={this.handleEditImageCloseClick} showEditImageDialog={showEditImageDialog} imageSrc={imageSrc} src={src} crop={crop} box={box} onImageLoaded={this.onImageLoaded}
                    onCropComplete={this.onCropComplete} onCropChange={this.onCropChange} saveEditImage={this.saveEditImage} action={action}
                    handleActionChange={this.handleActionChange} targetAspectRatio={targetAspectRatio} urlPreview={urlPreview} focusPointPreview1={focusPointPreview1}
                     focusPointPreview2={focusPointPreview2} />
                <GettyImageButton searchPhrase={searchTerm} resource={resource} onSelectImage={this.onSelectImage} />
                {children && (
                    <div className="previews">
                        {this.state.files.map((file, index) => (
                            <FileInputPreview
                                key={index}
                                file={file}
                                onRemove={this.onRemove(file)}
                                className={classes.removeButton}
                            >
                                {React.cloneElement(children, {
                                    record: file,
                                    className: classes.preview,
                                })}
                            </FileInputPreview>
                        ))}
                    </div>
                )}
            </span>
        </Labeled>
    );
}*/