import React, { FC, useState, useEffect } from 'react';
import compose from 'recompose/compose';
import { makeStyles, Theme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import SportsIcon from '@material-ui/icons/DirectionsRun';
import { buildRequest, checkResponse } from '../../helpers/request';
import { usePrevious } from '../../helpers/utils';
import { isEqual } from 'lodash';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
      flex: '1',
      marginRight: '1em',
  },
  root: {
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  }
}));

interface SportsParams {
  length?: number
}

const TotalSports: FC = () => {
  const [sports, setSports] = useState<SportsParams>({});
  const classes = useStyles();
  const translate = useTranslate();

  const fetchSportCount = () => {
    const request = buildRequest('sport/all', 'GET');

    fetch(request)
        .then(checkResponse)
        .then( (json) => {
            setSports(json);
        })
        .catch(error => console.error('Error:', error));
  }

  const prevSports = usePrevious(sports);
  useEffect(() => {
    if(!isEqual(prevSports, sports)) {
      fetchSportCount();
    }
  }, [sports, prevSports]);

  return (
    <Card
      className={classes.root}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="inherit"
              gutterBottom
              variant="body2"
            >
              {translate('pos.dashboard.totalSports.title')}
            </Typography>
            <Typography
              color="inherit"
              variant="h3"
            >
              {sports && sports.length}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <SportsIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TotalSports;