import React, { FC, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Typography, Avatar, Button } from '@material-ui/core';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useTranslate } from 'react-admin';
import { buildRequest, checkResponse } from '../../helpers/request';
import abbreviate from 'number-abbreviate';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  main: {
    flex: '1',
    marginRight: '1em',
  },
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceButton: {
    marginLeft: -10,
    width: 50,
    minWidth: 50
  },
  differenceIcon: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(0.2)
  },
  differenceValue: {
    color: theme.palette.success.dark,
  },
  caption: {}
}))

const LastChange: FC = () => {
  const [totalChange, setTotalChange] = useState(0);
  const [sinceLastMonth, setSinceLastMonth] = useState(0);
  const classes = useStyles();
  const translate = useTranslate();
  const history = useHistory();

  const fetchData = () => {
    const request = buildRequest('changes/metrics', 'GET');
    fetch(request)
        .then(checkResponse)
        .then( (json: any) => {
            setTotalChange(json.totalChange);
            setSinceLastMonth(json.sinceLastMonth);
        })
        .catch(error => console.error('Error:', error));
  }

  const navigateToList = () => {
      history.push('/changes');
  }

  useEffect(() => {
    fetchData();
  },[totalChange, sinceLastMonth] );

  return (
    <Card
      className={classes.root}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              {translate('pos.dashboard.changes.title')}
            </Typography>
            {totalChange && <Typography variant="h3">{abbreviate(totalChange, 3)}</Typography>}
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <FileCopyIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        <div className={classes.difference}>
            <Button
              color="primary"
              size="small"
              variant="text"
              onClick={navigateToList}
              className={classes.differenceButton}
            >
              <TrendingUpIcon className={classes.differenceIcon} />
              <Typography
              className={classes.differenceValue}
              variant="body2"
              >
                  {abbreviate(sinceLastMonth)}
              </Typography>
            </Button>
            <Typography
              className={classes.caption}
              variant="caption"
            >
              {translate('pos.dashboard.changes.subtitle', {smart_count:sinceLastMonth > 1 ? 2 : 1})}
            </Typography>
          </div>
          
      </CardContent>
    </Card>
  )
}

export default LastChange;