import React from 'react';
import { Edit, EditButton, SimpleForm, TextInput, ImageField } from 'react-admin';
import CustomImageInput from '../fileInput/CustomImageInput'
import { ResourceActions } from '../buttons/ResourceAction';
import { DefaultToolbar } from '../helpers/DefaultToolbar';
import { SportAdminPage } from '../components/customs';

const PlayerTitle = (props: any) => {
    const { record } = props;
    return <span>Player {record ? `"${record.lastName} ${record.firstName}"` : ''}</span>;
};

const PlayerEdit = (props) => (
    <SportAdminPage>
        <Edit title={<PlayerTitle />} {...props} actions={<ResourceActions />}>
            <SimpleForm toolbar={<DefaultToolbar />}>
                <TextInput disabled source="id" />
                <TextInput disabled source="standardPosition" />
                <TextInput disabled source="firstName" />
                <TextInput disabled source="lastName" />
                <TextInput disabled source="birthDate" />
                <TextInput disabled source="weight" />
                <TextInput disabled source="height" />
                <TextInput disabled source="nickName" />
                <TextInput source="nickNameDe" label={"Nick Name DE"} />
                <TextInput disabled source="birthPlace" />
                <TextInput disabled source="nationality" />
                <TextInput disabled source="preferredFoot" />
                <TextInput disabled source="realPosition" />
                <TextInput disabled source="realPositionSide" />
                <TextInput disabled source="country" />

                <CustomImageInput source="logo" label="Logo" accept="image/jpeg" multiple={false}>
                    <ImageField source="src" />
                </CustomImageInput>
            </SimpleForm>
        </Edit>
    </SportAdminPage>
);

export default PlayerEdit;
