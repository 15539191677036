import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'proxy-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
//import { unregister } from './registerServiceWorker';
import { Provider } from 'mobx-react'
import { UniversalProvider } from '@sport1/universal-components'
import { BrowserRouter as Router } from 'react-router-dom'
import theme from './theme/small'
import MatchStore from './store/matchstore'


ReactDOM.render(
    <Router>
        <UniversalProvider theme={theme}  >
            <Provider
                matchStore={new MatchStore()}
            >
                    <App />
            </Provider>
        </UniversalProvider>
    </Router>,
    document.getElementById('root')
)
//registerServiceWorker()
//unregister()
