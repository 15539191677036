import SportList from './SportList';
import SportEdit from './SportEdit';
import Icon from '@material-ui/icons/DirectionsRun';
import ReorderIcon from '@material-ui/icons/Reorder';


export const SportIcon = Icon;
export const OrderIcon = ReorderIcon;

export default {
    list: SportList,
    edit: SportEdit
}



