import React from "react";
import { SportAdminPage } from '../../components/customs';
import { Edit, SimpleForm, TextInput, Responsive, BooleanField, ImageField } from 'react-admin';
import { DefaultToolbar } from '../../helpers/DefaultToolbar';

const SoccerTeamStandingsTitle = (props: any) => {
  return <span>Soccer Team Standing "{props.record.groupName}"</span>;
};

const SoccerTeamStandingEdit = (props) => (
  <SportAdminPage>
    <Edit title={<SoccerTeamStandingsTitle />} {...props}>
      <SimpleForm toolbar={<DefaultToolbar />}>
        <TextInput disabled source="competitionId" />
        <TextInput disabled source="teamId" />
        <TextInput disabled source="seasonId" />
        <TextInput disabled source="groupName" />
        <TextInput source="groupNameDe" label='Group name DE' />
      </SimpleForm>
    </Edit>
  </SportAdminPage>
)

export default SoccerTeamStandingEdit