import React from 'react';
import { TextField, CloneButton } from 'react-admin';
import { List, Datagrid } from '../../components/customs';
import { CommentaryActions, CommentaryFilter, getDefaultRecord } from '../../commentaries/soccer/CommentaryList';
import CommentaryEditButton from '../../commentaries/EditButton';
import { isAdmin, isMatchEditor } from '../../permissions';
import { BulkActionButtons } from '../../components/buttons';

export const CommentaryList = ({permissions, ...props}) => {
    const record = getDefaultRecord(props.location.search);
    Object.assign(record, {eventType: 'comment'});
    return (
        <List {...props} sort={{ field: 'sort', order: 'DESC' }} filters={<CommentaryFilter />} actions={<CommentaryActions record={record} permissions={permissions} />} 
            bulkActionButtons={(isAdmin(permissions) || isMatchEditor(permissions)) ? <BulkActionButtons /> : false} >
            <Datagrid>
                <TextField source="id" />
                <TextField source="langId" label="Lang Id" />
                <TextField source="matchId" />
                <TextField source="minute" />
                <TextField source="sort" />
                <TextField source="message" />
                <TextField source="eventType" />
                {(isAdmin(permissions) || isMatchEditor(permissions)) && <CloneButton />}
                {(isAdmin(permissions) || isMatchEditor(permissions)) && <CommentaryEditButton />}
            </Datagrid>
        </List>
)};