import germanMessages from 'ra-language-german';

export default {
    ...germanMessages,
    pos: {
        dashboard: {
            changes: {
                title: 'ANPASSUNGEN',
                subtitle: 'Anpassung seit letztem Monat |||| Anpassugen seit letztem Monat'
            },
            imageAvailableCount: {
                title: 'Bilder verfügbar'
            },
            tickerSports: {
                title: 'ACTIVE TICKER SPORTS',
            },
            totalSports: {
                title: 'GESAMTSPORTS',
            },

        },
        configuration: {
            title: 'Konfiguration',
            tabs: {
                priorities: 'Reihenfolge',
                settings: 'Einstellungen',
            },
             select: {
                 placeholder: 'Wählen für Sortierung innerhalb einer Gruppe'
             },
              sort: {
                  override:{
                      button: {
                        title: 'Sortierung zürucksetzen'
                      },
                      dialog: {
                          title: 'Sortierung zürucksetzen',
                          message: 'Bist du sicher?',
                          yes: 'Ja',
                          no: 'Nein'
                      }
                  }
              }
        },
        language: 'Sprache',
        theme: {
            name: 'Motiv',
            light: 'Leicht',
            dark: 'Dunkel',
        },
        getty: {
             main: {
                title: 'Bild von Getty importieren'
             },
             dialog: {
                 title: 'Bilder von Getty importieren',
                 image: {
                    edit: {
                        title: 'Fokus nicht richtig? Box zum Fokus verschieben und bei Bedarf die Größe ändern',
                        accept: 'Bestätigen'
                    }
                 },
                 loadMore: 'Mehr Laden',
                 search: 'Suche'
             }
        },
        menu: {
            ticker: 'Ticker',
            catalog: 'Catalog',
            setup: 'Setup',
        },
        auth: {
            title: 'Anmelden',
            or: 'oder',
            ms: {
                sign_in: 'Mit Microsoft anmelden'
            },
        }
    },
     resources: {
        competition: {
            name: 'Wettbewerb |||| Wettbewerbe',
            fields: {
                id: 'id',
                nameDE: 'nameDE',
                isActive: 'isActive',
                hasTicker: 'hasTicker',
                hasStandings: 'hasStandings',
                isTop: 'isTop',
                countryCode: 'countryCode',
                topSort: 'topSort',
            }
        },
        event: {
            name: 'Event |||| Events',
            fields: {
                id: 'id',
                name: 'Name',
                startDate: 'startDate',
                endDate: 'endDate',
                stageType: 'stageType',
                status: 'status',
                nameDE: 'nameDE',
                shortName: 'shortName',
                shortNameDE: 'shortNameDE',
                season: 'season'
            },
        },
        session: {
            name: 'Session |||| Sessions',
        },
        team: {
            name: 'Verein |||| Vereine',
            fields: {
                code: 'code',
                country: 'country',
                city: 'city',
                founded: 'founded',
                postalCode: 'Postal code',
                regionName: 'Region name',
                shortName: 'Short name',
                street: 'street',
                webAddress: 'Web address',
                nameDE: 'Name DE',
                shortNameDE: 'Short name DE',
            }
        },
        player: {
            name: 'Spieler |||| Spieler',
            fields: {
                standardPosition: 'standardPosition',
                firstName: 'firstName',
                lastName: 'lastName',
                birthDate: 'birthDate',
                weight: 'weight',
                height: 'height',
                nickName: 'nickName',
                birthPlace: 'birthPlace',
                nationality: 'nationality',
                preferredFoot: 'preferredFoot',
                realPosition: 'realPosition',
                realPositionSide: 'realPositionSide',
                country: 'country'
            }
        },
        country: {
            name: 'Land |||| Länder',
            fields: {
                code: 'ioc',
                ioc: 'ioc',
                en: 'en',
                de: 'de',
                es: 'es',
                fr: 'fr',
                it: 'it',
                ru: 'ru',
                optaCode: 'optaCode',
            }
        },
        season: {
            name: 'Saison |||| Saisons',
            fields: {
                id: 'id',
                name: 'name',
                nameDE: 'nameDE',
                startDate: 'startDate',
                endDate: 'endDate',
                year: 'year',
                competitionId: 'competitionId',
                isCurrent: 'isCurrent',
                sportId: 'sportId'
            }
        },
        sport: {
            name: 'Sport |||| Sports',
            matches: 'Spiele',
            fields: {
                id: 'id',
                name: 'name',
                nameDE: 'nameDE',
                isActive: 'isActive',
                sort: 'sort',
                sportGroupId: 'sportGroupId',
            }
        },
        group: {
            name: 'Sport Kategorie |||| Sport Kategorien',
            fields: {
                id: 'id',
                name: 'name',
                nameDE: 'nameDE',
                sort: 'sort',
            }
        },
        ticker: {
            name: 'Ticker |||| Tickers'
        },
        match: {
            name: 'Spiel |||| Spiele',
            del: {
                dialog: {
                    title: 'Spiele löschen',
                    message: {
                        matchSingular: 'Spiel',
                        matchPlural: 'Spiele',
                        prefix: 'Willst du wirklich',
                        suffix: 'löschen?'
                    }
                }
            }
        },
        task: {
            create: {
                title: 'Task erstellen',
                button: {
                    text: 'Task erstellen'
                }
            },
            edit: {
                title: 'Task bearbeiten',
            },
            viewAll: 'Alle ansehen',
        },
        officials: {
            name: 'Official |||| Officials',
            fields: {
                team: 'verein',
                firstName: 'firstName',
                lastName: 'lastName',
                birthDate: 'birthDate',
                nickName: 'nickName',
                birthPlace: 'birthPlace',
                nationality: 'nationality',
                country: 'country'
            }
        },
        referee: {
            name: 'Referee |||| Referees',
            fields: {
                firstName: 'firstName',
                lastName: 'lastName',
                birthDate: 'birthDate',
                nickName: 'nickName',
                birthPlace: 'birthPlace',
                country: 'country'
            }
        },
        sportOrder: {
            name: 'Sport Reihenfolge |||| Sports Reihenfolge'
        },
        redaction: {
            name: 'Redaction Reset',
            reset: {
                successMessage: 'Für %{resourceGroup} - %{resource} wurde einen reset Request zum PollerService erfolgreich gesendet!'
            }
        },
        'standings-group': {
            name: 'Standings Group'
        },
         'match-group': {
             name: 'Match Group'
         },
        'team-standings': {
            name: 'Team Standings'
        },
        noAccess: {
            app: {
                title: 'Zugang nicht erlaubt',
                message: "Sie haben nicht genügend Rechte, um diese App zu nutzen"
            }
        },
        purge: {
            title: 'Bild aus CDN purgen.'
        },
       'soccer-team-standing': {
         name: 'Fußball Team Standing'
       }
     }
}